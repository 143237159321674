import React,{useState,useRef} from 'react'
import "../style/BatchImportPopUp.css"
function BatchImprtPopUp(props) {
    const fileUpload = useRef()
    const [errorMessage,setErrorMessage]=useState("")
    // const [file,setFile] = useState("")
    const [fileData,setFileData] = useState("")
    const [sendMailToAll, setSendMailToAll] = useState(false)
    const handleFileChange = () => {
        let validFileExtensions = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "text/csv"];
        if (fileUpload.current.files[0]) {
            if (validFileExtensions.includes(fileUpload.current.files[0].type)) {

                // We won't store the file data in our state here if needed we can use it in future
                // var file = fileUpload.current.files[0];

                setFileData(fileUpload)
                // var reader = new FileReader();
                // reader.readAsDataURL(file);
                // reader.onloadend = function (e) {
                //     setFile(reader.result)
                // };
                
            } else {
                fileUpload.current.value = "";
                setErrorMessage("Upload file with XLSX, CSV file format")
            }
    }
	}
    const UploadButtonHandler =(e)=>{
        e.preventDefault();
        if(fileData&&fileData.current.files.length > 0){
            props.fileData(fileData, sendMailToAll)
        }else{
            setErrorMessage("Upload file")
        }

    }
    return (
        <div className='batch-import-container' data-testid="batch-import-container">
            <div className="modal">
                <div className="modal-content-container batch-import-section">
                    <div className='batch-import-content-container'>
                        <div className='batch-import-title'  data-testid="batch-import-title">{props.batchImportTitle ? props.batchImportTitle + " - Batch Import" : "Batch Import"}</div>

                        <div className='choose-ctr'>Choose only xlsx, csv file.</div>
                        <div className='batch-import-file-input'>
                            <input type="file" className='batch-import-input' data-testid="batch-import-input-btn" ref={fileUpload} onChange={() => {
                                        setErrorMessage("")
                                        handleFileChange()
                                    }}>
                            </input>
                        </div>
                        <div className='file-template-container'>Download:&nbsp;<a data-testid="xlsx-download-link" className='xlsx-template' href={props.xlsxDownloadSample} target="_blank" rel="noreferrer"> XLSX template</a><a data-testid="csv-download-link" className='csv-template' href={props.csvDownloadSampleLink} target="_blank" rel="noreferrer">CSV template</a></div>
                        {props.isSendLinkProp  && <div className='email-password-sent-link-str'><input type="checkbox" checked = {sendMailToAll} onClick={(e)=>{setSendMailToAll(e.target.checked)}} className='sent-link-checkbox' data-testid="sent-link-checkbox"/><div>Send registration e-mail to imported Users.</div></div>}
                        <div className='input-error-message batch-import-error-ctr' data-testid="batch-import-error-msg">{errorMessage}</div>
                    </div>
                    <div className='button_parent_div'>
                        <button onClick={()=>props.onClose()}className="cancel-button" data-testid="batch-import-cancel-btn" id="batch-import-cancel-btn">Cancel</button>
                        <div class="vertical_line" ></div>
                        <button data-testid="batch-import-update-btn" className='save-button' onClick={(e) => { UploadButtonHandler(e) }} >Upload</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BatchImprtPopUp
