import React, { useState, useEffect, useRef } from 'react';
import "../style/TimePicker.css"

const TimePicker = ({ selectedTime, onSelectTime,placeholder,error }) => {
    const [isOpen, setIsOpen] = useState(false);
    const timerDropDown = useRef()
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour <= 23; hour++) {
            options.push(`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`);
            options.push(`${hour % 12 || 12}:30 ${hour < 12 ? 'AM' : 'PM'}`);
        }
        return options;
    };

    const handleTimeClick = (time) => {
        error = true
        onSelectTime(time);
        setIsOpen(false);
    };

    useEffect(()=>{
		const handleClickOutSide = (e) =>{
			if(isOpen && timerDropDown.current && !timerDropDown.current.contains(e.target)){
				setIsOpen(false)
			}
		};
		document.addEventListener("click",handleClickOutSide);
		return () => {
			document.removeEventListener("click",handleClickOutSide);
		}
	},[isOpen])

    return (
     
            <div className="event-time-picker" data-testid="time-picker-ctr">
                <input
                    type="text"
                    value={selectedTime}
                    onClick={() => setIsOpen(!isOpen)}
                    placeholder= {placeholder}
                    data-testid= "time-picker-input"
                    onChange={(e)=>handleTimeClick(e.target.value)}
                    className={!error && selectedTime.trim().length <=0 ?"event-time-picker-input error" : "event-time-picker-input"}
                />
                {isOpen && (
                    <div className="options" ref={timerDropDown} data-testId = "time-option">
                        {generateTimeOptions().map((time, index) => (
                            <div key={index} data-testId="time" className="option" onClick={() => handleTimeClick(time)}>
                                {time}
                            </div>
                        ))}
                    </div>
                )}
            </div>
    );
};

export default TimePicker;
