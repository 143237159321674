import React from 'react';
import { PDFObject } from 'react-pdfobject';
import eulaPdf from '../../assets/Status Solutions EULA 2024.pdf'

function EULApdfView(){

    return (
        <p className="pdfView">
            <PDFObject url={eulaPdf} width="100%" data-test="pdf-component" />
        </p>      
    )
}
export default EULApdfView

