import React,{useRef, useState,useEffect} from 'react';
import ButtonField from '../../../common/component/ButtonField'
import InputField from '../../../common/component/InputField'
import moment from "moment";
import writeXlsxFile from 'write-excel-file';
import { CSVLink } from "react-csv";
import "./DownloadFilePopUp.css"
function DownloadFilePopUp(props) {
    const [fileName,setFileName] =  useState(props.eventData.eventName+"_"+props.eventData.alertDate+props.eventData.alertTime)
    const [isCSVFile,setIsCSVFile] = useState(false)
    const [isExcelFile,setIsExcelFile] = useState(false)
    const [CSVData,setCSVdata] = useState([])
    const [errorMessage,setErrorMessage] = useState("")
    const [fileErrorMessage, setFileErrorMessage] = useState("")
    const [fileHeader] =  useState([
        { label: "First Name", key: "First Name" },
        { label: "Last Name", key: "Last Name" },
        { label: "Check-In Time", key:"Check-In Time"},
        { label: "Check In", key:"Check In"},
        { label: "Medical", key:"Medical"},
        { label: "Absent", key:"Absent"},
        { label: "Unaccounted", key:"Unaccounted"},
        { label: "Mustering Location", key:"Mustering Location"},
        { label: "Notes", key:"Notes"},
    ])

    useEffect(() => {
        let data = JSON.stringify(props.alertDetail)
        data= JSON.parse(data);
        let tempData = [];
        for (let index = 0; index < data.length; index++) {
            let tempArr = {}
            let checkInTime = ""
            if (data[index]["checkInTime"]){
                let dateObj = moment(data[index]["checkInTime"]);
                checkInTime = dateObj.format("MM-DD-YYYY hh:mm A")
            }
            let location = ""
            if (data[index]["checkedIn"] === 1){
                if(data[index]["customLocation"] === 1){
                    location = data[index]["newMusteringLocation"]
                }
                else{                        
                    location =  data[index]["locationName"]
                }
            }
            tempArr["First Name"] = data[index]["firstName"];
            tempArr['Last Name'] = data[index]["lastName"];
            tempArr["Check-In Time"] = checkInTime;

            tempArr["Check In"] = data[index]["checkedIn"] == 1 ? 'Yes':'No';
            tempArr['Medical'] =data[index]["needMedicalEmergency"] == 1 ? 'Yes':'No';
            tempArr['Absent'] =data[index]["isAbsent"] == 1 ? 'Yes':'No';
            tempArr['Unaccounted'] = ( data[index]["checkedIn"] == 1 || data[index]["isAbsent"] == 1 ) ? "No" : "Yes" ;

            tempArr['Mustering Location'] = location;
            tempArr['Notes'] = data[index]["notes"];
            tempData.push(tempArr)
        } 
        setCSVdata(tempData);
    }, [])
    const csvLink = useRef()
    const exportReport=async()=>{
        if(fileName.length <= 0){
            return setFileErrorMessage("File Name is required")
        }
        if(isCSVFile === false && isExcelFile === false){
            return setErrorMessage("File Type is required")
        }
        if(isExcelFile){
            let columnWidth = [{ width: 20 },{ width: 30 },{ width: 20 },{ width: 20 },{ width: 15 },{ width: 15 },{ width: 15 },{ width: 30 },{ width: 40 } ]; 
            let row_1 = [
                {value:"Title", fontWeight:"bold", borderStyle:"thin"},
                {value:props.alertFullDetail.title, borderStyle:"thin"},
            ]

            let CreatedTime = props.alertFullDetail.alertCreatedTime ? moment(props.alertFullDetail.alertCreatedTime).format("MM-DD-YYYY hh:mm A"):"NA";
            let Clearedtime = props.alertFullDetail.alertClearedTime ? moment(props.alertFullDetail.alertClearedTime).format("MM-DD-YYYY hh:mm A") : "NA";
            
            let row_2 = [
                {value:"Description", fontWeight:"bold", borderStyle:"thin"},
                {value:props.alertFullDetail.description, span:3, borderStyle:"thin"},
                null,null
            ]
            let alarmTriggeredBy_row = [
                {value:"Alarm Triggered By", fontWeight:"bold", borderStyle:"thin"},
                {value: props.alertFullDetail.alertTriggeredBy, borderStyle:"thin" }
            ]
            let alarmClearedBy_row = [
                {value:"Alarm Cleared By", fontWeight:"bold", borderStyle:"thin"},
                {value: props.alertFullDetail.alertClearedBy, borderStyle:"thin" }
            ]
            let row_3 = [
                {value:"Created Time", fontWeight:"bold", borderStyle:"thin"},
                {value:CreatedTime, borderStyle:"thin"}
            ]
            let clearedTimeRow = [
                {value:"Cleared Time", fontWeight:"bold", borderStyle:"thin"},
                {value:Clearedtime, borderStyle:"thin"}
            ]
            let empty_row = [];
            let row_4 = [
                {value:"Check-In", fontWeight:"bold", borderStyle:"thin"},
                {value:"Absent", fontWeight:"bold", borderStyle:"thin"},
                {value:"Medical Emergency", fontWeight:"bold", borderStyle:"thin"},
            ];
            let row_5 = [
                {value:props.eventData.checkedInCount, borderStyle:"thin"},
                {value:props.eventData.absentCount, borderStyle:"thin"},
                {value:props.eventData.medicalEmergencyNeededCount, borderStyle:"thin"}
            ]; 
            let row_6 = [
                {value:"First Name", fontWeight:"bold", borderStyle:"thin"},
                {value:"Last Name", fontWeight:"bold", borderStyle:"thin"},
                {value:"Check-In Time", fontWeight:"bold", borderStyle:"thin"},
                {value:"Checked In", fontWeight:"bold", borderStyle:"thin"},
                {value:"Medical", fontWeight:"bold", borderStyle:"thin"},
                {value:"Absent", fontWeight:"bold", borderStyle:"thin"},
                {value:"Unaccounted", fontWeight:"bold", borderStyle:"thin"},
                {value:"Mustering Location", fontWeight:"bold", borderStyle:"thin"},
                {value:"Notes", fontWeight:"bold", borderStyle:"thin"}
            ];
            let tempData = [];
            tempData.push(row_1);
            tempData.push(row_2);
            tempData.push(alarmTriggeredBy_row);
            tempData.push(row_3);
            tempData.push(alarmClearedBy_row);
            tempData.push(clearedTimeRow)
            tempData.push(empty_row);
            tempData.push(row_4);
            tempData.push(row_5);
            tempData.push(empty_row);
            tempData.push(row_6);
            let data = JSON.stringify(props.alertDetail)
            data= JSON.parse(data);
            for (let index = 0; index < data.length; index++) {
                let checkInTime = ""
                if (data[index]["checkInTime"]){
                    let dateObj = moment(data[index]["checkInTime"]);
                    checkInTime = dateObj.format("MM-DD-YYYY hh:mm A")
                }
                let location = ""
                if (data[index]["checkedIn"] === 1){
                    if(data[index]["customLocation"] === 1){
                        location = data[index]["newMusteringLocation"]
                    }
                    else{                        
                        location =  data[index]["locationName"]
                    }
                }
                
                let tempArr = [

                    {"value":data[index]["firstName"]? data[index]["firstName"] : "-", borderStyle:"thin",alignVertical:"center"},
                    {"value":data[index]["lastName"] ? data[index]["lastName"] : "-", borderStyle:"thin",alignVertical:"center"},
                    {"value":checkInTime ? checkInTime : "-", borderStyle:"thin",alignVertical:"center"},
                    {"value":data[index]["checkedIn"] === 1 ? 'Yes':'No', borderStyle:"thin",alignVertical:"center"},
                    {"value":data[index]["needMedicalEmergency"] === 1 ? 'Yes':'No', borderStyle:"thin",alignVertical:"center"},
                    {"value":data[index]["isAbsent"] === 1 ? 'Yes':'No', borderStyle:"thin",alignVertical:"center"},
                    {"value":( data[index]["checkedIn"] === 1 || data[index]["isAbsent"] === 1 )?"No":"Yes", borderStyle:"thin",alignVertical:"center"},
                    {"value": location?location:"", borderStyle:"thin",alignVertical:"center"},
                    {"value": data[index]["notes"] ? data[index]["notes"] : "", borderStyle:"thin",wrap:true},
                ]
                tempData.push(tempArr)
            } 
            
            await writeXlsxFile(tempData,{
                columns:columnWidth,
                headerStyle: {
                    backgroundColor: '#eeeeee',
                    fontWeight: 'bold',
                    align: 'center',
                   
                },
                fileName:fileName
            })
            props.onCancel()

        }else if(isCSVFile){
            if(csvLink && csvLink.current){
                csvLink.current.link.click();      
            }
            props.onCancel()     
        }   
    }
    return (
    <div className="modal">
        <div className="modal-content-container download-content-container">
            <div className='download-content-section' data-testid="download-file-popup">
                <div className='download-content-title' data-testid="download-report-title">Download Report</div>
                <div className='download-content-row-element' data-testid="download-content-report-name">
                    <span className='download-report-label'>Report:&nbsp;&nbsp;&nbsp;</span>
                    <p> { props.eventData.eventName} {props.eventData.alertDate} at {props.eventData.alertTime}</p>
                </div>
                <div className='download-content-row-element'>
                    <span className='download-content-field-name' data-testid="file-name-label">File Name: </span>
                    <InputField type="text"  fieldType="input-secondary" dataTestid="filename-input" autoFocus={true} value={fileName} onChange={(e)=>{setFileName(e.target.value);setFileErrorMessage("")}}/>
                </div>
                <div className='download-content-row-element'>
                    <span className='download-content-field-name' data-testid="file-type-label">File Type:&nbsp;&nbsp;&nbsp; </span>
                    <div className='download-radio-container'> 
                        <input type="radio" name="fileType" data-testid="xlsx-radio-input" checked={isExcelFile} onClick={(e)=>{setIsExcelFile(true);setIsCSVFile(false);setErrorMessage("")}}/>
                        <label className='field-type-label' for="file-type"  data-testid="xlsx-radio-label"  onClick={(e)=>{setIsExcelFile(true);setIsCSVFile(false);setErrorMessage("")}}>XLSX</label>
                    </div>
                    {/* <div className='download-radio-container'> 
                        <input type="radio" name="fileType"/>
                        <label for="file-type">XLS</label>
                    </div> */}
                    <div className='download-radio-container'> 
                        <input type="radio" name="fileType" data-testid="csv-radio-input" checked={isCSVFile} onClick={(e)=>{setIsCSVFile(true);setIsExcelFile(false);setErrorMessage("")}}/>
                        <label className='field-type-label' for="file-type" data-testid="csv-radio-label" onClick={(e)=>{setIsCSVFile(true);setIsExcelFile(false);setErrorMessage("")}} >CSV</label>
                        <CSVLink data={CSVData} ref={csvLink} headers={fileHeader} filename={fileName} target="_blank"/>
                    </div>
                </div>
                {/* <div className='download-content-row-element'>
                    <span className='download-content-field-name'>Delivery: </span>
                    <div className='download-radio-container'> 
                        <input type="radio" name="deliveryType"/>
                        <label for="deliveryType">XLSX</label>
                    </div> 
                    <div className='download-radio-container'> 
                        <input type="radio" name="deliveryType"/>
                        <label for="deliveryType">XLS</label>
                    </div>
                </div> */}
                {/* <div className='download-content-row-element'>
                    <span className='download-content-field-name'>Send To: </span>
                    <InputField type="text" fieldType="input-secondary" />
                </div> */}
                <div className='download-content-row-element download-btn-row'>
                    <ButtonField dataTestid={"download-pop-up-cancel-btn"} label="Cancel" type="cancel" onClick={(e)=>{
                        e.preventDefault();
                        props.onCancel()   
                    }}/>
                    <div className='vertical_line'></div>
                    <ButtonField  dataTestid={"download-pop-up-download-btn"} label="Download" type="submit" onClick={exportReport}/>
                    
                </div>
                <div className='input-error-message download-report-error-ctr' data-testid="file-name-errorMsg">{errorMessage || fileErrorMessage}</div>
            </div>
        </div>
    </div>
    )
}

export default DownloadFilePopUp
