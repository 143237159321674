import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BreadCrumb from "../../../common/component/BreadCrumb";
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import Table from '../../../common/component/Table';
import AddMusteringLocation from './AddMusteringLocation';
import Confirmation from "../../../common/component/Confirmation"
import URL from "../../../common/api/constantURL";
import * as API from '../../../common/api/index.js';
import { setSnackData, setLoading } from '../../../redux/action/userAction';
import { setGroups } from '../../../redux/action/groupAction';
import { validatePermission } from "../../../common/functions/permissionFunctions";
import "./MusterLocation.css"

function MusterLocation() {
	const dispatch = useDispatch();
	
	const groups = useSelector(state => state.groupReducer.groups)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const [musteringLocation, setMusteringLocation] = useState([])
	const [showMusteringLocationPopUp, setShowMusteringLocationPopUp] =  useState(false)
	const [confirmation,setConfirmation] = useState(false)
	const [confirmationDeleteData,setConfirmationDeleteData] = useState([])
	const [editModalData,setEditModalData] = useState({})
	const [search, setSearch] = useState('');
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/application/mustering",display:"Application",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt='arrow-icon' />,type:'img'},
		{link:"/home/application/muster-location",display:"Muster Location",type:"react"},
	];
	let tableHeaders = [
		{ id: 'musteringLocation', label: 'Location Name', width: 100, responsive: true, sortable: true ,dataTestId:"musteringLocation"},
		{ id: 'groupNames', label: "Associated Group", type: 'multiple_data', path: '#', width: 150, responsive: true }
	]

	useEffect(() => {
		dispatch(setLoading(true))
		fetchLocations();
	}, [groups]); 
	useEffect(() => {
		dispatch(setLoading(true))
		fetchGroup();
	}, []); 
	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let groupList = response.result.result
				for (let i = 0; i < groupList.length; i++) {
					groupList[i].userCount = groupList[i].userIds.length
					if (groupList[i].isEventGroup) {
						groupList.splice(i,1)
						i--
					}
				}
				dispatch(setGroups(groupList))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
				dispatch(setGroups([]))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
			dispatch(setGroups([]))
		}
	}
	const getGroupName = (groupId) => {
		for (let index = 0; index < groups.length; index++) {
			if (groups[index].id == groupId) {
				return groups[index].groupName
			}
		}
		return ""
	}
	const fetchLocations = async () => {
		let url = URL.musteringLocationsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.musteringLocations
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let locationList = response.result.result
				for (let i = 0; i < locationList.length; i++) {
					let groupNames = []
					locationList[i].groupIds.map((groupId, index)=>{

						groupNames.push(getGroupName(groupId))
					})
					locationList[i].groupNames = groupNames
				}
				setMusteringLocation(locationList)
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const showDeleteAction = (data) => {
		setConfirmation(true)
		setConfirmationDeleteData(data)
	}
	const showEditAction = (data) =>{
		setEditModalData(data)
		setShowMusteringLocationPopUp(true)
	}
	const deleteLocation = async () => {
		let data = {
			"id": confirmationDeleteData.id,
			"facilityId": localStorage.getItem("facilityId"),
		}
		let response = await API.deleteAPI(URL.musteringLocations, data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				setConfirmation(false)
				fetchGroup();
				dispatch(setSnackData(snackData))
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	if (validatePermission(userPermissions, "EDIT_MUSTERING_LOCATION") || validatePermission(userPermissions, "DELETE_MUSTERING_LOCATION")) {
		tableHeaders.push({ id: 'manage', label: 'MANAGE', type: 'action', path: '2', width: 80, responsive: true })
	}
	
	const filter = (tempValues) =>{
		let updatedValues = []
			if(search && search.length > 0){
				tempValues.map((value)=>{
					if(
						value.musteringLocation.toLocaleLowerCase().includes(search.toLocaleLowerCase())
					){
						updatedValues.push(value)
					}
				})
			}else{
				updatedValues = tempValues
			}
			return updatedValues
		}
	return (
		<div className='mustering-location-container' data-testid="mustering-location-container" id="wrapper">
			<BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			<div className='list-page-title-div'>
				<div className='list-page-title' data-testid="mustering-location-title">Muster Location</div>
			</div>
			{validatePermission(userPermissions, "ADD_MUSTERING_LOCATION") ?
				<div className='button_parent_div'>
					<button className='common-button' data-testid="add-mustering-location-btn" id="add-mustering-location-btn" onClick={() => { setShowMusteringLocationPopUp(true) }}>Add Mustering Location</button>
				</div>
				
			:null}
			<div className="search mustering-location-search-filter">
				<input type="text" className="usersSearch" placeholder="Search" data-testid="mustering_location_search" id="mustering-location-search" 
				 value={search} onChange={(e) => setSearch(e.target.value)}
				></input>
				<div style={{ display: "flex" }} className="reclear">
					<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
				</div>
			</div>
			<div>
				<Table
					headers={tableHeaders}
					data={filter(musteringLocation)}
					editAction={validatePermission(userPermissions, "EDIT_MUSTERING_LOCATION") ? (data) => showEditAction(data) : null}
					deleteAction={validatePermission(userPermissions, "DELETE_MUSTERING_LOCATION") ?(data) => showDeleteAction(data):null}		
				/>
			</div>
			{showMusteringLocationPopUp && 
				<AddMusteringLocation data={editModalData} onCancel={() => { setShowMusteringLocationPopUp(false); setEditModalData([]); fetchGroup()}}/>
			}
			{confirmation ?
				<Confirmation deleteMessage={"Are you sure you want to delete location "+confirmationDeleteData.musteringLocation+"?"} successFunc={() => deleteLocation()} cancelFunc={() => setConfirmation(false)} />
			: ""}
			
		</div>
	)
}

export default MusterLocation
