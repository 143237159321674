import React,{useState,useEffect} from 'react'
import ButtonField from '../../common/component/ButtonField'
import InputField from '../../common/component/InputField'
import "../Style/ManageMustering.css";
import * as API from '../../common/api/index.js';
import URL from "../../common/api/constantURL";
import { useDispatch,useSelector } from 'react-redux';
import { setLoading, setSnackData } from "../../redux/action/userAction";
import {AiOutlineClose} from "react-icons/ai"

function ManageMustering(props) {
    const dispatch = useDispatch() 
    const musteringLoaction = useSelector(state=>state.userReducer.musteringLocation)
    const [showLocationError,setShowLocationError] = useState(false)
    const [currentLocationEnable,setCurrentLocationEnable] = useState(props.musteringProps.customLocation)
    const [errorMessage,setErrorMessage] = useState("")
    const [validateManageMustering,setValidateMustering] = useState(true)
    const [newLocationname,setNewlocationName] = useState(props.musteringProps.newMusteringLocation)
    const [currentLocation,setCurrentLocation] = useState(0)
    const [notes,setNotes] = useState(props.musteringProps.notes)
    const [userCheckIn,setUserCheckIn] = useState(props.musteringProps.checkedIn)
    const [userAbsent,setUserAbsent] = useState(props.musteringProps.isAbsent) 
    
    const ManageMusteringSubmitHandler =async(e)=>{
        e.preventDefault()
        let LocationStatus;
        let data ={}
        data["facilityId"] = parseInt(localStorage.getItem("facilityId"))
        data["alertId"] = props.musteringProps.alertId
        data["alarmId"] =  props.musteringProps.saraAlertId
        data["userId"] = props.musteringProps.userId
        data["checkedInBy"] = parseInt(localStorage.getItem("loginUserId"))
        // data["needMedicalEmergency"] = 0
        if (!userAbsent && !userCheckIn ) {
            setErrorMessage("Please Select Check-in Status")
            return setValidateMustering(false)
        } else {
            data["checkedIn"] = userCheckIn ? 1 : 0;
            data["isAbsent"] = userAbsent ? 1 : 0;
        }
        musteringLoaction.map((data)=>{
            if(data.musteringLocation.trim().toLocaleLowerCase() === newLocationname?.trim().toLocaleLowerCase()){
                LocationStatus = true
            }
        })
         if(LocationStatus === true){
            return setShowLocationError(true)
        }
        if (!userAbsent) {
            if (currentLocationEnable === 0) {
                if (!currentLocation) {
                    setErrorMessage("Select Current Location")
                    return setValidateMustering(false)
                }
                data["locationId"] = parseInt(currentLocation)
                data["customLocation"] = 0
                data["newMusteringLocation"] = ""
            } else {
                if (!newLocationname) {
                    setErrorMessage("Enter Custom Location Name")
                    return setValidateMustering(false)
                }
                if(newLocationname.length > 25){
                    setErrorMessage("Location name must be less than 25.")
                    return setValidateMustering(false)
                }
                data["locationId"] = 0
                data["customLocation"] = 1
                data["newMusteringLocation"] = newLocationname
            }  
        }
        
        if(notes){
            data["notes"] = notes
        }else{
            data["notes"] = null
        }
        if(validateManageMustering && !showLocationError){
            dispatch(setLoading(true))
            let response = await API.postAPI(URL.alertCheckIn,data);
            if(response.fetchStatus === "success"){
                if(response.result.status === "success"){
                    props.onCancel()
                    let snackData = {
                        showSnack:true,
                        snackMessage:response.result.message,
                        snackVariant:"success"
                    }
                    dispatch(setSnackData(snackData))
                }else{
                    let snackData = {
                        showSnack:true,
                        snackMessage:response.result.message,
                        snackVariant:"error"
                    }    
                    dispatch(setSnackData(snackData))
                    dispatch(setLoading(false))
                }
            }else if(response.fetchStatus === "failure"){
                let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
                dispatch(setLoading(false))
            }    

        }
    }
    
    useEffect(() => {
        setCurrentLocation(props.musteringProps.locationId)   
    }, [])
  return (
    <div className='manage-mustering-container' data-testid="manage-mustering-container">
        <div className="modal">
            <div className="modal-content-container manage-mustering-modal">
                <div className='modal-content mustering '>
                    <form className='manage-mustering-section' onSubmit={ManageMusteringSubmitHandler}>
                        <div className='spaced-container'>
                        <h3 data-testid="manage-with-name">Manage - {props.musteringProps.fullName}</h3>
                        <div className='current-location-container'> 
                            <div className='mustering-label-container'>
                                <input type="radio" name="location" data-testid="current-location-checkbox" value="currentLocation" checked={!currentLocationEnable} onChange={()=>{ setCurrentLocationEnable(!currentLocationEnable);setNewlocationName("")}}/>
                                <label for="Advisor" data-testid="current-location-label">Current Location</label>
                            </div>  
                            <span>
                                <select  name="location" id="groups" value={currentLocation} disabled={currentLocationEnable} 
                                    data-testid="current-location-selectBox"
                                    onChange={(e)=>{
                                    setValidateMustering(true)
                                    setShowLocationError(false)
                                    setCurrentLocation(e.target.value)}}> 
                                    <option value="" data-testid="location-option">Select Location</option>             
                                    {musteringLoaction.map((location, index) =>
                                        <option value={location.id}  key={index}>{location.musteringLocation}</option>
                                    )}
                                </select>
                            </span>
                                        
                        </div>
                        <div className='new-location-container'> 
                            <div className='mustering-label-container'>
                                <input type="radio" data-testid="new-location-checkBox" name="location" value="current-location" checked={currentLocationEnable} onChange={()=>{ setCurrentLocationEnable(!currentLocationEnable);setCurrentLocation("")}}/>
                                <label for="location" data-testid="new-location-label">Add New Location</label>
                            </div> 
                            <span>
                                <InputField 
                                    type="text" 
                                    fieldType="input-secondary"
                                    name="newLocation" 
                                    placeholder="New location here"
                                    value={newLocationname}
                                    disabled={!currentLocationEnable}
                                    dataTestid="new-location-inputBox"
                                    onChange={(e)=>{ setValidateMustering(true);setShowLocationError(false);setNewlocationName(e.target.value)}}           
                                />
                            </span>  
                        
                        </div>
                        {showLocationError&&<div className='location-exit-error' data-testid="location-exit-error">
                            <div className='location-exit-error-ctr'>
                                <div className='location-error-header-ctr' ><h4 data-testid="location-exit-msg">Location already exists.</h4><AiOutlineClose onClick={()=>{setShowLocationError(false)}} data-testid="close-icon"/></div>
                                <p data-testid="choose-location-msg">Please enter a new location or choose current location and select an option from the list.</p>
                            </div>  
                        </div>}
                        <div className='notes-container'> 
                            <div className='mustering-label-container'>
                                <input type="radio" name="notes" value="new-location" data-testid="notes-checkbox"/>
                                <label for="Advisor" data-testid="notes-label">Notes</label>
                            </div>  
                        
                            <div className='manage-mustering-text-area'>
                                <textarea placeholder='Note Text' data-testid="notes-textarea" maxLength="200" value={notes} onChange={(e)=>{setNotes(e.target.value)}}></textarea>
                                <small data-testid="limit-error">Limit 200 characters</small>
                            </div>  
                        </div>
                        </div>
                        <div className='checkin-container'>
                            <div className='checkin-sub-container'> 
                                <div>
                                    <label for="User Is Checked In" data-testid="user-checkIn-label">User Is Checked In</label>
                                    <input type="checkbox" name="checkInStatus" data-testid="user-checkIn-checkbox" checked={userCheckIn} value={userCheckIn} onChange={(e)=>{setValidateMustering(true);setUserCheckIn(e.target.checked); setUserAbsent(false)}} />
                                </div>
                                <div>
                                    <label for="User Is Absent" data-testid="user-absent-label">User Is Absent</label>
                                    <input type="checkbox" name='checkInStatus' data-testid="user-absent-checkbox" checked={userAbsent} value={userAbsent} onChange={(e=>{ setValidateMustering(true);setUserAbsent(e.target.checked);setUserCheckIn(false)})} />
                                </div>
                            </div>
                        </div>
                        <div className="user-error-msg manage-mustering-error-msg" data-testid="manage-mustering-error-msg">
                            {!validateManageMustering && errorMessage}
				        </div>
                        <div className='btn-conteiner spaced-container' >
                            <ButtonField label="Cancel" type="cancel" 
                            dataTestid="cancel-btn"
                            onClick={(e)=>{
                                e.preventDefault();
                                props.onCancel()   
                            }}/>
                            <div className='vertical_line'></div>
                            <ButtonField label="Update 1 Record"  dataTestid="submit-btn" type="submit" onClick={ManageMusteringSubmitHandler}/>
                        </div>
                    </form>
                </div>
         
            </div>
        </div>
    </div>
  )
}

export default ManageMustering
