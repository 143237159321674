import {defaultPreference, defaultSeparator, namePreference} from '../variables/preference';

export function formatUserName(user, order=defaultPreference, separator=defaultSeparator){
    return formatUserFullName(user.firstName, user.lastName, order=defaultPreference, separator=defaultSeparator);
}

export function formatUserFullName(firstName, lastName, order=defaultPreference, separator=defaultSeparator){
    if (order === namePreference.FirstNameFirst){
        if (firstName && lastName)
            return firstName+separator+lastName 

        if (firstName)
            return firstName

        if (lastName)
            return lastName

    }
    if (order === namePreference.LastNameFirst){
        if (lastName && firstName)
            return lastName+separator+firstName
            // return user.firstName+defaultSeparator+user.lastName
        
        if (lastName)
            return lastName
            
        if (firstName)
            return firstName
            
    }
}
