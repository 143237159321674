import actiontypes from "../constant/action-types";

export const setAccounts = (accounts) => {
	return {
		type: actiontypes.SET_ACCOUNT_DATA,
		payload: accounts,
	};
};
export const setFacilities = (facilities) => {
	return {
		type: actiontypes.SET_FACILITY_DATA,
		payload: facilities,
	};
};
export const setSpecificFacilityDetail =(facilityDetail)=>{
	return {
		type:actiontypes.SET_SPECIFIC_FACILITY_DETAIL,
		payload: facilityDetail,
	}
}
export const setFacilitySelectionFlag = (facilitySelectionFlag) => {
	return {
		type: actiontypes.SET_FACILITY_SELECTION_FLAG,
		payload: facilitySelectionFlag,
	}
}
