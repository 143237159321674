import actiontypes from "../constant/action-types";

const initialState={
    serverConfig: [],
};

export const serverConfigReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actiontypes.SET_SERVER_CONFIG:
            return { ...state, serverConfig: payload };
        default:
            return state;
    }
}