import actiontypes from "../constant/action-types";
const initialState={
	adminDetail:[],
	users:[],
	userTypes:[],
	snackResponse: {
		showSnack : false,
		snackMessage:"",
		snackVariant:""
	},
	alertColor:[],
	alertIcon:[],
	currentMusteringEventUserList:[],
	musteringLocation:[],
	loading:false,
	rolesDetails:[],
	userPermissions : [],
	advisorDetails:[],
	userProfileDetail:[],
	userSearchData:"", 
	userFilterRoleData:"",
	userFilterGroupData:"",
	userPaginationData:0,
	userperPageData:10,
	alertEventHistoryData:[],
	activeMusteringList:[],
	alertTones:[],

};
export const userReducer = (state = initialState, { type, payload})=>{
	switch (type){
		case actiontypes.SET_USER:
			return { ...state, users: payload};

		case actiontypes.LOGIN:
			return { ...state, adminDetail: payload};

		case actiontypes.USER_TYPE:
			return { ...state, userTypes: payload};

		case actiontypes.SET_SNACK_DATA:
			return { ...state, snackResponse: payload}

		case actiontypes.SET_ALERT_COLOR:
			return { ...state, alertColor: payload}

		case actiontypes.SET_ALERT_ICON:
			return { ...state, alertIcon: payload}
			
		case actiontypes.SET_ACTIVE_MUSTERING_LIST:
			return {...state, activeMusteringList:payload}

		case actiontypes.SET_CURRENT_MUSTERING_EVENT_USER_LIST:
			return { ...state, currentMusteringEventUserList: payload}

		case actiontypes.SET_MUSTERING_LOCATION:
			return { ...state, musteringLocation: payload}

		case actiontypes.SET_LOADING:
			return { ...state, loading: payload}

		case actiontypes.SET_ROLES_DETAILS:
			return { ...state, rolesDetails: payload}

		case actiontypes.SET_USER_PERMISSION:
			return { ...state, userPermissions: payload }

		case actiontypes.SET_ADVISOR_DETAILS:
			return { ...state, advisorDetails: payload}

		case actiontypes.SET_USER_PROFILE_DETAIL:
			return { ...state, userProfileDetail: payload}

		case actiontypes.SET_USERS_SEARCH:
			return {...state, userSearchData:payload}

		case actiontypes.SET_USERS_FILTER_ROLE:
			return {...state,userFilterRoleData:payload }

		case actiontypes.SET_USERS_FILTER_GROUP:
			return {...state, userFilterGroupData:payload}

		case actiontypes.SET_USER_PAGINATION_DATA:
			return {...state, userPaginationData:payload}
			
		case actiontypes.SET_USER_PER_PAGE_DATA:
			return {...state, userperPageData:payload}
		
		case actiontypes.SET_ALERT_EVENT_HISTORY:
			return {...state, alertEventHistoryData:payload}

		case actiontypes.SET_ALERT_TONES:
			return { ...state, alertTones: payload}
		default:
			return state;
	}
}
