import { useState } from "react";
import { useSelector } from 'react-redux';

import DashboardUser from "./DashboardUser";
import DashboardAdmin from "./DashboardAdmin";

function Report() {
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	return (
		<>
			{localStorage.getItem("functionalRoleId") < 2 ?
				<DashboardAdmin/>
			:
				<DashboardUser />
			}
		</>
	)
}
export default Report;