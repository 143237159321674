import './AudioFileUploadPopUp.css';
import { useState, useRef, useEffect } from 'react';
import { setSnackData } from "../../../redux/action/userAction";
import { useDispatch } from 'react-redux';
import CONSTURL from "../../../common/api/constantURL";
import * as API from "../../../common/api/index";
import responseMessages from "../../../common/variables/responseMessages.js";

function AudioFileUploadPopUp(props){
    const [toneInfo, settoneInfo] = useState({
		file: [],
		filepreview: null,
	});
    var dispatch = useDispatch()
    const fileUpload = useRef(null);
    const [errorMessage, setErrorMessage] = useState("")
	const [validAddAudioForm, setValidAddAudioForm] = useState(true)
    const [validToneName, setValidToneName] = useState(true)
    const [toneFileName , setToneFileName] = useState('')
    const [toneName , setToneName] = useState('')
    const [isUpdate , setIsUpdate] = useState(false)
   
    useEffect(() => { 
        if(props.data && Object.keys(props.data).length > 0){
            setIsUpdate(true)
            setToneName(props.data.name)
            setToneFileName(props.data.fileOriginalName)
           settoneInfo({
                file: "",
                filepreview: props.data.url,
            });
        }
     }, [])

    useEffect(() => {
        const audio = document.getElementById("audioFile")
        if (audio) {
            audio.setAttribute("src", toneInfo.filepreview)
        }
    }, [toneInfo])

    const handleInputChange = (event) => {
		let validFileExtensions = "audio/wav"
		setValidAddAudioForm(true)
        setToneFileName("")
		settoneInfo({
			file: "",
			filepreview: null,
		});
        if (event.target.files.length > 0) {
            if (validFileExtensions === fileUpload.current.files[0].type) {
                settoneInfo({
                    file: event.target.files[0],
                    filepreview: URL.createObjectURL(event.target.files[0]),
                });
                setToneFileName(event.target.files[0].name)
            } else {
                fileUpload.current.value = ''
                setErrorMessage("Upload file with WAV format")
                return setValidAddAudioForm(false)
            }
        }
	}

    const addAudiosubmitHandler  = async (e) => {
		e.preventDefault()
        const input = toneInfo.file.name
        if (toneName.length == 0 || toneName === '') {
            setErrorMessage("Mandatory Field")
            setValidToneName(false)
            return setValidAddAudioForm(false)
        } 

        if(toneName.length > 100){
            setErrorMessage("Tone Name should not exceed 100 characters")
            setValidToneName(false)
            return setValidAddAudioForm(false)
        }

        if(!isUpdate && input === undefined){
          setErrorMessage('Upload Audio file')
          return setValidAddAudioForm(false)
        }

        if (input) {
            let validFileExtensions =  "audio/wav"
            if (validFileExtensions === fileUpload.current.files[0].type) {
                const fileSize = toneInfo.file.size / 1024 / 1024;
                if (fileSize > 0.3) {
                    setErrorMessage("Please upload a file smaller than 300 KB.")
                    return setValidAddAudioForm(false)
                } 
            }  else{
                setErrorMessage("Upload file with WAV format")
                return setValidAddAudioForm(false)
            }
        }
            
        if (validAddAudioForm && validToneName) {
            let formData = new FormData();

            formData.append("name", toneName.trim())
            formData.append("facilityId", localStorage.getItem("facilityId"))
            if (toneInfo.file.name) {
                formData.append("audioTone", (toneInfo.file.name ? toneInfo.file : ""));
            }    

            let response
            if (isUpdate) {
                formData.append("id", props.data.id)
                response = await API.putwithFileAPI(CONSTURL.tones, formData)
            } else {    
                response = await API.postWithFileAPI(CONSTURL.tones, formData)
            }
    
            if (response.fetchStatus === "success") {
                if (response.result.status === "success") {
                    let snackData = {
                        showSnack: true,
                        snackMessage: response.result.message,
                        snackVariant: "success"
                    }
                    dispatch(setSnackData(snackData))
                    props.onCancel()
                } else {
                    let snackData = {
                        showSnack: true,
                        snackMessage: response.result.message,
                        snackVariant: "error"
                    }
                    dispatch(setSnackData(snackData))
                }
            } else if (response.fetchStatus === "failure") {
                let snackData = {
                    showSnack: true,
                    snackMessage: responseMessages.apiFailureResponse,
                    snackVariant: "error"
                }
                dispatch(setSnackData(snackData))
            }
        }
        
	}

    return (
        <div className="popUpContainer">
            <div className="upload-modal-container add-new-audio-model">
                <form className='form-audio'>
                    <div className="add-new-icon-model-title" data-testid="add-new-icon-model-title" id="add-new-icon-model-title">
                       {isUpdate ?  "Update Tone" : "Add Tone"}
                    </div>
                    <div className="file-adding-container">
                        <div className="audio-popup-cont">
                            <div className='title-cont title-cont-device'>
                                <label className="add-new-icon-model-icon-label tone-label" data-testid="selected-icon-name-label">Tone Name : </label>
                                <input type='text' value={toneName} data-testid="input-tone-name" onChange={(e)=>{setToneName(e.target.value);setValidAddAudioForm(true);setValidToneName(true)}} className={validToneName ? "add-new-icon-model-icon-input-box toneName-input" : "add-new-icon-model-icon-input-box toneName-input error-input"}></input>
                            </div>
                            <div className='title-cont title-cont-second'>
                                <input type="text"  placeholder="File Name"value={toneFileName} title={toneFileName}className="add-new-icon-model-icon-input-box tone-file-input" data-testid="selected-icon-name-inputBox" readOnly/>
                                <div className="small-button audio-choose-position">
                                    <div className="inputfile-box">
                                        <input  type="file" id="add-alarm-icon" data-testid="chooseFileBtn" className="inputfile" ref={fileUpload} onChange={(e) => { handleInputChange(e) }} />
                                        <label htmlFor="add-alarm-icon">
                                            <span id="file-name" className="file-box"></span>
                                            <span data-testid="chooseFilelabel" id="chooseFilelabel" className='small-button'>
                                                Choose File
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {toneInfo.file.name ? null :
                                    <div className="no-file-option" data-testid="no-file-option">
                                        No file choosen
                                    </div>
                                }
                            </div>
                            
                        </div>
                        
                       
                    </div>
                    <div className='previewAndFormat'>
                        <div className="selected-tone" data-testid="selected-img">
                            {toneInfo.filepreview !== null &&
                                <audio controls  id="audioFile" className='preview-tone borderChange' preload="auto" >
                                    <source src={toneInfo.filepreview ? toneInfo.filepreview : ""} type="audio/wav" />
                                </audio>
                            }
                        </div>

                        <div className="supportedFileText" data-testid="img-type">
                            Choose only wav files and size within 300 kb.
                        </div>

                    </div>
                    <div className='errorMessageDiv'>
                        <div className="user-error-msg add-icon-error-msg" data-testid="add-icon-error-msg">
                            {!validAddAudioForm && errorMessage}
                        </div>
                    </div>
                    <div className="popup-button-container add-icon-btn-container">
						<button className="cancel-button" type="button" onClick={(e) => {
                                    e.preventDefault();
                                    props.onCancel()
                                }} data-testid="cancel-btn" id="cancel-btn">
                                Cancel
                        </button>
						<div class="vertical_line" ></div>
						<button className="save-button" type="submit" onClick={addAudiosubmitHandler} data-testid="add-tone-btn" id="add-tone-btn">{isUpdate ? "Update Tone" :"Add Tone"}</button>
					</div>
                </form>
            </div>
        </div>
    )
}
export default AudioFileUploadPopUp
