import React from "react";
import "../style/Footer.css";
import HeaderLogo from "../../assets/images/Status_Solutions_Logo.svg";
import FooterLogoMobile from "../../assets/images/Status_Solution_Logo-Footer.svg"
import NetworkDirectorylogo from "../assets/svg/FooterNetworkDirectory.png"
import NetworkDirectoryLogoMobile from "../../assets/images/StatusSolutionsNetwork-Logo-mobile.svg"
import variables from "../variables/globals";

function Footer() {
	return (
		<div className="footer-container" data-testid="footer-container">
			<div className="conatct-container" data-testid="contact-container-testid">
				<div className="logo-container">
					<a className="aTag" href="https://www.statussolutions.com/" target="_blank" rel="noreferrer">
						<img className="ssLogoImage"
							data-testid="footer-logo-web"
							src={HeaderLogo}
							alt="Status Solution Footer Logo"
						/>
					</a>
					<a className="aTag-mobile-logo" href="https://www.statussolutions.com/" target="_blank" rel="noreferrer">
						<img className="mobile-logo"
							data-testid="footer-logo"
							src={FooterLogoMobile}
							alt="Status Solution Footer Logo"
						/>
					</a>
				</div>
				<div className="version-divA">
					<small data-testid="version" className="version">{variables.version}</small>
				</div>
			</div>
			<div className="copyright-txt">
				<div className="middlePortion">
					<div className="middle-content">
						<a data-testid="eula-pdf"  href= {window.location.origin+"/home/eulaview"} target="_blank" rel="noopener noreferrer">EULA</a>
						<a data-testid="privacy-policy" href="https://www.statussolutions.com/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy&nbsp;Policy</a>
						<a data-testid="contact-us" href="https://www.statussolutions.com/contact-us-status-solutions/" target="_blank" rel="noopener noreferrer">Contact&nbsp;Us</a>
					</div>
					<div className="copyright-text">
						<small className="copyright-text" data-testid="copy-right-content">
							Copyright © 2024. Status Solutions, LLC. All Rights Reserved.
						</small>
						<small className="copyright-text-mobile" data-testid="copy-right-content-mobile">
							© 2024 Status Solutions, LLC.
						</small>
					</div>
				</div>
			</div>
			<div className="network-directory-ctr">
				<a className="network-directory-logo-link" href="https://www.statussolutionsnetwork.com/network-directory/" data-testid="network-directory-logo-link" id="network-directory-logo-link" target="_blank" rel="noreferrer"><img className="network-directory-image"src={NetworkDirectorylogo} data-testid="network-directory-logo" id="network-directory-logo" alt="network dirctory" /></a>
				<a className="mobile-network-directory-logo-link" href="https://www.statussolutionsnetwork.com/network-directory/" target="_blank" rel="noreferrer">
					<img className="network-directory-mobile-logo"
						data-testid="footer-logo"
						src={NetworkDirectoryLogoMobile}
						alt="Status Solution Footer Logo"
					/>
				</a>
			</div>
		</div>
	);
}

export default Footer;
