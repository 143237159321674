import React, { useEffect, useState } from 'react'
import InputField from '../../../common/component/InputField'
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useSelector, useDispatch } from 'react-redux';
import * as API from "../../../common/api/index";
import URL from "../../../common/api/constantURL";
import { setSnackData, setLoading } from '../../../redux/action/userAction';
import "./AddMusteringLocation.css"
import * as functions from "../../../common/functions/validateFunctions";
import Card from '../../../common/component/Card';
import {BiCommand} from "react-icons/bi"

function AddMusteringLocation(props) {
	const groups = useSelector(state => state.groupReducer.groups)
	const dispatch = useDispatch();
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [assignedGroups, setAssignedGroups] = useState([]);
	const [unAssignedGroups, setUnAssignedGroups] = useState([]);
	const [locationName, setLocationName] = useState("")
	const [locationNameError, setLocationNameError] = useState("")
	const [errorMessage, setErrorMessage] = useState("")
	const [assignedSelectAllCheckBox, setAssinedSelectAllCheckBox] = useState(false)
	const [unassignedSelectAllCheckBox, setUnassinedSelectAllCheckBox] = useState(false)
	
	useEffect(() => {
		if (props.data.id) {
			setLocationName(props.data.musteringLocation || "")
			setGroups(props.data.groupIds)
		}else{
			setGroups([])
		}
	}, [])

	const setGroups = (selectedIds) => {
		let tempAssigenedGroup = [];
		let tempUnAssignedGroup = [];
		groups.map((data) => {
			if (selectedIds.includes(data.id)) {
				tempAssigenedGroup.push(data)
			} else {
				tempUnAssignedGroup.push(data)
			}
		})
		setUnAssignedGroups(tempUnAssignedGroup)
		setAssignedGroups(tempAssigenedGroup)
	}

	const selectGroup = (groupId) => {
		setSelectedGroups([...groupId])
	}

	const selectGroupMobile = (groupId) => {
		let tempSelectedGroups = selectedGroups;
		let index = tempSelectedGroups.indexOf(groupId)
		if (index > -1) {
			tempSelectedGroups.splice(index, 1);
		} else {
			tempSelectedGroups.push(groupId)
		}
		setSelectedGroups([...tempSelectedGroups])
	}

	const selectAllGroups = (checkStatus,group) => {
		if (checkStatus === true) {
			let tempIds = (group.map((data)=>{
                return (data.id)
            }))
			setSelectedGroups([...tempIds])
		} else {
			setSelectedGroups([])
		}
	}

	const moveUnSelectedGroups = () => {
		setAssinedSelectAllCheckBox(false)
		let tempAssignedValues = assignedGroups
		let tempUnAssignedValues = unAssignedGroups
		let tempSelectedUsers = selectedGroups;
		groups.map((user) => {
			if (selectedGroups.includes(user.id) && tempAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
				tempUnAssignedValues.push(user)
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedGroups([...tempSelectedUsers])
		setAssignedGroups([...tempAssignedValues])
		setUnAssignedGroups([...tempUnAssignedValues])
	}

	const moveSelectedGroups = () => {
		setUnassinedSelectAllCheckBox(false)
		let tempAssignedValues = assignedGroups
		let tempUnAssignedValues = unAssignedGroups
		let tempSelectedUsers = selectedGroups;
		groups.map((user) => {
			if (selectedGroups.includes(user.id) && tempUnAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
				tempAssignedValues.push(user)
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedGroups([...tempSelectedUsers])
		setAssignedGroups([...tempAssignedValues])
		setUnAssignedGroups([...tempUnAssignedValues])
	}

	const updateLocationName = (event)=> {
		setLocationName(event.target.value)
		setLocationNameError("")
	}
	const saveMusteringLocation = async () => {
		setErrorMessage("")
		let locationValidation = functions.validateInput(locationName.trim(), setLocationNameError, "alphanumeric");
		if(locationName.trim().length > 25){
			return setLocationNameError("Location character must be less than 25.")
		}

		if (assignedGroups.length === 0) {
			return setErrorMessage("Select Groups to Continue")
		}
		let AssignedGroupArray = []
		if(assignedGroups.length > 0){
			assignedGroups.map((data)=>{
				AssignedGroupArray.push(data.id)
			})
		}
		if (locationValidation && locationName.trim().length <= 25) {
			let data = {
				"musteringLocation": locationName.trim(),
				"groups": AssignedGroupArray,
				"facilityId": localStorage.getItem("facilityId")
			}
			dispatch(setLoading(true))
			let response = null
			if (props.data.id) {
				data["id"] = props.data.id;
				response = await API.putAPI(URL.musteringLocations, data)
			} else {
				response = await API.postAPI(URL.musteringLocations, data)
			}
			dispatch(setLoading(false))
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					props.onCancel()
					dispatch(setSnackData(snackData))
				} else if (response.result.status === "failure") {

					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else{
				let snackData = {
					showSnack: true,
					snackMessage: "Server down.Try again later.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
	}
	return (
		<div className="modal">
			<div className="modal-content-container add-mustering-location-ctr" data-testid="add-mustering-location-ctr">
				<div className='add-mustering-location-section'>
					<div className='add-mustering-location-title' data-testid="add-mustering-location-title">Add Mustering Location</div>
					<div className='add-mustering-location-label' data-testid="location-name">Name<span>*</span></div>
					<div className='add-mustering-location-input'>
						<InputField
							dataTestid="mustering-location-input"
							fieldType="input-secondary"
							placeholder="Location Name"
							onChange={(e) => updateLocationName(e)}
							autoFocus={true}
							value={locationName}
							className={locationNameError ? "invalid-error":""}
							
						/>
						<div className='input-error-message'  data-testid="input-error-message">{locationNameError}</div>
					</div>
					<div className="group-management-container">
						<div className="group-management-card-container">
						<Card 
									 header="select"
									 title="Groups Available"
									 type="add-group"
									 item={unAssignedGroups}
									 selectedData = {(data)=>selectGroup(data)}
									 selectedDataMobile = {(data)=>{selectGroupMobile(data)}}
									 selectAll = {(checkStatus,data)=>{selectAllGroups(checkStatus,data)}}
									 setSelectAllStatus={(checkStatus)=>{setUnassinedSelectAllCheckBox(checkStatus);setAssinedSelectAllCheckBox(false)}}
									 checkedStatus={unassignedSelectAllCheckBox}
									 selectionData={selectedGroups}
									 dataTestId={"unAssignedGroupData"}
									 selectAllDataTestId={"UnAssignedUser-select-all"}
									 searchDataTestId={"unAssigned-search-filter-input-box"}
									 cardContainerDataTestId={"groups-available-card"}
							/>
							<div className='user-list-option-container-list'>
									<BsArrowRight onClick={() => moveSelectedGroups()} id="move-unAssigned-group" data-testid="move-unAssigned-group"/>
									<BsArrowLeft onClick={() => moveUnSelectedGroups()} id="move-Assigned-group" data-testid="move-Assigned-group"/>
								</div>
								<Card 
								
									  type="add-group"
									 title="Groups Associated"
									 item={assignedGroups}
									 selectedData = {(data)=>selectGroup(data)}
									 selectedDataMobile = {(data)=>{selectGroupMobile(data)}}
									 selectAll = {(checkStatus,data)=>{selectAllGroups(checkStatus,data)}}
									 setSelectAllStatus={(checkStatus)=>{setAssinedSelectAllCheckBox(checkStatus);setUnassinedSelectAllCheckBox(false)}}
									 checkedStatus={assignedSelectAllCheckBox}
									 selectionData={selectedGroups}
									 dataTestId={"AssignedGroupData"}
									 selectAllDataTestId={"AssignedUser-select-all"}
									 searchDataTestId={"unAssigned-search-filter-input-box"}
									 cardContainerDataTestId={"associated-group-card"}
							/>
						</div>
					</div>
					<div className="card-instruction">
						<div>Hold down</div>
						<div className="card-instruction-bold">&nbsp;CTRL&nbsp;</div>
						<div>, or </div>
						<div className="card-instruction-bold">&nbsp;<BiCommand/>&nbsp;</div>
						<div>on a Mac to select more than one.</div> 
					</div>
					<div className='response-container' data-testid="errorMessage">{errorMessage}</div>
					<div className='add-mustering-loc-btn-ctr'>
						<button onClick={(e) => {
							e.preventDefault();
							props.onCancel()
						}} data-testid="cancel-btn" id="cancel-btn" className='cancel-button'>
							Cancel
						</button>
						<div class="vertical_line" ></div>
						<button onClick={()=> saveMusteringLocation()} className='save-button' data-testid="save-btn" id="save-btn">Save Location</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AddMusteringLocation
