import React, { useEffect } from 'react'
import BreadCrumb from "../../../common/component/BreadCrumb";
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import "./DeploymentConfiguration.css"
import { setLoading, setSnackData ,setUserPermissions } from '../../../redux/action/userAction';
import { useDispatch,useSelector } from 'react-redux';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import { setDeloyementConfig } from '../../../redux/action/deployementAction'; 
import { useState } from 'react';
import { validatePermission } from '../../../common/functions/permissionFunctions';
import { useNavigate} from 'react-router-dom'
export default function DeploymentConfiguration() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userPermissions = useSelector(state => state.userReducer.userPermissions)
    const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
    const [enableDeviceBasedAlerting,setEnableDeviceBasedAlerting] = useState()
    const[password,setPassword] = useState()
    const[userName,setUserName] = useState()
    const[mobileChargeInactivityLogout,setMobileChargeInactivityLogout] = useState()
    const[isMobileInactivityLogout,setIsMobileInactivityLogout] = useState()
    const[mobileInactivityLogoutInterval,setMobileInactivityLogoutInterval] = useState()
    const[deviceExist,setDeviceExist] = useState()
    const[passwordError,setPasswordError] = useState(false)
    const[errorMsg,setErrorMsg] = useState("")
    const[inactiveLogoutError,setInactiveLogoutError] = useState(false)
    useEffect(()=>{
        dispatch(setLoading(true))
        fetchDeployementConfiguration()
    },[])
    useEffect(()=>{
        if(deploymentConfiguration.Deployment){ 
            setEnableDeviceBasedAlerting(deploymentConfiguration.Deployment.isDeviceBasedAlert)
            setPassword(deploymentConfiguration.Deployment.devicePassword)
            setUserName(deploymentConfiguration.Deployment.deviceUsername)
            setMobileChargeInactivityLogout(deploymentConfiguration.Deployment.isMobileChargeInactivityLogout)
            setDeviceExist(deploymentConfiguration.Deployment.deviceExist)
            setIsMobileInactivityLogout(deploymentConfiguration.Deployment.isMobileInactivityLogout)
           setMobileInactivityLogoutInterval(deploymentConfiguration.Deployment.mobileInactivityLogoutInterval)
        }
        
      
    },[deploymentConfiguration])

    const BreadCrumbArr = [
        { link: "/home/dashboard", display: "Dashboard", type: "react" },
        { link: '', display: <img src={BreadCrumbIcon} alt="arrow-icon" />, type: 'img' },
        { link: "/home/application/mustering", display: "Application", type: "react" },
        { link: '', display: <img src={BreadCrumbIcon} alt="arrow-icon" />, type: 'img' },
        { link: "/home/application/configurations/deployment-setup", display: "Configurations", type: "react" },
        { link: '', display: <img src={BreadCrumbIcon} alt="arrow-icon" />, type: 'img' },
        { link: "/home/application/configurations/deployment-setup", display: "Deployment Setup", type: "react" },
    ];
    const fetchDeployementConfiguration = async () => {
		let url = URL.alertSetUp + "?facilityId=" + localStorage.getItem("facilityId") + "&type=1&updateSiteId=true";
        dispatch(setLoading(true))
		let response = await API.getAPI(url)
		getPermissions()
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
                let data = response.result.result
                dispatch(setDeloyementConfig(data))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
      
	}

    const getPermissions = async () =>{
		let url = URL.userPermissions + "?userId=" + localStorage.getItem("loginUserId") +"&type=desktop";
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let userPermissions = response.result.result
				let permissions = [];
				if (userPermissions && userPermissions.length > 0) {
					for (let index = 0; index < userPermissions.length; index++) {
						const element = userPermissions[index];
						permissions.push(element.refName)
					}
				}
				dispatch(setUserPermissions(permissions))
			} 
	}}

    const submitHandler = async (event) => {
		event.preventDefault();
        if (password.length < 6 || password.length > 25) {
			setErrorMsg("Password must contain atleast 6 characters and not exceed 25 characters");
			return setPasswordError(true);
        }
        if((mobileInactivityLogoutInterval.length <1 || mobileInactivityLogoutInterval < 60 || mobileInactivityLogoutInterval > 10080)  && isMobileInactivityLogout){
            setErrorMsg("Mobile Inactivity logout interval should be in range of 60 to 10080(7 days)")
            return setInactiveLogoutError(true)
        }
            if(!passwordError && !inactiveLogoutError){
			let data = {
                "id": deploymentConfiguration.id,
                "facilityId": localStorage.getItem("facilityId"),
                "isDeviceBasedAlert": enableDeviceBasedAlerting,
                "isMobileInactivityLogout": isMobileInactivityLogout,
                "mobileInactivityLogoutInterval": mobileInactivityLogoutInterval,
                "isMobileChargeInactivityLogout": mobileChargeInactivityLogout,
            }
            if(!isMobileInactivityLogout){
                data["mobileInactivityLogoutInterval"] = deploymentConfiguration.Deployment.mobileInactivityLogoutInterval
            }
            if(enableDeviceBasedAlerting){
                data = { ...data,  "deviceUsername": userName,
                "devicePassword": password,} 
            }
            let url = URL.updateAlertSetUp + "?facilityId=" + localStorage.getItem("facilityId") + "&type=1";
			dispatch(setLoading(true))
            let response = await API.putAPI(url, data);
			dispatch(setLoading(false))

			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
                    fetchDeployementConfiguration()
					dispatch(setSnackData(snackData))
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down.Try again.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))}
			}
	}

    const InputHandler = (event) => {
		const { name, value } = event.target;
        if(name === "enableDeviceBasedAlerting"){
            setEnableDeviceBasedAlerting(event.target.checked)
        }else if(name === "password"){
            setErrorMsg("")
            setPasswordError(false)
            setPassword(value)
        }else if(name === "mobileChargeInactivityLogout"){
            setMobileChargeInactivityLogout(event.target.checked)
        }
	}
    // function cancelFn(){
    //     if(deploymentConfiguration.Deployment){ 
    //         setEnableDeviceBasedAlerting(deploymentConfiguration.Deployment.isDeviceBasedAlert)
    //         setPassword(deploymentConfiguration.Deployment.devicePassword)
    //         setUserName(deploymentConfiguration.Deployment.deviceUsername)
    //         setMobileChargeInactivityLogout(deploymentConfiguration.Deployment.isMobileChargeInactivityLogout)
    //     }
    // }
    return (
        <div className='deployment-configuration-ctr'>
            <BreadCrumb crumbs={BreadCrumbArr} data-testid={"bread-crums-list"}></BreadCrumb>
            <div className='deployment-setup-ctr'>
                <div className='list-page-title-div'>
                    <div className='list-page-title' data-testid="deployementsetuptitle">Deployment Setup</div>
                </div>
                
                <div className='deployement-setup-configuration-ctr'>
                    <div className='deployement-setup-configuration-title'>Deployment Configurations</div>
                    <div className='device-based-alerting-ctr'>
                        <div className='deployment-checkbox-ctr'>
                            <span>Enable Device-Based Alerting</span>
                            <span><input data-testid="device-based-checkbox" type="checkbox" name='enableDeviceBasedAlerting' className={deviceExist ? "disablingEditConfig":null} disabled={deviceExist} checked={enableDeviceBasedAlerting} onClick={(e)=>{InputHandler(e)}}/></span>
                        </div>
                        <div className='deployment-config-description'>
                            <span>If enabled, this can only be disabled when all Devices have been removed.</span>
                            {(validatePermission(userPermissions, "VIEW_DEVICES")) && <span data-testid="gotodevices" className={deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert ?'goToDeviceLink':"diablingCtr goToDeviceLink"} onClick={(event)=>{event.preventDefault();
                                    if(deploymentConfiguration.Deployment && deploymentConfiguration.Deployment.isDeviceBasedAlert){
                                        navigate("/home/devices");
                                    }
									}}> Go to Devices</span>}
                        </div>
                       <div className={enableDeviceBasedAlerting ?'device-based-alerting-input-ctr':"device-based-alerting-input-ctr diablingCtr"}>
                            <div className='deployment-input-ctr'>
                                <label >Username</label>
                                <input type="text" data-testid="username-test" className='common-input disableUsernameEdit'  name='userName' disabled="disabled" value={userName}/>
                            </div>
                            <div className='deployment-input-ctr'>
                                <label>Password</label>
                                <input type="text" data-testid="password-test" className={passwordError ? 'common-input border-color-error' : 'common-input'} disabled={!enableDeviceBasedAlerting} name='password' value={password} onChange={(e)=>{InputHandler(e)}}/>
                            </div>
                        </div>
                    </div>
                    <div className='mobile-inactivity-ctr'>
                        <div className='deployment-checkbox-ctr'>
                            <span>Enable Mobile Inactivity Logout</span>
                            <span><input data-testid="inactivity-logout-checkbox" type="checkbox" onClick={(e)=>{setIsMobileInactivityLogout(e.target.checked)}} checked={isMobileInactivityLogout}/></span>
                        </div>
                        <div className='deployment-config-description'>If enabled, Users will be logged out for the specified period below.</div>
                        <div className={inactiveLogoutError ? "inactive-input-ctr deployment-input-ctr input-error-border" :'inactive-input-ctr deployment-input-ctr'}>
                            <span><span className='inactivity-logout'>Inactivity Logout</span><span className='tool-tip-text'> in minutes</span></span>
                            <input  data-testid="inactivity-logout-interval" className={isMobileInactivityLogout ?'common-input':"common-input diablingCtr"} type="number" inputmode="numeric" value={mobileInactivityLogoutInterval} disabled={isMobileInactivityLogout ? false : true}  onChange={(e)=>{setErrorMsg("");setInactiveLogoutError(false);setMobileInactivityLogoutInterval(e.target.value)}}/>
                        </div>
                    </div>
                    <div className='mobile-inactivity-charging-ctr'>
                        <div className='deployment-checkbox-ctr'>
                            <span>Enable Mobile Inactivity Logout while Charging</span>
                            <span><input data-testid="mobile-charge-checkbox"  type="checkbox" name='mobileChargeInactivityLogout' checked={mobileChargeInactivityLogout} onClick={(e)=>{InputHandler(e)}} /></span>
                        </div>
                        <div className='deployment-config-description'>If enabled, Users will be logged out of the application while Device is charging</div>
                    </div>
                    <div className='error-ctr'>
                        {(passwordError || inactiveLogoutError) && <span data-testid='error-test'>{errorMsg}</span>}
                    </div>
                    {validatePermission(userPermissions, "EDIT_DEPLOYMENT_CONFIGURATION") ?<div className='btn_parent-center'>
                        {/* <button data-testid="cancel" className = "manage-config-cancel-btn"    onClick={()=>{cancelFn()}}>Cancel</button> */}
                        <button  data-testid="submit" className = "save-button"  onClick={(e)=>{submitHandler(e)}}>Save</button>
                    </div>:null}
                </div>
            </div>
        </div>
    )
}
