import actiontypes from "../constant/action-types";
export const setDevices = (devices) => {
	return {
		type: actiontypes.SET_DEVICES,
		payload:devices,
	};
};
export const setDeviceSearch=(search)=>{
	return {
		type:actiontypes.SET_DEVICES_SEARCH,
		payload:search
	}

}
