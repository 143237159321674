import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import ButtonField from "./ButtonField";
import StatusSolutionLogo from "../../assets/images/Status_Solutions_Logo-Header.svg";
import BlankProfile from "../../assets/images/blankUserProfile.png"
import "../style/Header.css"
import { validatePermission } from "../functions/permissionFunctions";
import { reset } from "../../redux/action/clearAction";

function Header(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const ref = useRef()
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const loginDetails = useSelector(state => state.userReducer.adminDetail)
	const [ButtonPopUp, setButtonPopUp] = useState(false)
	useEffect(() => {
		const checkIfClickedOutside = e => {
			if (ButtonPopUp && ref.current && !ref.current.contains(e.target)) {
				setButtonPopUp(false)
			}
		}
		document.addEventListener("mousedown", checkIfClickedOutside)
		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [ButtonPopUp])

	const SignOutButtonHandler = (event) => {
		event.preventDefault();
		if (localStorage.getItem("rememberMe") === 'true') {
			localStorage.removeItem("loginUserId");
			localStorage.removeItem("firstName")
			localStorage.removeItem("lastName")
			localStorage.removeItem("accessToken")
			localStorage.removeItem("refreshToken")
			localStorage.removeItem("profilePicture")
			localStorage.removeItem("functionalRoleId")
			localStorage.removeItem("siteLogo")
			localStorage.removeItem("siteName")
			localStorage.removeItem("facilityId")
		}
		else {
			localStorage.clear()
		}
		dispatch(reset());
		navigate("/user/login"); 
		// window.location.href = "/user/login";
	};
	const logoHandler = (event) => {
		window.open("https://www.statussolutions.com/", "_blank")
	};
	return (
		<div className="header-container" ref={ref} data-testid="header-container">
			{props.landing ?
				// <div className="header-logo">
				<img src={StatusSolutionLogo} className="header-logo" onClick={() => logoHandler()} alt="Status Solution logo" data-testid="logo"/>
				// </div>
			: null}

			<div className="header-title" data-testid="header-element">
				<h2>CATIE Mobile Admin</h2>
			</div>
			{!props.landing && (
				<div
					className="header-profile-container"
					data-testid="header-profile-container-testid"
					onClick={() => { setButtonPopUp(!ButtonPopUp) }}
				>
					<p data-testid="username">
						Signed in as <strong>{loginDetails.firstName} {loginDetails.lastName}</strong>
					</p>
					<img
						src={localStorage.getItem("profilePicture") ? loginDetails.profilePicture : BlankProfile}
						alt="User Profile"
						title={localStorage.getItem("user")}
						data-testid="profile_picture"
						onError={(e) => { e.target.onerror = null; e.target.src = BlankProfile }}
					/>
					<BsThreeDotsVertical className="profile-viewer-btn" />
					{ButtonPopUp && <div className="header-pop-up-ctr" data-testid="sign-out-popup">
						{validatePermission(userPermissions, 'VIEW_PROFILE') && localStorage.getItem("functionalRoleId") !== "1" ?
							<div className="profile-header-ctr" onClick={() => { navigate("/home/profile"); setButtonPopUp(false) }} data-testid="profile-nav">Profile</div>
						:null}
						<div className="signout-header-ctr" data-testid="header-sign-out-btn" onClick={SignOutButtonHandler}>Sign Out</div>
					</div>}
					<ButtonField
						label={"Sign out"}
						onClick={SignOutButtonHandler}
					/>
				</div>
			)}
		</div>
	);
}

export default Header;
