import React from "react";
import { useState, useEffect } from "react";
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import SignInPage from "./SignInPage";
import ConfirmationPopUp from "../../common/component/Confirmation"

function SignInFromResetPassword() {
	const [navigateToApp, setNavigateToApp] = useState(false)

	const appLink = isAndroid ? "catmo://open" : "catmo://";
	const fallbackAndroid = "https://play.google.com/store/apps/details?id=com.statussolutions.catmobile";
	const fallbackiOS = "https://apps.apple.com/in/app/catie-mobile/id1613885464"; 

	useEffect(() => {
		if (isMobile) {	
			setNavigateToApp(true)
		}
	}, []);

	const openApp = () => {
		window.open(appLink, "_top");
		setTimeout(() => {
			if (isAndroid) {
				window.location.href = fallbackAndroid;
			} else if (isIOS) {
				window.location.href = fallbackiOS;
			}
		}, 2000);
	}

    return (
        <React.Fragment>
           <SignInPage/>
			{
				navigateToApp && 
				<ConfirmationPopUp style = {true} className="modal-indexed"  buttonValue="Proceed" deleteMessage={"Would you like to open in the CATIE Mobile app?"} successFunc={() =>{setNavigateToApp(false); openApp()}} cancelFunc={() => setNavigateToApp(false)}/>
			}

        </React.Fragment>
    );
}
export default SignInFromResetPassword;