import React, { useState, useEffect } from 'react'
import InputField from '../../common/component/InputField'
import addIconSymbol from '../../assets/svg/plus_symbol.svg';
import ButtonField from '../../common/component/ButtonField';
import "../Style/ManageAlarm.css";
import CustomSelect from '../../common/component/SelectBox';
import AddNewIcon from './Application/AddNewIcon';
import { useSelector, useDispatch } from 'react-redux';
import * as API from '../../common/api/index.js';
import URL from "../../common/api/constantURL";
import {setAlertIcon, setLoading} from '../../redux/action/userAction';
import { setSnackData } from '../../redux/action/userAction';
import Card from '../../common/component/Card';
import { BsArrowRight } from 'react-icons/bs';
import { BsArrowLeft } from 'react-icons/bs';
import { BiCommand } from "react-icons/bi"
function ManageAlarm(props) {
    const dispatch = useDispatch()
    const groups = useSelector(state => state.groupReducer.groups)
    const users = useSelector(state => state.userReducer.users)
    const devices = useSelector(state => state.deviceReducer.devices)
    const color = useSelector(state => state.userReducer.alertColor.result)
    const icon = useSelector(state => state.userReducer.alertIcon.result)
    const [icons, setIcons] = useState(icon)
    const propsData = props.data;
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [unAssignedUsers, setUnAssignedUsers] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([])
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [unAssignedGroups, setUnAssignedGroups] = useState([]);
    const [unAssignedDevices, setUnAssignedDevices] = useState([]);
    const [assignedDevices, setAssignedDevices] = useState([]);
    var [eventName, setEventName] = useState("")
    var [validEventName, setValidEventName] = useState(true)
    var [unassignedDeviceSelectAllCheckBox, setUnassinedDeviceSelectAllCheckBox] = useState(false)
    var [assignedDeviceSelectAllCheckBox, setAssinedDeviceSelectAllCheckBox] = useState(false)

    var [unassignedUserSelectAllCheckBox, setUnassinedUserSelectAllCheckBox] = useState(false)
    var [assignedUserSelectAllCheckBox, setAssinedUserSelectAllCheckBox] = useState(false)

    var [unassignedGroupSelectAllCheckBox, setUnassinedGroupSelectAllCheckBox] = useState(false)
    var [assignedGroupSelectAllCheckBox, setAssinedGroupSelectAllCheckBox] = useState(false)

    const [enableDescription, setEnableDescription] = useState(props.data.enableDescription ? true : false)
    const [alarmNameErrorMsg, setAlarmNameErrorMsg] = useState("")

    const [showUsersCard, setShowUsersCard] = useState(false)
    const [showDevicesCard, setShowDevicesCard] = useState(false)
    const [showGroupsCard, setShowGroupsCard] = useState(true)
    const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
    const [isDeviceBasedAlert,setIsDeviceBasedAlert] = useState(false);
    const [isInitialize, setIsInitialize] = useState(false)
    useEffect(() => {
        if(deploymentConfiguration.Deployment){
            setIsDeviceBasedAlert(deploymentConfiguration.Deployment.isDeviceBasedAlert)
        }  
    }, [])

    const fetchIcon = async () => {
        let url = URL.iconByFacility + "?facilityId=" + localStorage.getItem("facilityId");
        if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
            url = URL.icon
        }
        let response = await API.getAPI(url)
        if (response.fetchStatus === "success") {
            dispatch(setAlertIcon(response.result))
        } else if (response.fetchStatus === "failure") {
            let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
        }
    }

    const [isActiveColor, setActiveColor] = useState(false);
    const [isActiveIcon, setActiveIcon] = useState(false);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedIcon, setSelectedIcon] = useState('');
    const [selectIconError, setIconSelectError] = useState(false);
    const [selectColorError, setColorSelectError] = useState(false);
    const [addNewIconPopup, setAddNewIconPopup] = useState(false);
    const [iconId,setIconId] = useState();

    useEffect(() => {
        
        if (propsData.name) {
            setEventName(propsData.name)
        }

        if (icons) {
            let arr = []
            icons && icons.map(item =>
                arr.push(item.id)
            )
            if (selectedIcon && !arr.includes(selectedIcon.id)) {
                setSelectedIcon(null)
            }

        }
        if (propsData.icon) {
            icon && icon.map((e) => {
                if (propsData.icon === e.url) {
                    setIconId(e.id)
                    setSelectedIcon(e)
                }
            })
            if (iconId) {
                icon && icon.map((e) => {
                    if (iconId === e.id) {
                        setSelectedIcon(e)
                    }
                })
            }

        }
    }, [icons]);
    useEffect(() => {
        setIcons(icon)

        let tempAssignedUsers = [];
        let tempUnAssignedUsers = [];
        let tempAssigenedGroup = [];
        let tempUnAssignedGroup = [];

        users.map((user) => {
            if (propsData.users && propsData.users.includes(user.id)) {
                tempAssignedUsers.push(user)
            } else {

                tempUnAssignedUsers.push(user)
            }
           
        })
        groups.map((data) => {
            if (propsData.groups && propsData.groups.includes(data.id)) {
                tempAssigenedGroup.push(data)
            } else {
                tempUnAssignedGroup.push(data)
            }
        })

        let tempAssigenedDevice = [];
        let tempUnAssignedDevice = [];


        devices.map((data) => {
            if (propsData.devices && propsData.devices.includes(data.id)) {
                tempAssigenedDevice.push(data)
            } else {
                tempUnAssignedDevice.push(data)
            }
        })

        if (!isInitialize) {
            if (propsData.buttonColor) {
                const str = propsData.buttonColor;
                const replacedString = str.replace(/#/g, "");
                color && color.filter(item =>
                    item.hexValue.includes(replacedString) ? setSelectedColor(item) : null
                )
            }
            
            setUnAssignedDevices(tempUnAssignedDevice)
            setAssignedDevices(tempAssigenedDevice)
            setUnAssignedGroups(tempUnAssignedGroup)
            setAssignedGroups(tempAssigenedGroup)
            setUnAssignedUsers(tempUnAssignedUsers)
            setAssignedUsers(tempAssignedUsers)
            setIsInitialize(true)
        }
    }, [users,groups,devices,icon])

    const selectUser = (userId) => {
        setSelectedUsers([...userId])
    }
    const selectUserMobileView = (userId) => {
        let tempSelectedUsers = selectedUsers;
        let index = tempSelectedUsers.indexOf(userId)
        if (index > -1) {
            tempSelectedUsers.splice(index, 1);
        } else {
            tempSelectedUsers.push(userId)
        }
        setSelectedUsers([...tempSelectedUsers])
    }
    const selectAllUser = (checkStatus, user) => {
        if (checkStatus === true) {
            let tempIds = (user.map((data) => {
                return (data.id)
            }))
            setSelectedUsers([...tempIds])
        } else {
            setSelectedUsers([])
        }
    }
    const selectGroup = (groupId) => {
        setSelectedGroups([...groupId])
    }
    const selectGroupMobile = (groupId) => {
        let tempSelectedGroups = selectedGroups;
        let index = tempSelectedGroups.indexOf(groupId)
        if (index > -1) {
            tempSelectedGroups.splice(index, 1);
        } else {
            tempSelectedGroups.push(groupId)
        }
        setSelectedGroups([...tempSelectedGroups])
    }
    const selectAllGroups = (checkStatus, group) => {
        if (checkStatus === true) {
            let tempIds = (group.map((data) => {
                return (data.id)
            }))
            setSelectedGroups([...tempIds])
        } else {
            setSelectedGroups([])
        }
    }


    const selectDevice = (deviceId) => {
        setSelectedDevices([...deviceId])
    }
    const selectDeviceMobile = (deviceId) => {
        let tempSelectedDevices = selectedDevices;
        let index = tempSelectedDevices.indexOf(deviceId)
        if (index > -1) {
            tempSelectedDevices.splice(index, 1);
        } else {
            tempSelectedDevices.push(deviceId)
        }
        setSelectedDevices([...tempSelectedDevices])
    }
    const selectAllDevices = (checkStatus, device) => {
        if (checkStatus === true) {
            let tempIds = (device.map((data) => {
                return (data.id)
            }))
            setSelectedDevices([...tempIds])
        } else {
            setSelectedDevices([])
        }
    }

    const moveUnSelectedUsers = () => {
        setAssinedUserSelectAllCheckBox(false)
        let tempAssignedValues = assignedUsers
        let tempUnAssignedValues = unAssignedUsers
        let tempSelectedUsers = selectedUsers;
        users.map((user) => {
            if (selectedUsers.includes(user.id) && tempAssignedValues.map((data) => { return data.id }).includes(user.id)) {
                tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
                tempUnAssignedValues.push({
                    id: user.id,
                    advisorId: user.advisorId,
                    age: user.age,
                    facilityId: user.facilityId,
                    firstName: user.firstName,
                    functionalRoleId: user.functionalRoleId,
                    gender: user.gender,
                    groupIds: user.groupIds,
                    interests: user.interests,
                    lastName: user.lastName,
                    location: user.location,
                    middleName: user.middleName,
                    nickName: user.nickName,
                    prefix: user.prefix,
                    profilePicture: user.profilePicture,
                    roleId: user.roleId,
                    suffix: user.suffix,
                    umsUserId: user.umsUserId,
                    userName: user.userName
                })
                tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
            }
        })
        setSelectedUsers([...tempSelectedUsers])
        setAssignedUsers([...tempAssignedValues])
        setUnAssignedUsers([...tempUnAssignedValues])
    }
    useEffect(() => {

	}, [assignedDevices, unAssignedDevices])
	useEffect(() => {

	}, [assignedUsers, unAssignedUsers])

    const moveSelectedUsers = () => {
        setUnassinedUserSelectAllCheckBox(false)
        let tempAssignedValues = assignedUsers
        let tempUnAssignedValues = unAssignedUsers
        let tempSelectedUsers = selectedUsers;

        users.map((user) => {
            if (selectedUsers.includes(user.id) && tempUnAssignedValues.map((data) => { return data.id }).includes(user.id)) {
                tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
                tempAssignedValues.push({
                    id: user.id,
                    advisorId: user.advisorId,
                    age: user.age,
                    facilityId: user.facilityId,
                    firstName: user.firstName,
                    functionalRoleId: user.functionalRoleId,
                    gender: user.gender,
                    groupIds: user.groupIds,
                    interests: user.interests,
                    lastName: user.lastName,
                    location: user.location,
                    middleName: user.middleName,
                    nickName: user.nickName,
                    prefix: user.prefix,
                    profilePicture: user.profilePicture,
                    roleId: user.roleId,
                    suffix: user.suffix,
                    umsUserId: user.umsUserId,
                    userName: user.userName
                })
                tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
            }
        })
        setSelectedUsers([...tempSelectedUsers])
        setAssignedUsers([...tempAssignedValues])
        setUnAssignedUsers([...tempUnAssignedValues])
    }
    const moveUnSelectedGroups = () => {
        setAssinedGroupSelectAllCheckBox(false)
        let tempAssignedValues = assignedGroups
        let tempUnAssignedValues = unAssignedGroups
        let tempSelectedUsers = selectedGroups;
        groups.map((user) => {
            if (selectedGroups.includes(user.id) && tempAssignedValues.map((data) => { return data.id }).includes(user.id)) {
                tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
                tempUnAssignedValues.push(user)
                tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
            }
        })
        setSelectedGroups([...tempSelectedUsers])
        setAssignedGroups([...tempAssignedValues])
        setUnAssignedGroups([...tempUnAssignedValues])
    }
    const moveSelectedGroups = () => {
        setUnassinedGroupSelectAllCheckBox(false)
        let tempAssignedValues = assignedGroups
        let tempUnAssignedValues = unAssignedGroups
        let tempSelectedUsers = selectedGroups;
        groups.map((user) => {
            if (selectedGroups.includes(user.id) && tempUnAssignedValues.map((data) => { return data.id }).includes(user.id)) {
                tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
                tempAssignedValues.push(user)
                tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
            }
        })
        setSelectedGroups([...tempSelectedUsers])
        setAssignedGroups([...tempAssignedValues])
        setUnAssignedGroups([...tempUnAssignedValues])
    }


    const moveUnSelectedDevices = () => {
        setAssinedDeviceSelectAllCheckBox(false)
        let tempAssignedValues = assignedDevices
        let tempUnAssignedValues = unAssignedDevices
        let tempSelectedDevices = selectedDevices;
        devices.map((device) => {
            if (selectedDevices.includes(device.id) && tempAssignedValues.map((data) => { return data.id }).includes(device.id)) {
                tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === device.id), 1);
                tempUnAssignedValues.push(device)
                tempSelectedDevices.splice(tempSelectedDevices.indexOf(device.id), 1);
            }
        })
        setSelectedDevices([...tempSelectedDevices])
        setAssignedDevices([...tempAssignedValues])
        setUnAssignedDevices([...tempUnAssignedValues])
    }
    const moveSelectedDevices = () => {
        setUnassinedDeviceSelectAllCheckBox(false)
        let tempAssignedValues = assignedDevices
        let tempUnAssignedValues = unAssignedDevices
        let tempSelectedDevices = selectedDevices;
        devices.map((device) => {
            if (selectedDevices.includes(device.id) && tempUnAssignedValues.map((data) => { return data.id }).includes(device.id)) {
                tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === device.id), 1);
                tempAssignedValues.push(device)
                tempSelectedDevices.splice(tempSelectedDevices.indexOf(device.id), 1);
            }
        })
        setSelectedDevices([...tempSelectedDevices])
        setAssignedDevices([...tempAssignedValues])
        setUnAssignedDevices([...tempUnAssignedValues])
    }



    const formSubmitHandler = async (event) => {
        let assignedUserId = [];
        assignedUsers && assignedUsers.map((data) => {
            assignedUserId.push(data.id)
        })
        let assignedGroupId = []
        if (assignedGroups.length > 0) {
            assignedGroups.map((data) => {
                assignedGroupId.push(data.id)
            })
        }

        let assignedDeviceId = []
        if (assignedDevices.length > 0) {
            assignedDevices.map((data) => {
                assignedDeviceId.push(data.id)
            })
        }

        if (eventName.trim().length <= 0) {
            return setValidEventName(false)
        }
        if (eventName.trim().length > 100) {
            setAlarmNameErrorMsg("Alarm name should not exceed 100 characters.")
            return setValidEventName(false)
        }
        !selectedColor ? setColorSelectError(true) : setColorSelectError(false);
        let response = null
        let data = {
            "facilityId": localStorage.getItem("facilityId"),
            "name": eventName.trim(),
            "buttonName": eventName.trim(),
            "buttonColor": selectedColor.hexValue,
            "icon": selectedIcon ?selectedIcon.fileName : null,
            "enableDescription": enableDescription ? 1 : 0,
            "groups": assignedGroupId,
            "users": assignedUserId,
            "devices": assignedDeviceId
        }
        if (validEventName && (!selectedColor == '')) {
            dispatch(setLoading(true))
            if (propsData.id) {
                let data = {
                    "id": propsData.id,
                    "facilityId": localStorage.getItem("facilityId"),
                    "name": eventName.trim(),
                    "buttonName": eventName.trim(),
                    "buttonColor": selectedColor.hexValue,
                    "icon": selectedIcon ? selectedIcon.fileName : null,
                    "enableDescription": enableDescription ? 1 : 0,
                    "groups": assignedGroupId,
                    "users": assignedUserId,
                    "devices": assignedDeviceId 
                }
                response = await API.putAPI(URL.alarms, data)
                dispatch(setLoading(false))
                if (response.fetchStatus === "success") {
                    let snackData = {
                        showSnack: true,
                        snackMessage: "Alarm button updated successfully.",
                        snackVariant: "success"
                    }
                    props.onCancel()
                    dispatch(setSnackData(snackData))
                } else {
                    let snackData = {
                        showSnack: true,
                        snackMessage: "Unable update alarm button. Try again!",
                        snackVariant: "error"
                    }
                    dispatch(setSnackData(snackData))
                }
            }
            else {
                response = await API.postAPI(URL.alarms, data)
                dispatch(setLoading(false))
                if (response.fetchStatus === "success") {
                    let snackData = {
                        showSnack: true,
                        snackMessage: "Alarm button added successfully.",
                        snackVariant: "success"
                    }
                    props.onCancel()
                    dispatch(setSnackData(snackData))

                } else {
                    let snackData = {
                        showSnack: true,
                        snackMessage: "Unable add. Try again!",
                        snackVariant: "error"
                    }
                    dispatch(setSnackData(snackData))
                }
            }
        }
    }

    
    function navigateCard(users,devices,groups) {
        setShowUsersCard(users)
        setShowDevicesCard(devices)
        setShowGroupsCard(groups)
    }
    return (
        <div className="modal">
            <div className="modal-content-container create-alert-modal" data-testid="create-alert-modal">
                        <div className='create-alert-container'>
                            <div className='label-alighn-container'>
                                <div className='event-title-input-box' data-testid="event-title-lable" id="event-title-lable">Event Name</div>
                                <span className='required-symbol'>&nbsp;*</span>
                            </div>
                            <div className='event-name-input-box'>
                                <InputField
                                    dataTestid="event-name-inputBox"
                                    id="event-name-inputBox"
                                    fieldType="input-secondary"
                                    placeholder="Name of Event"
                                    onChange={(event) => {
                                        setValidEventName(true)
                                        setEventName(event.target.value)
                                        setAlarmNameErrorMsg("")
                                    }}
                                    value={eventName}
                                    className={!validEventName && " invalid-error"}
                                    autoFocus={true}
                                />
                            </div>
                            {!validEventName && alarmNameErrorMsg.length > 0 && <div data-testid="manage-user-error-msg" class="user-error-msg">{alarmNameErrorMsg}</div>}
                            <div className='create-alert-icon-color-selection-container'>
                                <div className='create-alert-select-btn-container'>
                                    <div className='label-alighn-container'>
                                        <label className={selectColorError ? 'invalid' : null} data-testid="button-color-label">
                                            Button Color
                                        </label>
                                        <span className='required-symbol'>&nbsp;*</span>
                                    </div>

                                    <CustomSelect
                                        trigger={isActiveColor}
                                        setTrigger={setActiveColor}
                                        option={color}
                                        selectItem={selectedColor}
                                        selectedItem={setSelectedColor}
                                        type="Color"
                                        valid={selectColorError}
                                        setValid={setColorSelectError}
                                        dataTestid="button-color-selectBox"
                                    />
                                </div>
                                <div className='create-alert-select-btn-container alarm-icon'>
                                    <div className='label-alighn-container'>
                                        <label className={selectIconError ? 'invalid' : null} data-testid="icon-label">
                                            Icon
                                        </label>
                                    </div>
                                    <CustomSelect
                                        trigger={isActiveIcon}
                                        setTrigger={setActiveIcon}
                                        option={icon}
                                        selectItem={selectedIcon}
                                        selectedItem={setSelectedIcon}
                                        type="Icon"
                                        valid={selectIconError}
                                        setValid={setIconSelectError}
                                        dataTestid="button-icon-selectBox"
                                    />
                                </div>
                                <div className='add-new-icon-btn' onClick={() => setAddNewIconPopup(true)}>
                                    <img src={addIconSymbol} className='add_icon_symbol' alt=' '/>
                                    <button className='add_icon_btn'  data-testid="add-new-icon-label" id="add-new-icon-label">Add New Icon</button>
                                </div>
                                    {addNewIconPopup ? <AddNewIcon trigger={addNewIconPopup} setTrigger={() => {
                                        fetchIcon()
                                        setAddNewIconPopup(false)
                                    }} /> : null}
                            </div>

                            <div>
                                <span className='enable-description-checkbox-ctr'>
                                    <input type="checkbox" checked={enableDescription} onClick={() => { setEnableDescription(!enableDescription) }} onChange={() => { }} data-testid="enable-describtion-checkbox" id="enable-describtion-checkbox" />
                                    <span className="enable-description-label" onClick={() => { setEnableDescription(!enableDescription) }}>Enable Description</span>
                                </span>
                            </div>

                            <div className='select-card'>
                                <button className={showGroupsCard ? 'card-selected' : ''} onClick={()=>{navigateCard(false,false,true)}} id='groupsButton' data-testid='groupsNav'>Groups - {assignedGroups.length}</button>
                                <div className='align'><div class="vertical-line"></div></div>
                                <button className={showUsersCard ? 'card-selected' : ''} onClick={()=>{navigateCard(true,false,false)}} id='usersButton' data-testid='usersNav'>Users - {assignedUsers.length}</button>
                                {isDeviceBasedAlert &&<div className='align'><div class="vertical-line"></div></div>}
                                {isDeviceBasedAlert &&<button className={showDevicesCard ? 'card-selected' : ''} onClick={()=>{navigateCard(false,true,false)}} id='devicesButton' data-testid='devicesNav'>Devices - {assignedDevices.length}</button>}
                            </div>

                            {showGroupsCard && <div className='create-alert-selection-container'>
                                <Card
                                    header="select"
                                    title="Groups Available"
                                    type="add-group"
                                    item={(unAssignedGroups)}
                                    selectedData={(data) => selectGroup(data)}
                                    selectAll={(checkStatus, data) => { selectAllGroups(checkStatus, data) }}
                                    selectedDataMobile={(data) => { selectGroupMobile(data) }}
                                    setSelectAllStatus={(checkStatus) => { setUnassinedGroupSelectAllCheckBox(checkStatus); setAssinedGroupSelectAllCheckBox(false) }}
                                    checkedStatus={unassignedGroupSelectAllCheckBox}
                                    selectionData={selectedGroups}
                                    dataTestId={"unassigned-group"}
                                    selectAllDataTestId={"unAssigned-checkbox-selectAll"}
                                    searchDataTestId={"unAssigned-search-filter-input-box"}
                                    cardContainerDataTestId={"group-card"}
                                />
                                <div className='user-list-option-container-list'>
                                    <BsArrowRight onClick={() => moveSelectedGroups()} id="move-unAssigned-group" data-testid="move-unAssigned-group" />
                                    <BsArrowLeft onClick={() => moveUnSelectedGroups()} id="move-Assigned-group" data-testid="move-Assigned-group" />
                                </div>
                                <Card
                                    type="add-group"
                                    title="Groups Associated"
                                    item={(assignedGroups)}
                                    selectedData={(data) => selectGroup(data)}
                                    selectedDataMobile={(data) => { selectGroupMobile(data) }}
                                    selectAll={(checkStatus,data) => { selectAllGroups(checkStatus, data) }}
                                    setSelectAllStatus={(checkStatus) => { setAssinedGroupSelectAllCheckBox(checkStatus); setUnassinedGroupSelectAllCheckBox(false) }}
                                    checkedStatus={assignedGroupSelectAllCheckBox}
                                    selectionData={selectedGroups}
                                    dataTestId={"assigned-group-data"}
                                    selectAllDataTestId={"Assigned-checkbox-selectAll"}
                                    searchDataTestId={"unAssigned-search-filter-input-box"}
                                    cardContainerDataTestId={"group-card"}
                                />
                            </div>}
                            {showUsersCard && <div className='create-alert-selection-container user-alert-selection-list'>
                                <Card
                                    header="select"
                                    type="add-users-group"
                                    title="Users Available"
                                    item={(unAssignedUsers)}
                                    selectedData={(data) => selectUser(data)}
                                    selectedDataMobile={(data) => { selectUserMobileView(data) }}
                                    selectAll={(checkStatus,data) => { selectAllUser(checkStatus, data) }}
                                    setSelectAllStatus={(checkStatus) => { setUnassinedUserSelectAllCheckBox(checkStatus); setAssinedUserSelectAllCheckBox(false) }}
                                    checkedStatus={unassignedUserSelectAllCheckBox}
                                    selectionData={selectedUsers}
                                    dataTestId={"unAssignedUserData"}
                                    selectAllDataTestId={"UnAssignedUser-select-all"}
                                    searchDataTestId={"unAssigned-search-filter-input-box"}
                                />
                                <div className='user-list-option-container-list'>
                                    <BsArrowRight onClick={() => moveSelectedUsers()} id="move-unAssigned-users" data-testid="move-unAssigned-users" />
                                    <BsArrowLeft onClick={() => moveUnSelectedUsers()} id="move-Assigned-users" data-testid="move-Assigned-users" />
                                </div>
                                <Card
                                    headers="deselect"
                                    type="add-users-group"
                                    title="Users Associated"
                                    item={(assignedUsers)}
                                    selectedData={(data) => selectUser(data)}
                                    selectionData={selectedUsers}
                                    selectAll={(checkStatus,data) => { selectAllUser(checkStatus, data); }}
                                    setSelectAllStatus={(checkStatus) => { setAssinedUserSelectAllCheckBox(checkStatus); setUnassinedUserSelectAllCheckBox(false) }}
                                    checkedStatus={assignedUserSelectAllCheckBox}
                                    dataTestId={"AssignedUserData"}
                                    selectAllDataTestId={"AssignedUser-select-all"}
                                    selectedDataMobile={(data) => { selectUserMobileView(data) }}
                                />
                            </div>}
                            {showDevicesCard && <div className='create-alert-selection-container'>
                                <Card
                                    header="select"
                                    title="Devices Available"
                                    type="add-device"
                                    item={(unAssignedDevices)}
                                    selectedData={(data) => selectDevice(data)}
                                    selectAll={(checkStatus,data) => { selectAllDevices(checkStatus, data) }}
                                    selectedDataMobile={(data) => { selectDeviceMobile(data) }}
                                    setSelectAllStatus={(checkStatus) => { setUnassinedDeviceSelectAllCheckBox(checkStatus); setAssinedDeviceSelectAllCheckBox(false) }}
                                    checkedStatus={unassignedDeviceSelectAllCheckBox}
                                    selectionData={selectedDevices}
                                    dataTestId={"unassigned-device"}
                                    selectAllDataTestId={"unAssigned-checkbox-selectAll"}
                                    searchDataTestId={"unAssigned-search-filter-input-box"}
                                    cardContainerDataTestId={"device-card"}
                                />
                                <div className='user-list-option-container-list'>
                                    <BsArrowRight onClick={() => moveSelectedDevices()} id="move-unAssigned-device" data-testid="move-unAssigned-device" />
                                    <BsArrowLeft onClick={() => moveUnSelectedDevices()} id="move-Assigned-device" data-testid="move-Assigned-device" />
                                </div>
                                <Card
                                    type="add-device"
                                    title="Devices Associated"
                                    item={(assignedDevices)}
                                    selectedData={(data) => selectDevice(data)}
                                    selectedDataMobile={(data) => { selectDeviceMobile(data) }}
                                    selectAll={(checkStatus,data) => { selectAllDevices(checkStatus, data) }}
                                    setSelectAllStatus={(checkStatus) => { setAssinedDeviceSelectAllCheckBox(checkStatus); setUnassinedDeviceSelectAllCheckBox(false) }}
                                    checkedStatus={assignedDeviceSelectAllCheckBox}
                                    selectionData={selectedDevices}
                                    dataTestId={"assigned-device-data"}
                                    selectAllDataTestId={"Assigned-checkbox-selectAll"}
                                    searchDataTestId={"unAssigned-search-filter-input-box"}
                                    cardContainerDataTestId={"device-card"}
                                />
                            </div>}

                            <div className="card-instruction">
                                <div>Hold down</div>
                                <div className="card-instruction-bold">&nbsp;CTRL&nbsp;</div>
                                <div>, or </div>
                                <div className="card-instruction-bold">&nbsp;<BiCommand />&nbsp;</div>
                                <div>on a Mac to select more than one.</div>
                            </div>

                            <div className='create-alert-btn-container'>
                                <ButtonField label="Cancel" onClick={(e) => {
                                    e.preventDefault();
                                    props.onCancel()

                                }} dataTestid="cancel-btn" id="cancel-btn" type="cancel"/>
                                <div class="vertical_line" ></div>
                                <ButtonField label={propsData.id ? "Update" : "Save"} dataTestid={propsData.id ? "UpdateBtn" : "SaveBtn"} type="submit"id={propsData.id ? "UpdateBtn" : "SaveBtn"} onClick={() => formSubmitHandler()} />
                            </div>
                            <div className="row-5 create-alert-required-text">
                                <div className="required-text" data-testid="required-text">* required</div>
                            </div>

                        </div>
                
            </div>
        </div>
    )
}

export default ManageAlarm
