import {combineReducers} from 'redux';
import {userReducer}  from './userReducer';
import {groupReducer} from './groupReducer';
import { accountReducer } from './accountReducer';
import {serverConfigReducer} from './serverConfigReducer'
import { alarmReducer } from './alarmReducer';
import { serviceAllocationReducer } from './serviceAllocationReducer';
import { deviceReducer } from './deviceReducer';
import { deployementReducer } from './deployementReducer';
import { eventReducer } from './eventReducer';
import actiontypes from "../constant/action-types";

const reducers = combineReducers({
    userReducer,
    groupReducer,
    accountReducer,
    serverConfigReducer,
    alarmReducer,
    serviceAllocationReducer,
    deviceReducer,
    deployementReducer,
    eventReducer
})

const rootReducer = (state, action) => {
    if (action.type === actiontypes.RESET_ALL) {
      // Reset the entire state to initial state
      state = undefined;
    }
    return reducers(state, action);
  };
  
export default rootReducer;

