import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as functions from "../../../common/functions/validateFunctions";
import constantURL from "../../../common/api/constantURL";
import * as API from '../../../common/api/index.js';
import Calender from '../../../common/component/Calender';
import TimePicker from "../../../common/component/TimePicker"
import CalenderIcon from "../../../assets/svg/calendarIcon.svg";
import AddImageIcon from "../../../assets/svg/Add-image-icon.svg"
import AddDocumentIcon from "../../../assets/svg/Add-document-icon.svg"
import CopyTextIcon from "../../../assets/svg/CopyTextIcon.svg";
import DownloadIcon from "../../../assets/svg/Download-Icon.svg"
import WordDocIcon from "../../../assets/svg/Word-icon.svg";
import PdfIcon from "../../../assets/svg/pdf-icon.svg";
import DeleteIcon from "../../../assets/svg/ImageDeleteIcon.svg";
import DarkDeleteIcon from "../../../assets/svg/DarkDeleteImageIcon.svg";
import {FaRegEdit} from 'react-icons/fa';
import FileUploadPopUp from "../../../common/component/FileUploadPopUp";
import Confirmation from "../../../common/component/Confirmation";
import {downloadImage} from "../../../common/functions/commonFunctions.js";
import {QRCodeCanvas} from 'qrcode.react';
import moment from 'moment-timezone';
import {setSnackData, setLoading} from '../../../redux/action/userAction';
import key from "../../../common/variables/globals.js"
import "./AddEvents.css"
import { v4 as uuidv4 } from 'uuid';
import ArrowIcon from "../../../assets/images/Drop_down_arrow.png";

function AddEvents (props){
    const dispatch = useDispatch();
    var [updateFlag, setUpdateFlag] = useState(false);
    const [removeImageConfirmation,setRemoveImageConfirmation] = useState(false)
    const [removeDocumentConfirmation,setRemoveDocumentConfirmation] = useState(false)
    const [eventErrorMessage, setEventErrorMessage] = useState('');
	const [isValidEventData, setIsValidEventData] = useState(true);
    const [eventName, setEventName] = useState("")
    const [isEventName, setIsEventName] = useState(true)
    const [eventLocation, setEventLocation] = useState("")
    const [eventDescription, setEventDescription] = useState("")
    const [showStartCalendar, setShowStartCalendar] = useState(false)
    const [showEndCalendar, setShowEndCalendar] = useState(false)
    const [calendarStartDate, setCalendarStartDate] = useState(localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date());
    const [calendarEndDate , setCalendarEndDate] = useState(localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date());
    const[formattedStartDate, setFormattedStartDate]=useState("")
    const[formattedEndDate, setFormattedEndDate]=useState("")
    const nextDate = true;
    const [eventStartDate, setEventStartDate] = useState()
    const [isEventStartDate, setIsEventStartDate] = useState(true)
    const [eventEndDate, setEventEndDate] = useState()
    const [isEventEndDate, setIsEventEndDate] = useState(true)
    const [eventStartTime, setEventStartTime] = useState("")
    const [eventEndTime, setEventEndTime] = useState("")
    const [isStartTime, setIsStartTime] = useState(true)
    const [isEndTime, setIsEndTime] = useState(true)
    const [isOptInRegister, setIsOptInRegister] = useState(false);
    const [isOptInNotRegister, setIsOptInNotRegister] = useState(true);
    const [imgUploadPopUp, setImgUploadPopUp ] =useState(false);
    const [docUploadPopUp, setDocUploadPopUp] = useState(false);
    const [removedImage, setRemovedImage] = useState();
    const [removedDocument, setRemovedDocument] = useState();
    const [showCopiedMessage, setShowCopiedMessage] = useState(false)
    const [imagePaginate, setImagePaginate] = useState(0);
    const [documentPaginate, setDocumentPaginate] = useState(0);
    const [receivedImgData, setReceivedImgData] = useState({ data: [],removedData:[]});
    const [receivedDocData, setReceivedDocData] = useState({ documentData: [],removedData:[] });
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [uniqueId, setUniqueId] = useState("")
    const [deleteData ,setDeleteData]= useState(null)
    const [label, setLabel] = useState("")
    const [selectedfile, setSelectedFile] = useState(null);
    const [index, setIndex] =  useState()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [dateChange, setDateChange] = useState()
    const calendarRef = useRef();
    const endCalendarRef = useRef()
    const QR_code = useRef();
    var form_data = new FormData();
    const eventData={}
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
   
    const itemsPerPage = screenWidth <= 510 ? 3 : 7;
    const receiveImgDataFromFileUploadPopUp = (newData) => {
        const imagesPerPage = itemsPerPage;
        const startIndex = imagePaginate * imagesPerPage;
        const overallIndex = startIndex + newData.index;
        
        if(newData.remove) {
            if (newData.index !== undefined) {
                removeImage(newData.index);
            }
        }
        setReceivedImgData(prevData => {
            let updatedData = [...prevData.data];

            if (newData.data) {
                newData.data.map(data => {
                    if (data.id !== undefined) {
                        const matchIndex = updatedData.findIndex(item => item.id === data.id);
                        if (matchIndex !== -1) {
                            updatedData[matchIndex] = {
                                ...updatedData[matchIndex],
                                label: newData.imgLabel,
                                displayOrder : newData.index
                            };
                        }
                    }

                    // If no match found, add the newData at the overallIndex
                    if (!newData.update && newData.imgLabel && newData.index !== undefined) {
                        if (overallIndex >= 0 && overallIndex < updatedData.length) {
                            updatedData[overallIndex] = {
                                ...updatedData[overallIndex],
                                label: newData.imgLabel,
                                displayOrder : newData.index
                            };
                        }
                    }

                    if (newData.update || newData.index === undefined) {
                        if (overallIndex >= 0 && overallIndex <= updatedData.length) {
                            updatedData.splice(overallIndex, 0, ...newData.data);
                        } else {
                            updatedData = [...updatedData, ...newData.data];
                        }
                    }
                })
            }

            return {
                ...prevData,
                data: updatedData
            };
        });
    };

    const receiveDocDataFromFileUploadPopUp = (newData) => {
        const documentsPerPage = 3;
        const startIndex = documentPaginate * documentsPerPage;
        const overallIndex = startIndex + newData.index;
        if(newData.remove) {
            if (newData.index !== undefined) {
                removeDocument(newData.index);
            }
        }
        setReceivedDocData(prevData => {
            let updatedData = [...prevData.documentData];

           if (newData.documentData) {
                newData.documentData.map(data => {
                    if (data.id !== undefined) {
                        const matchIndex = updatedData.findIndex(item => item.id === data.id);
                        if (matchIndex !== -1) {
                            updatedData[matchIndex] = {
                                ...updatedData[matchIndex],
                                label: newData.docLabel,
                                displayOrder : newData.index
                            };
                        }
                    }
                    
                    // If no match found, add the newData at the overallIndex
                    if (!newData.update && newData.docLabel && newData.index !== undefined) {
                        if (overallIndex >= 0 && overallIndex < updatedData.length) {
                            updatedData[overallIndex] = {
                                ...updatedData[overallIndex],
                                label: newData.docLabel,
                                displayOrder : newData.index
                            };
                        }
                    }

                    if (newData.update || newData.index === undefined) {
                        if (overallIndex >= 0 && overallIndex <= updatedData.length) {
                            updatedData.splice(overallIndex, 0, ...newData.documentData);
                        } else {
                            updatedData = [...updatedData, ...newData.documentData];
                        }
                    }
                })
            }
            return {
                ...prevData,
                documentData: updatedData
            };
        });
    };
   
    const labelCollection=(data,key)=>{
        return data.map((item,index) => {
            if (item.name) {
                return {
                    [item.name]:{label : item.label, displayOrder :  index},
                };
            }
            else{
                return {
                    [item[key].name]: {label : item.label, displayOrder  : index},
                };
            }
        });
    }
 
    const removeItem = (index, setData, paginate, itemsPerPage, dataKey) => {
        setData(prevData => {
            const startIndex = paginate * itemsPerPage;
            const overallIndex = startIndex + index;
            const removedData = Array.isArray(prevData.removedData) ? prevData.removedData : [];
            const removedItem = prevData[dataKey][overallIndex];
            const updatedData = prevData[dataKey].filter((_, i) => i !== overallIndex);
    
            return {
                ...prevData,
                removedData: [...removedData, removedItem],
                [dataKey]: updatedData
            };
        });
    };
    
    // Usage for removing an image
    const removeImage = (index) => {
        removeItem(index, setReceivedImgData, imagePaginate, itemsPerPage, 'data');
        setRemoveImageConfirmation(false);
    };
    
    // Usage for removing a document
    const removeDocument = (index) => {
        removeItem(index, setReceivedDocData, documentPaginate, 3, 'documentData');
        setRemoveDocumentConfirmation(false);
    };
    
   
    const removeLabelCollection = (data)=>{
        if (data.removedData && data.removedData.length > 0) {
            const flattenedRemovedData = data.removedData.flat();
            return flattenedRemovedData.map(item => {
              
                    return {
                        [item.name]: item.type
                    };
            });
        }
        else{
            return [];
        }
    }
    
    let totalImagePage = Math.ceil(receivedImgData.data.length / itemsPerPage);
    let totalDocumentPage = Math.ceil(receivedDocData.documentData ? receivedDocData.documentData.length / 3 : "");
    function handlePaginate(type, stateToUpdate) {
        if (type === "decrease") {
            stateToUpdate((prevState) => prevState - 1);
        } else {
            stateToUpdate((prevState) => prevState + 1);
        }
    }
    
    const formatDate = (date) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        const dateObj = moment(formattedDate + ".0000000Z", "YYYY-MM-DD HH:mm:ss.0000000Z");
        return moment.utc(dateObj).format("DD MMM, YYYY");
      };
    useEffect(() => {
        if (eventStartDate) {
            setFormattedStartDate(formatDate(calendarStartDate));
        }
        if (eventEndDate) {
            setFormattedEndDate(formatDate(calendarEndDate));
        }
    }, [eventStartDate, eventEndDate,calendarStartDate, calendarEndDate])

const monthChangeStartCalendar = (date) => {
    let tempDate = new Date(date);
    setDateChange(tempDate)
}
const monthChangeEndCalendar = (date) => {
    let tempDate = new Date(date);
    setDateChange(tempDate)
}

function startDateChange(date) {
    let compareDate = localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date()
    if (new Date(compareDate) > date){
        date = new Date(compareDate);
    }
    setCalendarStartDate(date);
    setEventStartDate(date);
    setShowStartCalendar(false);
}
function endDateChange(date) {
    let compareDate = localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date()
    if (new Date(compareDate) > date){
        date = new Date(compareDate);
    }
		setCalendarEndDate(date);
        setEventEndDate(date)
        setShowEndCalendar(false);
	}
    useEffect(()=>{
        const handleClickOutSide = (e) =>{
            if(showStartCalendar && calendarRef.current && !calendarRef.current.contains(e.target)){
                setShowStartCalendar(false)
			}
            if(showEndCalendar && endCalendarRef.current && !endCalendarRef.current.contains(e.target)){
                setShowEndCalendar(false)
			}
		};
		document.addEventListener("click",handleClickOutSide);
        setDateChange()
		return () => {
            document.removeEventListener("click",handleClickOutSide);
		}
	},[showStartCalendar, showEndCalendar])
    
    const getQueryParam = (url, param) => {
        const queryString = url.split('?')[1];
        if (!queryString) {
            return null;
        }
    
        const params = new URLSearchParams(queryString);
        return params.get(param);
    };

    useEffect(() => {
        
        if (props.data.id) {
            setUpdateFlag(true)
            setEventName(props.data.name || "")
            setEventLocation(props.data.location || "")
            setEventDescription(props.data.description || "")
            setQrCodeUrl(props.data.link?props.data.link:"")
            if (props.data.startDate && props.data.endDate) {
                if (props.data.startDate) {
                    setCalendarStartDate(new Date(moment(props.data.propsStartDate).format("MM/DD/YYYY")))
                    const [startDatePart, startTimePart] = props.data.propsStartDate.split('  ');
                    setEventStartTime(startTimePart.split(" ")[0]+" " + startTimePart.split(" ")[1])
                    setFormattedStartDate(startDatePart)
                }
                if (props.data.endDate) {
                    setCalendarEndDate(new Date(moment(props.data.propsEndDate).format("MM/DD/YYYY")))
                    const [endDatePart, endTimePart] = props.data.propsEndDate.split('  ');
                    setEventEndTime(endTimePart.split(" ")[0]+" "+ endTimePart.split(" ")[1])
                    setFormattedEndDate(endDatePart)
                }
            }
            if (props.data.isOpenRegistration === true) {
                setIsOptInNotRegister(false)
                setIsOptInRegister(props.data.isOpenRegistration);
            }

            if (props.data.files.length > 0) {
                const previewImage = [];
                const docPreview = []
                props.data.files.map((data) => {
                    const filename = getQueryParam(data.url, 'fileName');
                    const fileExtension = filename.substring(filename.lastIndexOf('.') + 1);
                    if (data.type === 1) {
                        previewImage.push({ name:filename,filepreview: data.url, label: data.label,type:data.type, id:data.id , displayOrder : data.displayOrder})
                    }
                    if (data.type === 2) {
                        if (fileExtension === "pdf") {
                            docPreview.push({ name:filename,docpreview:data.url,IconComponent: "PdfIcon", label: data.label,type:data.type, id: data.id, displayOrder : data.displayOrder })
                        }
                        else{
                            docPreview.push({ name:filename,docpreview:data.url,IconComponent: "WordDocIcon", label: data.label,type:data.type, id: data.id , displayOrder : data.displayOrder})
                        }
                    }
                })
                setReceivedImgData({ data: previewImage})
                setReceivedDocData({documentData: docPreview})
            }
        }
        setUniqueId(props.data.uniqueId ? props.data.uniqueId : uuidv4())
    }, [])

    const to24HourFormat = (time) => {
        if (time) {
            let regex = new RegExp(/^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i);
            if (regex.test(time) == true) {
                const [hour, minute, period] = time.split(/:| /);
                let hours24 = parseInt(hour, 10);
                if (period.toLowerCase() === 'pm' && hours24 !== 12) {
                    hours24 += 12;
                } else if (period.toLowerCase() === 'am' && hours24 === 12) {
                    hours24 = 0;
                }
                return `${hours24.toString().padStart(2, '0')}:${minute.padStart(2, '0')}`;
            } else {
                return false;
            }
        }
    }; 
    

    const enableOpenReg = async (event) => {
        setIsOptInNotRegister(false);
        setIsOptInRegister(true);

        if (props.data.link) {
            return 
        }
           
        if (uniqueId && localStorage.getItem("facilityId")) {
            const eventDetails = {
                uniqueId: uniqueId,
                facilityId: localStorage.getItem("facilityId"),
            }
            const requestBody = {
                branch_key: key.key,
                data: { eventDetails: eventDetails }
            };
            if (qrCodeUrl === ''){
                try {
                    dispatch(setLoading(true))
                    let response = await API.deepLinkPostAPI(constantURL.fetchEventUrl, requestBody)
                    dispatch(setLoading(false))
                    if (response.fetchStatus === "success"){
                        setQrCodeUrl(response.result.url);
                    } else {
                        let snackData = {
                            showSnack: true,
                            snackMessage: "Error while fetching event url",
                            snackVariant: "error"
                            };
                        dispatch(setSnackData(snackData));
                    }
                } catch (error) {
                    let snackData = {
                        showSnack: true,
                        snackMessage: "Error while fetching event url",
                        snackVariant: "error"
                        };
                    dispatch(setSnackData(snackData));
                }
            } 
        }
    };
   
    const disableOpenReg = (event) => {
        setIsOptInNotRegister(true);
        setIsOptInRegister(false);
    };

    const isValidStartTime = (time) =>{
        if (time) { 
        const current = localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date();
        const now = new Date(current)
        const currentTime = now.getHours() * 60 + now.getMinutes();
            const [givenHours, givenMinutes] = (time).split(':').map(Number);
            const givenTime = givenHours * 60 + givenMinutes;
            return currentTime > givenTime;
        } else {
            return false
        }
    }
    
    const saveEventDetails = async (e) => {
        e.preventDefault()
        setIsEventStartDate(true)
        setIsEventEndDate(true)
        setIsStartTime(true)
        setIsEndTime(true)
        setIsEventName(true)
        setEventErrorMessage("")
        const currentDate = localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date();
        const today = new Date(currentDate)
        let startDate = new Date(formattedStartDate);
        let endDate = new Date(formattedEndDate);
        let startDateInMillis = startDate.getTime();
        let endDateInMillis = endDate.getTime();
       
        let validateEventStartDate = functions.validateInput(formattedStartDate, setEventErrorMessage,"all");
        let validateEventEndDate = functions.validateInput(formattedEndDate, setEventErrorMessage, "all");
        let validateEventStartTime = functions.validateInput(eventStartTime, setEventErrorMessage, "all");
        let validateEventEndTime = functions.validateInput(eventEndTime, setEventErrorMessage, "all");
        let validateEventName = functions.validateInput(eventName, setEventErrorMessage, "all");
        const formattedStartTime = to24HourFormat(eventStartTime);
        if (validateEventStartTime && !formattedStartTime) {
            setIsValidEventData(false);
            return setEventErrorMessage("Invalid Start Time")
        }
        const formattedEndTime = to24HourFormat(eventEndTime);
        if (validateEventEndTime && !formattedEndTime) {
            setIsValidEventData(false);
            return setEventErrorMessage("Invalid End Time")
        }
        const startTime = new Date(`2000-01-01T${formattedStartTime}`);
        const endTime = new Date(`2000-01-01T${formattedEndTime}`);
        // Get timestamps for comparison

        const startTimestamp = startTime.getTime();
        const endTimestamp = endTime.getTime();
        if (!validateEventName) {
            setIsValidEventData(false)
            return setIsEventName(false)
        }

        if (eventName.trim().length > 150) {
            setIsValidEventData(false)
            return setEventErrorMessage("Event name should not exceed 150 characters.")
        }

        if (eventLocation.trim().length > 200) {
            setIsValidEventData(false)   
            return setEventErrorMessage("Event location should not exceed 200 characters.")
        }

        if (eventDescription.trim().length > 350) {
            setIsValidEventData(false)
            return setEventErrorMessage("Event description should not exceed 350 characters.")
        }
        if ((!props.data.id)) {
            if(startDate.setHours(0,0,0,0) == today.setHours(0,0,0,0)) {
                const checkPassedTime = isValidStartTime(formattedStartTime)
                if (checkPassedTime) {
                    setIsValidEventData(false);
                    return setEventErrorMessage("Start time should be greater than current time")
                }
            }
        }

        if (!validateEventStartDate) {
            setIsValidEventData(false)
            return setIsEventStartDate(false)
        }

        if (!validateEventEndDate) {
            setIsValidEventData(false)
            return setIsEventEndDate(false)
        }
        if (!validateEventStartTime) {
            setIsValidEventData(false)
            return setIsStartTime(false)
        }
        if (!validateEventEndTime) {
            setIsValidEventData(false)
            return setIsEndTime(false)
        }
        if (validateEventStartDate && validateEventEndDate) {
            if (startDate > endDate) {
                setIsValidEventData(false)
                return setEventErrorMessage("Start Date should not be greater than End date");
            }
        }


        if (validateEventStartDate && validateEventEndDate) {
            if (startDateInMillis === endDateInMillis) {
                // Compare timestamps
                if ((startTimestamp > endTimestamp)) {
                    setIsValidEventData(false)
                    return setEventErrorMessage("Start time should not be greater than End time");
                }
                else if ((startTimestamp == endTimestamp)) {
                    setIsValidEventData(false)
                    return setEventErrorMessage("Start Time and End Time should not be same");
                }
            }
        }
        
        if (validateEventName && validateEventEndDate && validateEventEndTime&& validateEventStartDate && validateEventStartTime) {
            eventData["facilityId"] = localStorage.getItem("facilityId");
            eventData["name"] =eventName.trim();
            eventData["description"] = eventDescription ? eventDescription.trim() : "";
            eventData["location"] = eventLocation ? eventLocation.trim() : "";
            eventData["link"] = qrCodeUrl? qrCodeUrl:"" ;
            eventData["startDate"] =formattedStartDate + " " + eventStartTime;
            eventData["endDate"] =formattedEndDate + " " + eventEndTime;
            eventData["isOpenRegistration"] = isOptInRegister;
            eventData["uniqueId"] = uniqueId;
            let docLabel = labelCollection(receivedDocData.documentData,"document")
            let imgLabel = labelCollection(receivedImgData.data,"file")
            for (var key in eventData) {
                form_data.append(key, eventData[key])
            }
            if (isOptInRegister === true) {
                try {
                    const file = await qrImgToFile();
                    form_data.append("qrImage", file);
                } catch (error) {
                    const snackData = {
                        showSnack: true,
                        snackMessage: error,
                        snackVariant: "error"
                    };
                    dispatch(setSnackData(snackData));
                }
            }
            receivedImgData.data.map((file) => {
                if (file.file) {
                    form_data.append("images", file.file);
                }
            });
            receivedDocData.documentData.map((file) => {
                if(file.document){
                    form_data.append("documents", file.document);
                }
            });
            let mergeLabel = docLabel.concat(imgLabel)
            let mergeLabelObj = Object.assign({}, ...mergeLabel)
            let labelObj = JSON.stringify(mergeLabelObj)
            form_data.append("fileLabelMapping", labelObj);
            
            dispatch(setLoading(true))
            let response = null
            if (updateFlag) {
                let removedimgData = removeLabelCollection(receivedImgData);
                let removedDocData = removeLabelCollection(receivedDocData);
                let mergeremoved = removedDocData.concat(removedimgData)
                let mergeremovedObj = Object.assign({}, ...mergeremoved)
                let removedObj = JSON.stringify(mergeremovedObj);
                form_data.append("removeFiles", removedObj)
                if (props.data.isOpenRegistration === isOptInRegister) {
                    form_data.delete("link")
                    form_data.delete("isOpenRegistration")
                    form_data.delete("qrImage")
                }
                form_data.append("id", props.data.id)
                form_data.delete("uniqueId")
                response = await API.putwithFileAPI(constantURL.events, form_data);
            } else {
                response = await API.postWithFileAPI(constantURL.events, form_data);
            }
			dispatch(setLoading(false))
			if (response.fetchStatus === "success") {
                if (response.result.status === "success") {
                    props.onCancel()
					let snackData = {
                        showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
				} else {
                    let snackData = {
                        showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
                let snackData = {
                    showSnack: true,
					snackMessage: "Server down.Try again.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
        }
        
    }
    
    const handleSelectedStartTime = (time) => {
        setEventErrorMessage("")
        setEventStartTime(time);
    };
    
    const handleSelectedEndTime = (time) => {
        setEventErrorMessage("")
        setEventEndTime(time);
    };

    const qrImgToFile = async (download) => {
        if (qrCodeUrl) {
            const canvas = document.getElementById('qr-code');
            if (!canvas) {
                throw new Error('Canvas not found');
            }

            const blob = await new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    if (blob) {
                        resolve(blob);
                        if (download === "download") {
                            downloadImage(blob,"QR_code.png")
                        }
                    } else {
                        reject(new Error('Failed to create blob from canvas'));
                    }
                }, 'image/png');
            });

            return new File([blob], 'qrcode.png', { type: 'image/png' });
        }
    };

      
    const copyQRCode = () => {
        const canvas = document.getElementById('qr-code');
            canvas.toBlob((blob) => {
                try {
                    navigator.clipboard.write([
                        new ClipboardItem({
                            [blob.type]: blob
                        })
                    ])
                    setShowCopiedMessage(true)
                    setTimeout(() => {
                        setShowCopiedMessage(false);
                    }, 1000);
                } catch (err) {
                    let snackData = {
                        showSnack: true,
                        snackMessage: "Browser does not have permission to copy images to clipboard.",
                        snackVariant: "error"
                    }
                    dispatch(setSnackData(snackData))
                }
            });
    };

    const copyText = () => {
        const inputField = document.getElementById('registration-url');
        const textToCopy = inputField.value;
    
        try {
            navigator.clipboard.writeText(textToCopy);
            setShowCopiedMessage(true);
            setTimeout(() => {
                setShowCopiedMessage(false);
            }, 1000);
        } catch (error) {
            let snackData = {
                showSnack: true,
                snackMessage: "Browser does not have permission to copy text to clipboard.",
                snackVariant: "error"
            }
            dispatch(setSnackData(snackData))
        }
    };
    
    const handleEditImage = (imageData,index) => {
        setImgUploadPopUp(true);
        setIndex(index)
        setLabel(imageData.label);
        setSelectedFile(imageData); // Set the selected image data for editing
    };
    const handleEditDoc = (imageData,index) => {
        setDocUploadPopUp(true);
        setIndex(index)
        setLabel(imageData.label);
        setSelectedFile(imageData); // Set the selected image data for editing
    };
    
    return(
        <div className='modal' data-testid="add-event">
            <div className='modal-content-container-add-events-ctr' data-testid="add-events-ctr">
                <div className='model-content' data-testid='model-content'>
                <div className='modal-title heading' data-testid="add-event-title">{props.data.id ? "Edit Event" : "Create Event"}</div>

                <div className='form-div'>
                    <div className='add-event-section' data-testid='add-event-section'>
                        <div className= "event-field-ctr" >
                            <label data-testid="event-name-label" className={!isEventName ? " event-title highLight" : "event-title"}>Event Name *</label>
                            <input
                                className={isEventName ? "event-input-field" : "event-input-field warn-msg"}
                                type='text'
                                value={eventName}
                                data-testid="event-name-input"
                                placeholder='Enter Event Name'
                                onChange={(event) => {functions.validateInputChange(event, setEventName, setEventErrorMessage);setIsEventName(true)}}
                                >
                            </input>
                        </div>
                        <div className='event-field-ctr'>
                            <label data-testid="event-location-label" className="event-title">Event Location</label>
                            <input
                                type='text'
                                value={eventLocation}
                                className="event-input-field"
                                data-testid="event-location-input"
                                placeholder='Enter Event Location'
                                onChange={(e) => {functions.validateInputChange(e, setEventLocation, setEventErrorMessage)}}
                                >
                            </input>
                        </div>
                        <div className="instruction" data-testid ="dataTime-topic">Choose the Date and Time of your Event</div>
                        <div className='event-date-ctr'>
                                <div className={!isEventStartDate ? "date-field warn-msg" : "date-field"} >
                                    <input 
                                        onClick={() => {setShowStartCalendar(true);setIsEventStartDate(true);setEventErrorMessage("")}}
                                        className="event-date-input" 
                                        placeholder="Start Date"
                                        value={formattedStartDate}
                                        readOnly
                                        data-testid="start-calender-input"
                                        id="calender-input"
                                    />
                                    <img src={CalenderIcon}  onClick={() => setShowStartCalendar(true)} alt="file-upload" className='calenderIcon' data-testId="start-calendar-icon" />
                                    {showStartCalendar ?
                                        <div className="request-service-date-picker-container eventdate" data-testid="start-date-picker-container"  ref={calendarRef} onClick={(e)=> e.stopPropagation()}>
                                            <Calender
                                                active={calendarStartDate || new Date()}
                                                change = {dateChange}
                                                highlightDates={[]}
                                                monthChange={(data) => monthChangeStartCalendar(data)}
                                                onChange={(data) => startDateChange(data)}
                                                nextDate={nextDate}
                                                currentDate={localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date()}
                                            />
                                        </div>
                                        : ""}
                                </div>
                            <div className={!isEventEndDate ? "date-field end-date warn-msg" : "date-field end-date" } >
                                    <input 
                                        onClick={() => {setShowEndCalendar(true);setIsEventEndDate(true);setEventErrorMessage("")}}
                                        className= "event-date-input" 
                                        value={formattedEndDate}
                                        placeholder='End Date'
                                        readOnly
                                        data-testid="end-calender-input"
                                        id="calender-input"
                                    />
                                    <img src={CalenderIcon}  onClick={() => setShowEndCalendar(true)} alt="file-upload" className='calenderIcon' data-testId="end-calendar-icon" />
                                    {showEndCalendar ?
                                        <div id = "end-date" className="request-service-date-picker-container eventdate flip" data-testid="end-date-picker-container"  ref={endCalendarRef} onClick={(e)=> e.stopPropagation()}>
                                            <Calender
                                                active={calendarEndDate || new Date()}
                                                change = {dateChange}
                                                highlightDates={[]}
                                                monthChange={(data) => monthChangeEndCalendar(data)}
                                                onChange={(data) => endDateChange(data)}
                                                nextDate={nextDate}
                                                currentDate={localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date()}
                                                />
                                        </div>
                                        : ""}
                            </div>
                        </div>
                            <div className='event-time-pickers'>
                                <div className='start-time-picker' data-testid="time-picker" >
                                    
                                    <TimePicker
                                        selectedTime={eventStartTime}
                                        placeholder={"Start time"}
                                        onSelectTime={handleSelectedStartTime}
                                        error={isStartTime ? true:false}
                                    />
                                </div>
                                <div
                                    className='end-time-picker'
                                >
                                    <TimePicker
                                        selectedTime={eventEndTime}
                                        placeholder={"End time"}
                                        onSelectTime={handleSelectedEndTime}
                                        error={isEndTime ? true:false}
                                    />
                                </div>
                            </div>
                        <div className="event-field-ctr">
                            <label data-testid="event-description-title" className= "event-title">Description</label>
                            <textarea 
                                className='event-input-field description'
                                type='text' 
                                value={eventDescription}
                                data-testid="event-description-input"
                                placeholder='Enter Event Description'
                                onChange={(e) => {functions.validateInputChange(e, setEventDescription, setEventErrorMessage)}}
                                >                                
                            </textarea>
                            <small data-testid="limit-error" className='limit-msg'>350 max</small>
                        </div>
                        <div className='event-title' data-testid="event-attendees-title">Attendee Settings</div>
                        <div className="instruction" data-testid="event-reg-instruction">Choose if this Event requires registration</div>
                        <div className='event-title' data-testid="registration-part">Allow Open Registration</div>
                        <div className='event-registration-ctr' data-testid="event-registration-sub-title">
                            <div className='event-register-radio-ctr' data-testid='open-reg' onClick={enableOpenReg}>
                                <input
                                        type="radio"
                                        data-testid={'enable-openReg'}
                                        id="register-input"
                                        checked={isOptInRegister}
                                />
                                <label
                                    data-testid={"role-radio-input-label"}
                                    id="role-radio-input"
                                    className='event-register-radio'
                                >
                                    Yes
                                </label>
                            </div>
                                <div className='event-register-radio-ctr' onClick={disableOpenReg}>
                                    <input
                                        type="radio"
                                        data-testid={'disable-openReg'}
                                        id="register-input"
                                        checked={isOptInNotRegister}
                                    />
                                    <label
                                        data-testid={"role-radio-input-label"}
                                        id="role-radio-input"
                                        className='event-register-radio'
                                    >
                                        No
                                    </label>
                                </div>
                        </div>
                        {isOptInRegister ? 
                            <div>
                                <div className='event-title' data-testid="event-qr-url-title">URL and QR Codes</div>
                                <div className='event-url-ctr'>
                                    <label data-testid="event-url-label" className='url-label'>Link</label>
                                    <input
                                        id='registration-url'
                                        className='event-input-field qr-url'
                                        value={qrCodeUrl}
                                        type='text'
                                        data-testid="event-url-input"
                                        readOnly
                                    >
                                    </input>
                                    <img src={CopyTextIcon} alt="file-upload"  onClick={()=>copyText()} className='copy-icon' data-testid="url-copy" />
                                </div>
                                <div className='qr-div'>
                                <label data-testid="event-qr-label" className='url-label'>QR Code</label>
                                {qrCodeUrl?
                                        <QRCodeCanvas  size={480} id="qr-code" className="qr-code" data-testid= "qr-canvas" value={qrCodeUrl} ref={QR_code} renderAs="canvas" includeMargin={true}/>
                                    :""}   
                                        <div className='img-column'>
                                            <img src={CopyTextIcon} alt="file-upload" className='copy-icon qr' data-testid= "qr-image-copy" onClick={()=>copyQRCode()} />
                                            <img src={DownloadIcon} alt="file-upload" className='copy-icon qr download' data-testid= "qr-image-download" onClick={() => qrImgToFile("download")} />
                                        </div>
                                        {showCopiedMessage && (
                                            <div className="copy-message" data-testId="copied-msg">
                                                copied!
                                            </div>
                                        )} 
                                </div>
                            </div>
                        :""}
                        </div>
                        <div className='galleryDiv'>
                            <div className='imageContainer'>
                                <div className='event-title' data-testId="image-gallery-title">Image Gallery</div>
                                <div className='image-content-container'  data-testid= "image-container">
                                    <div className="add-files-icon">
                                        <input  id="add-alarm-icon" data-testid="chooseFileBtn-img" onClick={() => { setImgUploadPopUp(true);setSelectedFile(null) }} className="inputfile" />
                                        <label htmlFor="add-alarm-icon">
                                            <img htmlFor="add-alarm-icon" className="add-files-icon" src={AddImageIcon} alt="Add icon" ></img>
                                        </label>
                                        {
                                            imgUploadPopUp && <FileUploadPopUp  onClose={() => { setImgUploadPopUp(false) }} type={"image"} sendDataToParent={receiveImgDataFromFileUploadPopUp}  initialData={selectedfile} index={index} label={label}/>
                                        }
                                    </div>
                                    {receivedImgData.data &&
                                       receivedImgData.data.length > 0 ? 
                                       receivedImgData.data.slice(imagePaginate * itemsPerPage, parseInt(imagePaginate * itemsPerPage) + parseInt(itemsPerPage))
                                       .map((row, index) => {
                                                    return (
                                                        <div className='preview' data-testid="image-preview"
                                                            style={{ position: 'relative' }}
                                                        >
                                                            <img className="previewimgGallery" src={row.filepreview} alt="UploadImage" data-testid="preview-img"/>
                                                            <p data-testid="img-label" title={row.label}>{row.label}</p>
                                                            <div className='gallery-icon-div'>
                                                                <span className='icon-container' >
                                                                    <FaRegEdit onClick={() => handleEditImage(row,index)} data-testid="img-edit-icon"/>
                                                                </span>
                                                                <span className='deleteImageIcon' >
                                                                    <img src={DeleteIcon} onClick={() => {setDeleteData(row.label);setRemoveImageConfirmation(true);setRemovedImage(index)}} data-testid="img-delete-icon" alt="Delete icon"></img>
                                                                </span>
                                                              
                                                            </div>
                                                          
                                                        </div>
                                                        )
                                                        
                                                })
                                            :
                                            ""
                                    }
                                </div>
                                    {removeImageConfirmation ?
                                        <Confirmation className = "modal-indexed"deleteMessage={"Are you sure you want to delete Image "+deleteData+"?"} successFunc={() => removeImage(removedImage)} cancelFunc={() => setRemoveImageConfirmation(false)} />
                                        : ""}
                                    {receivedImgData.data.length > 0 &&
                                        <div data-testid="table-options-container" id="table-options-container" className="table-options-container middle">
                                            <div className="table-page-entry-container">
                                                <div className="table-paginate-container">
                                                    <span>Image Page</span>
                                                    <div className="table-paginate-inner-container">
                                                        <img src={ArrowIcon} alt="previous" className="table-paginate-next-prev table-paginate-prev" data-testid="table-paginate-previous" id="table-paginate-previous" onClick={imagePaginate !== 0 ? () => { handlePaginate("decrease", setImagePaginate) } : null}></img>
                                                        <span className="table-paginate-page" title={imagePaginate + 1} data-testid="current-page">{imagePaginate + 1}</span>
                                                        <img src={ArrowIcon} alt="next" className="table-paginate-next-prev table-paginate-next" data-testid="table-paginate-next" id="table-paginate-next" onClick={(imagePaginate + 1) < totalImagePage ? () => { handlePaginate("increase", setImagePaginate) } : null}></img>
                                                    </div>
                                                    <span data-testid="table-page-total-count" id="table-page-total-count">Of {totalImagePage || 1}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                            </div>
                            <div className='docsContainer'>
                                <div className='event-title' data-testId="doc-gallery-title">Documents</div>
                                <div className='document-content-container'>
                                        <div className="add-files-icon">
                                            <input id="add-document-icon" data-testid="chooseFileBtn-doc" className="inputfile" onClick={() => { setDocUploadPopUp(true);setSelectedFile(null) }} />
                                            <label htmlFor="add-document-icon">
                                                <img htmlFor="add-document-icon" className="add-files-icon" src={AddDocumentIcon} alt="Add icon" ></img>
                                            </label>
                                            {
                                                docUploadPopUp && <FileUploadPopUp onClose={() => { setDocUploadPopUp(false) }} type={"document"} popUpTitle={"Add Document"} popUpSubTitle={"Choose only PDF files."} initialData={selectedfile}  sendDataToParent={receiveDocDataFromFileUploadPopUp} index={index}  />
                                            }
                                        </div>
                                    {receivedDocData.documentData &&
                                        receivedDocData.documentData.length > 0 ?
                                        receivedDocData.documentData.slice(documentPaginate * 3, parseInt(documentPaginate * 3) + parseInt(3))
                                                .map((row, index) => {
                                                    return (
                                                        <div className='preview' data-testid="doc-preview"
                                                        style={{ position: 'relative' }}
                                                        >
                                                        <img className={row.IconComponent === "WordDocIcon"?"previewimgGallery word":"previewimgGallery pdf"} src={row.IconComponent === "WordDocIcon" ? WordDocIcon : PdfIcon} alt="UploadDoc" data-testid="preview-doc"  />
                                                        <p data-testid="doc-label" title={row.label}>{row.label}</p>
                                                        <div  className='gallery-icon-div dark'>
                                                           
                                                            <span className='icon-container'>
                                                                <FaRegEdit onClick={() => handleEditDoc(row,index)} data-testid="doc-edit-icon"/>
                                                            </span>
                                                            
                                                            <span className='deleteImageIcon'>
                                                            <img src={DarkDeleteIcon} onClick={() => {setDeleteData(row.label);setRemoveDocumentConfirmation(true);setRemovedDocument(index)}} data-testid="doc-delete-icon" alt="Delete icon"></img>
                                                            </span>
                                                        </div>
                                                    </div>)
                                                })
                                            :
                                            ""
                                    }
                                </div>
                                    {removeDocumentConfirmation ?
                                        <Confirmation className = "modal-indexed"deleteMessage={"Are you sure you want to delete Document "+deleteData+"?"} successFunc={() => removeDocument(removedDocument)} cancelFunc={() => setRemoveDocumentConfirmation(false)} />
                                        : ""}
                                    {receivedDocData.documentData.length > 0 &&
                                        <div data-testid="table-options-container" id="table-options-container" className="table-options-container middle">
                                            <div className="table-page-entry-container">
                                                <div className="table-paginate-container">
                                                    <span data-testId="doc-paginate">Document Page</span>
                                                    <div className="table-paginate-inner-container">
                                                        <img src={ArrowIcon} alt="previous" className="table-paginate-next-prev table-paginate-prev" data-testid="table-paginate-previous" id="table-paginate-previous" onClick={documentPaginate !== 0 ? () => { handlePaginate("decrease", setDocumentPaginate) } : null}></img>
                                                        <span className="table-paginate-page" data-testId="current-page">{documentPaginate + 1}</span>
                                                        <img src={ArrowIcon} alt="next" className="table-paginate-next-prev table-paginate-next" data-testid="table-paginate-next" id="table-paginate-next" onClick={(documentPaginate + 1) < totalDocumentPage ? () => { handlePaginate("increase", setDocumentPaginate) } : null}></img>
                                                    </div>
                                                    <span data-testid="table-page-total-count" id="table-page-total-count">Of {totalDocumentPage || 1}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className='manage-events-btn'>
                        <div data-testid="event-error-messsage" className="error-msg" >
                            {!isValidEventData ? eventErrorMessage : ""}
                        </div>
                        <div className='manage-device-btn-ctr'>
                            <button className="cancel-button" data-testid="manage-event-cancel-btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onCancel()
                                }}>Cancel</button>
                            <div class="vertical_line" ></div>
                            <button className="save-button" data-testid="manage-event-save-btn" onClick={saveEventDetails} >Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )

}
export default AddEvents
