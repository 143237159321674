import actiontypes from "../constant/action-types";
const initialState={
	devices:[],
	deviceSearchData : ""

};
export const deviceReducer = (state = initialState, { type, payload})=>{
	switch (type){
		case actiontypes.SET_DEVICES:
			return { ...state, devices: payload};
		case actiontypes.SET_DEVICES_SEARCH:
			return {...state, deviceSearchData:payload}
		default :return state;
        }
}
