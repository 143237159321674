import React, { useState, useEffect } from "react";
import InputField from "../../common/component/InputField";
import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL"
import validator from "validator";
import "../Style/login.css";
import { login, setUserPermissions } from '../../redux/action/userAction';
import { setFacilities } from '../../redux/action/accountAction';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"

function SignInPage(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	var [loginForm, setLoginForm] = useState({
		email:localStorage.getItem('emailId')?localStorage.getItem('emailId'): "",
		password: localStorage.getItem('password')?localStorage.getItem('password'):"",
	});
	var [ErrorMessage, setErrorMessage] = useState("");
	var [ErrorEmail, setErrorEmail] = useState(false);
	var [ErrorPassword, setErrorPassword] = useState(false);
	var [showPassword, setShowPassword] = useState(false);
	const [rememberMe,setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true'?true:false);
	useEffect(()=>{
		if (localStorage.loginUserId !== undefined) {
			navigate("/home/dashboard")
		}
	},[])

	const loginFormOnchangeHandler = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		const updatedForm = {
			...loginForm,
			[name]: value,
		};
		setLoginForm(updatedForm);
	};
	const loginFormButtonHandler = async(event) => {
		event.preventDefault();
		if(loginForm.email.length <= 0){
			setErrorMessage("Please enter a Email")
			return setErrorEmail(true)
		}
		if (validator.isEmail(loginForm.email) === false) {
			setErrorMessage("Enter a valid Email");
			return setErrorEmail(true);
		}
		if (loginForm.password.length === 0) {
			setErrorMessage("Please enter a password");
			return setErrorPassword(true);
		}
		if (ErrorEmail === false && ErrorPassword === false) {
			let body = {
				userName: loginForm.email,
				password: loginForm.password,
				type:"desktop"
			};
			//localStorage.setItem("emailId", body.username);
			let result = await API.postAPI(URL.login,body)
			if(result.fetchStatus === "failure"){
				setErrorMessage("Server down.Failed to fetch");
				return setErrorPassword(true);
			}else{
				if(result.result.status === "failure"){   
					setErrorMessage(result.result.message);
					return setErrorPassword(true);
				}
				let adminDetail =  result.result.result
				dispatch(login(adminDetail))
				let permissions = [];
				if (adminDetail.permissions && adminDetail.permissions.length > 0) {
					for (let index = 0; index < adminDetail.permissions.length; index++) {
						const element = adminDetail.permissions[index];
						permissions.push(element.refName)
					}
				}
				dispatch(setUserPermissions(permissions))
				localStorage.setItem("loginUserId",adminDetail.id)
				localStorage.setItem("emailId", adminDetail.userName);
				localStorage.setItem("firstName",adminDetail.firstName);
				localStorage.setItem("lastName",adminDetail.lastName);
				localStorage.setItem("accessToken", adminDetail.accessToken);
				localStorage.setItem("refreshToken",adminDetail.refreshToken);
				localStorage.setItem("profilePicture", adminDetail.profilePicture);
				localStorage.setItem("functionalRoleId", adminDetail.functionalRoleId);  
				localStorage.setItem("password",loginForm.password )
				localStorage.setItem("rememberMe",rememberMe)
				if (parseInt(adminDetail.functionalRoleId) <= 2) {
					navigate("/home/dashboard");     
				} else if (parseInt(adminDetail.functionalRoleId) < 5) {
					let response = await API.getAPI(URL.getFacilitybyAccount + "?userId=" + adminDetail.id)
					let facilityData = response.result.result
					if (facilityData === null || facilityData === undefined || facilityData.length <= 0) {
						setErrorMessage("No facility Available. Contact Admin");
						localStorage.clear();
						return setErrorPassword(true);
					}else{
						dispatch(setFacilities(facilityData))
						localStorage.setItem("facilityId", facilityData[0].id);
						localStorage.setItem("siteLogo", facilityData[0].siteLogo);
						localStorage.setItem("siteName", facilityData[0].siteName);
						localStorage.setItem("timeZone", facilityData[0].timezone);
						navigate("/home/dashboard");
					}
				} else{
					let response = await API.getAPI(URL.getFacilityById + "?id=" + adminDetail.facilityId)
					if(response.fetchStatus === "failure"){
						setErrorMessage("Server down.Failed to fetch");
						localStorage.clear()
						return setErrorPassword(true);
					}else{
						if(response.result.status === "success"){ 
							let facilityData = response.result.result
							if ( response.result.status === "failure") {
								localStorage.clear();
								setErrorMessage("No facility Available. Contact Admin");
								return setErrorPassword(true);
							}else {
								localStorage.setItem("facilityId", adminDetail.facilityId);
								localStorage.setItem("siteLogo", facilityData.siteLogo);
								localStorage.setItem("siteName", facilityData.siteName);
								localStorage.setItem("timeZone", facilityData.timezone);
								navigate("/home/dashboard");
							}    
						}else{
							setErrorMessage(response.result.message);
							localStorage.clear()
							return setErrorPassword(true);
						}  
					}
				}   
			}
		} 
	};
	return (
		<React.Fragment>
			<div className="login-section" data-testid="login-section">  
				<form onSubmit={loginFormButtonHandler} className="login-conatiner">
					<h1 data-testid="login-form-title">Sign in</h1>
					<InputField
						label="Email"
						type="email"
						autoFocus={true}
						name="email"
						value={loginForm.email}
						dataTestid="email-field"
						onChange={(event) => {
							setErrorEmail(false);
							setErrorPassword(false);
							loginFormOnchangeHandler(event);
						}}
					/>
					<span data-testid="email-error-field">
						{ErrorEmail && ErrorMessage}
					</span>
					<InputField
						label="Password"
						type={showPassword ? "text" : "password"}
						name="password"
						dataTestid="password-field"
						value={loginForm.password}
						onChange={(event) => {
							setErrorPassword(false);
							loginFormOnchangeHandler(event);
						}}
					/>
				   
					<span data-testid="password-error-field">
						{ErrorPassword  && ErrorMessage}
					</span>
					{/* <div className="check-box-container">
						<input type="checkbox" />
						<span className="checkmark-logo"><AiOutlineCheck/></span>
						<label>Show Password</label>
					</div>
				   
				   */}
					<div className="show-password">
						<input type="checkbox"  
							checked = {showPassword} 
							data-testid="show-password-checkbox"
							onChange={()=>{
								setShowPassword(!showPassword)
							}}
						/>
						<p  className="show-password-text" 
							onClick={()=>{
								setShowPassword(!showPassword)
							}}
							data-testid="show-password-checkbox-text"
						>
							Show Password
						</p> 
					</div>
					<div className="forgot-password-link"><Link to="/user/forgot-password" data-testid="login-forgot-password-link">Forgot your password?</Link></div>
					<div className="remember-me-container">
						<p>Remember Me </p>
						<input type="checkbox" checked={rememberMe} data-testid="remember-me-check-box" className="primary-checkbox" onChange={()=>setRememberMe(!rememberMe)}/>
					</div>
					<div className="list-page-title-div">
						<button

						onClick={loginFormButtonHandler}
						className="signIn-button"
						>SIGN IN</button>
					</div>
					
					</form>
				</div>
			
			
		</React.Fragment>
	);
}

export default SignInPage;
