import { useRef, useEffect, useState } from 'react';
import '../style/SelectBox.css';
import dropdown_arrow from '../../assets/svg/dropdown_arrow.svg';
import {FaRegEdit} from 'react-icons/fa'
import {RiDeleteBinLine} from 'react-icons/ri';
import AddNewIcon from '../../../src/HomeLayout/Component/Application/AddNewIcon';
import Confirmation from "../../common/component/Confirmation";
import { setSnackData, setAlertIcon,setLoading} from '../../redux/action/userAction';
import {useDispatch} from 'react-redux';
import * as API from '../../common/api/index';
import URL from "../../common/api/constantURL";


function SelectBox(props) {
	const [isEditIconPopUp,setIsEditIconPopUp] = useState(false);
	const [editIconData, setEditIconData] = useState({});
	const [deleteConfirtmationPopUp,setDeleteConfirtmationPopUp] = useState(false);
	const [confirmationRemoveIconAllocation, setConfirmationRemoveIconAllocation] = useState(false)
	const [deleteData ,setDeleteData]= useState({
		id:"",
		filename:"",
		name:""
	});
	const dispatch = useDispatch()
	const ref = useRef();

	useEffect(() => {
		
		const handleClickOutSide = (e) => {
			if (props.trigger && ref.current && !ref.current.contains(e.target)) {
				props.setTrigger(false)
			}
		};
		document.addEventListener("click", handleClickOutSide);
		return () => {
			document.removeEventListener("click", handleClickOutSide);
		}
	}, [props])


	

	const showEditAction = (data) =>{
		setEditIconData(data)
		setIsEditIconPopUp(true)
	}

	const fetchIcon = async () => {
        let url = URL.iconByFacility + "?facilityId=" + localStorage.getItem("facilityId");
        if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
            url = URL.icon
        }
        let response = await API.getAPI(url)
        if (response.fetchStatus === "success") {
            dispatch(setAlertIcon(response.result))
        } else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
        }
    }
	
	const deleteIcon = async()=>{
		dispatch(setLoading(true))
		let response = await API.deleteAPI(URL.icon, deleteData)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				fetchIcon();
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
			}else if (response.result.status === "failure") {
				setConfirmationRemoveIconAllocation(true)
				setDeleteData({facilityId: deleteData.facilityId,id:deleteData.id,filename: deleteData.filename,name:deleteData.name,isDefault: deleteData.isDefault, deleteAllocation:"true"})
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to delete. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	return (
		<>	
		<div className="dropdown" data-testid={props.dataTestid}>
			<div className={!props.valid ? "dropdown-btn validDropdown" : "dropdown-btn inValidDropdown"}
				onClick={() => props.setTrigger(!props.trigger)} data-testid="custom-select" id="custom-select">
				<span>
					{props.selectItem ?
						props.selectItem.hexValue ?
							<div className="selected-item">
								{/* <img src={props.selectItem.hexValue}  className="circle" style={{backgroundColor:props.selectItem.hexValue}} /> */}
								<div style={{ backgroundColor: props.selectItem.hexValue }} className="selected-color-box"></div>
								<span title={props.selectItem.name} className="inner-padding" data-testid="selected-color" id="selected-color">
									{props.selectItem.name}
								</span>
							</div>
							:
							<div className="selected-item">

								<img src={props.selectItem.url} className="select-box-icon" alt="Icon" />
								<span title={props.selectItem.name} className="inner-padding" data-testid="selected-icon" id="selected-icon">
									{props.selectItem.name}
								</span>
							</div>
						:
						<span className={props.valid ? 'invalidSelectItem' : null}>Select</span>
					}
				</span>
				<span className="dropdown-arrow">
					<img src={dropdown_arrow} className={!props.trigger ? "drop-down-arrow" : "drop-down-arrow-rotation"} alt="" />
				</span>
			</div>
			{props.trigger ?
				<div className="dropdown-content" ref={ref} data-testid="options">
						{props.type === 'Icon'
							?
							<span className="dropdown-item" style={{justifyContent:"center",fontSize:"13px"}}onClick={(e) => {
								props.setTrigger(false)
								props.selectedItem(null)
							}}>
								Select
							</span>
							:
							""
						}
					{props.option ? props.option.map(((d, index) =>
						<div className="dropdown-item" 
						data-testid="select" id="custom-select-drop-down-item"
						>
							<div className="options-list" key={index}
								onClick={(e) => {
									props.selectedItem(d)
									props.setTrigger(false)
									props.setValid(false)
								}}
								>
							{props.type === 'Icon' ?
								<img src={d.url} className="select-box-icon" alt="Icon"/>
								:
								props.type === 'Color' ?
									<span className="circle" style={{ backgroundColor: d.hexValue }}></span>
									:
									null
							}
							<span title={d.name} className="select-box-value" data-testid="select-box-value" id="select-box-value">
								{d.name}
							</span>
							</div>
							<div>
							{props.type === 'Icon' ?
							<div className='manage-icons-ctr'>
							<span>
							<FaRegEdit className='data-update-delete-icon' id="edit-action-icon" data-testid="edit-icon" onClick={()=>{showEditAction(d)}} />
							</span>
							<span >
							<RiDeleteBinLine className='data-update-delete-icon' data-testid="delete-icon" id="delete-icon" onClick={()=>{setDeleteData({facilityId: d.facilityId,id:d.id,filename: d.fileName,name:d.name,isDefault: d.isDefault});setDeleteConfirtmationPopUp(true)}}/>
							</span>
							</div>
							: ""}
							</div>
						</div>
					)) : null}
				</div>
				:
				null
			}
		</div>
			{confirmationRemoveIconAllocation &&
				<Confirmation className="modal-indexed"  buttonValue="Proceed" deleteMessage={"Icon is currently used in existing alarm buttons, are you sure you want to delete?"} successFunc={() => {  setConfirmationRemoveIconAllocation(false);setDeleteConfirtmationPopUp(false);deleteIcon()}} cancelFunc={() => setConfirmationRemoveIconAllocation(false)} dataTestid="delete-allocation" />
			}
			{deleteConfirtmationPopUp ?
				<Confirmation className="modal-indexed" deleteMessage={"Are you sure you want to delete " + deleteData.name + "?"} successFunc={() => {setDeleteConfirtmationPopUp(false);deleteIcon()}} cancelFunc={() => setDeleteConfirtmationPopUp(false)} />
				: ""}
			{isEditIconPopUp ? <AddNewIcon trigger={isEditIconPopUp} 
			data = {editIconData}
			setTrigger={() => {
			fetchIcon()
			setIsEditIconPopUp(false)
		}} /> : null}
		</>

	)
}
export default SelectBox;