import { useEffect, useState } from "react"
import "./DeviceDetailPopUp.css"
import fileDownload from "../../../assets/svg/file-arrow-down-light.svg"
import apple from "../../../assets/svg/apple.svg";
import android from "../../../assets/svg/android.svg"
import { useDispatch } from 'react-redux';
import {defaultPreference ,defaultSeparator} from '../../../common/variables/preference';
import { formatUserFullName } from '../../../common/functions/userNameFormat';
import * as API from '../../../common/api/index.js';
import { setSnackData, setLoading} from "../../../redux/action/userAction"
import URL from "../../../common/api/constantURL";
function DeviceDetailPopup(props){
    const [propsData,setPropsData] = useState({})
    const [modelLogo,setModelLogo] = useState()
    const dispatch = useDispatch();
    useEffect(()=>{
        if(props.data.deviceDetails){
            setPropsData(props.data.deviceDetails)
            if(props.data.deviceDetails.deviceOSType){
                if((props.data.deviceDetails.deviceOSType).toLowerCase() === "ios")
                    setModelLogo(apple)
                else
                    setModelLogo(android)
            }
        }
    },[props])

    const handleFetchDetails = () =>{
        if(props.data.isRegistered){
            fetchDetails('info')
        }
    }
    const requestFetchLogs = () =>{
        if(props.data.isRegistered){
            fetchDetails('log')
        }
    }

    const fetchDetails = async (event) => {
        dispatch(setLoading(true))
        let loginStatusId = props.data.deviceDetails ? props.data.deviceDetails.id : ""
        let url = URL.fetchDeviceDetails + "?id="+props.data.id +"&facilityId=" + localStorage.getItem("facilityId") + "&event="+event +"&loginStatusId="+loginStatusId;
		let response = await API.getAPI(url)
		dispatch(setLoading(false))   
        props.onCancel()
        if(response.fetchStatus === "success"){
			let snackData = {
				showSnack:true,
				snackMessage:response.result.message,
				snackVariant:"success"
			}
			dispatch(setSnackData(snackData))
		}else {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}

    }
    return (
		<div className="modal" data-testid="add-device-popup">
			<div className="modal-content-container manage-device-detail-modal">
                <div className="modal-content">
                    <div className="title-div" data-testid="deviceDetailsTitle">Device Details</div>
                    <div className="header-ctr">
                        <div className="col1">
                            <div className="id-div">Device ID:</div>
                            <div className="overFlow" title={props.data.deviceId} data-testid="deviceId">{props.data.deviceId}</div>
                        </div>
                        <div className="col2">
                            <div className="name-div">Device Name:</div>
                            <div className="overFlow name" title={props.data.deviceName} data-testid="deviceName">{props.data.deviceName}</div>
                        </div>
                        <div className="col3">
                            <div className="type-div">Device Type:</div>
                            <div>{propsData.deviceOSType && <img className={(props.data.deviceDetails.deviceOSType).toLowerCase() === "ios" ? 'device-model-icon-apple' : "device-model-icon-android"} src={modelLogo} alt="apple" />}</div>
                            <div className="overFlow" data-testid="deviceType" title={propsData.deviceModel ? propsData.deviceModel:"N/A"}>{propsData.deviceModel ? propsData.deviceModel:"N/A"}</div>
                        </div>
                        <div className="col4">
                            <div className="version-div">Device OS Version:</div>
                            <div className="overFlow" data-testid="osVersion"title={propsData.deviceOSVersion ? propsData.deviceOSVersion :"N/A"}>{propsData.deviceOSVersion ? propsData.deviceOSVersion :"N/A"}</div>
                        </div>
                        <div className="col5">
                            <div className="battery-div">Battery Level:</div>
                            <div className="overFlow battery" data-testid="batterylevel"title={propsData.batteryLevel ? propsData.batteryLevel :"N/A"}>{propsData.batteryLevel ? propsData.batteryLevel+"%" :"N/A"}</div>
                        </div>
                    </div>
                    <div className="user-and-device">
                        <div className="user-div">
                            <div className="column">
                                <div className="label">Current User:</div>
                                <div data-testid="currentUserName">{(propsData.currentUserFirstName || propsData.currentUserLastName) ?  formatUserFullName(propsData.currentUserFirstName,propsData.currentUserLastName, defaultPreference , defaultSeparator)  : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Current User Login Time:</div>
                                <div data-testid="currentUserLoginTime">{propsData.currentUserLoginTime ? propsData.currentUserLoginTime : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Last Logged In User Name:</div>
                                <div data-testid="lastUserName">{(propsData.lastUserFirstName || propsData.lastUserLastName) ?  formatUserFullName(propsData.lastUserFirstName,propsData.lastUserLastName, defaultPreference , defaultSeparator) : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Last Logged In User Login Time:</div>
                                <div data-testid="lastUserLoginTime">{propsData.lastUserLoginTime ? propsData.lastUserLoginTime : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Last Logged In User Logout Time:</div>
                                <div data-testid="lastUserLogOutTime">{propsData.lastUserLogoutTime ? propsData.lastUserLogoutTime : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Last Logged In User Logout Type:</div>
                                <div data-testid="lastUserLogoutType">{propsData.lastUserLogoutType ? propsData.lastUserLogoutType : "N/A"}</div>
                            </div>
                        </div>
                        <div className="device-div">
                            <div className="column">
                                <div className="label">Device IP address:</div>
                                <div data-testid="deviceIp">{propsData.deviceIP ? propsData.deviceIP : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Client App Version:</div>
                                <div data-testid="appVersion">{propsData.appVersion ? propsData.appVersion : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Device Status:</div>
                                <div data-testid="isRegistered">{props.data.isRegistered ? "Attached" : "Detached"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Device Last Request Date & Time:</div>
                                <div data-testid="deviceLastRequestDate">{propsData.deviceLastRequestDate ? propsData.deviceLastRequestDate : "N/A"}</div>
                            </div>

                            <div className="column">
                                <div className="label">Device Date and Time:</div>
                                <div data-testid="deviceDate">{propsData.deviceDate ? propsData.deviceDate : "N/A"}</div>
                            </div>

                        </div>

                    </div>

                    <div className="communication-status">
                        <div className="column">
                            <div className="label">Device Communication Status:</div>
                            <div className="rowDiv">
                            <span data-testid="deviceConnectionStatusColor" className={propsData.deviceConnectionStatus ? ((propsData.deviceConnectionStatus).toLowerCase() === "foreground" ||  (propsData.deviceConnectionStatus).toLowerCase() === "background" ? "green dot" : "dot") : "dot"}></span>
                            <div data-testid="deviceConnectionStatus">{propsData.deviceConnectionStatus ? propsData.deviceConnectionStatus : "N/A"}</div>
                            </div>
                        </div>

                        <div className="column">
                            <div className="label">MQTT Communication Status:</div>
                            <div className="rowDiv">
                            <span className={propsData.mqttConnectionStatus ? ( (propsData.mqttConnectionStatus.toLowerCase() === "connected") ? "green dot" : "dot") : "dot"} ></span>
                            <div data-testid="mqttConnectionStatus">{propsData.mqttConnectionStatus ? propsData.mqttConnectionStatus : "N/A"}</div>
                            </div>
                        </div>

                        {/* <div className="column">
                            <div className="label">Device SIP Status:</div>
                            <div className="rowDiv">
                            <span className="dot"></span>    
                            <div>{propsData.SIPConnectionStatus ? propsData.SIPConnectionStatus : "N/A"}</div>
                            </div>
                        </div> */}

                        <div className="column">
                            <div className="label">SARA Registration Status:</div>
                            <div className="rowDiv">
                            <span data-testid="saraRegistrationStatusColor" className={props.data.saraRegistrationStatus ? "green dot" : "dot"}></span>
                            <div data-testid="saraRegistrationStatus">{props.data.saraRegistrationStatus ? "Registered" : "Unregistered"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-div">
                        <div className="leftDiv">

                        </div>
                        <div className="left-footer-div">
                            <div onClick={props.onCancel} className="cancel-button" data-testid="closeDetails">Close</div>
                            <div className="vertical_line"></div>
                            <div data-testid="fetchDetails" className={props.data.isRegistered ? "save-button" : "fetch-blocked save-button"} onClick={()=>{handleFetchDetails()}}>Fetch Details</div>
                        </div>
                      
                        <div data-testid="request-logs" className={props.data.isRegistered ? "download-div" : "download-div req-blocked"} onClick={()=>{requestFetchLogs()}}>
                            <img src={fileDownload} alt="fileDownload" className="icon-div"/>
                            <div className="requestLogs">Request Logs</div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	)
}


export default DeviceDetailPopup
