import actiontypes from "../constant/action-types";

const initialState={
    alarmsByFacilityId: [],
};

export const alarmReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actiontypes.SET_ALARMS:
            return { ...state, alarmsByFacilityId: payload };
        default:
            return state;
    }
}