import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import validator from "validator";
import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL"
import InputField from "../../common/component/InputField";
import { isMobile } from 'react-device-detect';
import "../Style/resetPassword.css";

function ResetPasswordPage() {
  localStorage.clear()
	const location = useLocation();
	const [userName, setUserName] = useState();
	const navigate = useNavigate();
	var [userData, setUserData] = useState({
		firstName: "",
		lastName: "",
		id: ""
	})
	const [updationStatusMsg, setUpdationStatusMsg] = useState("")
	const [resetForm, setResetForm] = useState({
		newPassword: "",
		confirmPassword: "",
	});
	var searchToken = location.search;
	var resetToken = searchToken.substring(searchToken.indexOf("?") + 1);
	const fetchResetPassword = async() => {
		var updatedData;
		let data = { "resetPasswordToken": resetToken }
		const response = await API.postAPI(URL.resetPassword, data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				setUserData.firstName = response.result.result.firstName
				updatedData = {
					firstName: response.result.result.firstName,
					lastName: response.result.result.lastName,
					id: response.result.result.id
				};
				setUserName(response.result.result.userName)
				setUserData(updatedData)
			} else {
				navigate("/user/forgot-password")
			}
		}
	}
	useEffect( () => {
		fetchResetPassword()	
	}, [])
         
	const [MatchPasswordError, setMatchPasswordError] = useState(false);
	const [passwordUpdateStatus, setPasswordUpdateStatus] = useState(false);
	const [MatchPasswordErrorMessage, setMatchPasswordErrorMessage] = useState("");
	const resetPasswordFormHandler = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		const updatedForm = {
			...resetForm,
			[name]: value,
		};
		setResetForm(updatedForm);
	};
	const resetPasswordButtonHandler = async (event) => {
		event.preventDefault();
		if (!resetForm.newPassword && !resetForm.confirmPassword) {
			setMatchPasswordErrorMessage("Password should not be null");
			return setMatchPasswordError(true);
		}
		if (resetForm.newPassword !== resetForm.confirmPassword) {
			setMatchPasswordErrorMessage("Password didn't Match");
			return setMatchPasswordError(true);
		}
		if (
			!validator.isStrongPassword(resetForm.newPassword, {
				minLength: 6,
				minLowercase: 1,
				minUppercase: 1,
				minNumbers: 1,
				minSymbols: 1,
			})
		) {
			setMatchPasswordErrorMessage(
				"Password must contain atleast one number,special charater,upper and lower case."
			);
			return setMatchPasswordError(true);
		}
		let data = {
			id: userData.id,
			resetPasswordToken: resetToken,
			password: resetForm.newPassword
		}
		let response = await API.postAPI(URL.changePassword, data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				setResetForm({
					email: "",
					newPassword: "",
					confirmPassword: "",
				});
				setUpdationStatusMsg(response.result.message)
				setTimeout(() => {
					if (isMobile) {
						navigate("/user/resetPassword");
					} else {
						navigate("/user/login");
					}
				}, 2500);
				setPasswordUpdateStatus(true);
				setTimeout(function () {
					if (isMobile) {
						navigate("/user/resetPassword");
					} else {
						navigate("/user/login");
					}
				}, 2500);
				
			} else {
				setUpdationStatusMsg(response.result.message)
				setPasswordUpdateStatus(true)
			}
		}else if(response.fetchStatus === "failure"){
			setUpdationStatusMsg("Server down.Failed to fetch")
			setPasswordUpdateStatus(true)
		}

	};
	return (
		<React.Fragment>
			<div className="reset-password-section">
				{/* <h3>{passwordUpdateStatus && updationStatusMsg}</h3> */}
				<form className="reset-password-container" onSubmit={resetPasswordButtonHandler}>
					<h1 data-testid="reset-password-title">Reset Password</h1>
					<h4 data-testid="reset-instruction-name-ctr">Hello {userData.firstName}</h4>
					<p className="instruction" data-testid="instruction">
						Please fill out the fields below to change your
						password.
					</p>
					<input
						type="email"
						hidden="true"
						name="email"
						value= {userName}
						autoComplete="username"
						readOnly
					/>
					<InputField
						label="Select a new password"
						name="newPassword"
						dataTestid="newPassword-field"
						type="password"
						value={resetForm.newPassword}
						autoComplete="new-password"
						autoFocus={true}
						onChange={(event) => {
							setMatchPasswordError(false);
							setPasswordUpdateStatus(false)
							resetPasswordFormHandler(event);
						}}
					/>
					<InputField
						label="Retype your new password"
						name="confirmPassword"
						dataTestid="confirmPassword-field"
						type="password"
						autoComplete="new-password"
						value={resetForm.confirmPassword}
						onChange={(event) => {
							setMatchPasswordError(false);
							setPasswordUpdateStatus(false)
							resetPasswordFormHandler(event);
						}}
					/>

					<span
						data-testid="pasword-error"
						className= {"message-box " + (MatchPasswordError ? "password-error" : (passwordUpdateStatus&&"message-box"))}
					>
						{MatchPasswordError ? MatchPasswordErrorMessage : passwordUpdateStatus && updationStatusMsg }
					</span>
					{/* <div className="show-password"><input type="checkbox" onClick={()=>{setShowPassword(!showPassword)}}/> Show Password </div> */}
					<button
						className="signIn-button"
						onClick={resetPasswordButtonHandler}
					>Change</button>
					<Link to="/user/login" data-testid="forgot-back-to-login">Back To Login</Link>
				</form>
			</div>
		</React.Fragment>
	);
}

export default ResetPasswordPage;
