const namePreference = {
	FirstNameFirst: 1,
	LastNameFirst: 2,
};

const separatorPreference = {
    Space:" ",
    CommaSpace:", ",
    Comma:","
}

const defaultPreference = namePreference.LastNameFirst;
// const defaultPreference = namePreference.FirstNameFirst;
const defaultSeparator = separatorPreference.CommaSpace;

export {defaultPreference, defaultSeparator, namePreference, separatorPreference};