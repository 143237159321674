import actiontypes from "../constant/action-types";
const initialState={
	deployementConfiguration:[]
};
export const deployementReducer = (state = initialState, { type, payload})=>{
	switch (type){
		case actiontypes.SET_DEPLOYEMENT_CONFIG:
			return { ...state, deployementConfiguration: payload};
		default :return state;
        }
}
