import { useEffect, useState } from 'react';
import { useDispatch,  } from 'react-redux';
import BreadCrumb from '../../common/component/BreadCrumb';
import {CgOrganisation} from "react-icons/cg"
import {FaUsers,FaMobileAlt,FaSitemap} from "react-icons/fa"
import * as API from '../../common/api/index.js';
import URL from "../../common/api/constantURL";
import { setLoading, setSnackData } from "../../redux/action/userAction";
import '../Style/style.css';
import "../Style/Dashboard.css"

function DashboardAdmin() {
	const dispatch = useDispatch();
	const [crumbs, setCrumbs] = useState(['Dashboard', 'user'])
	const [accounts, setAccounts] = useState(0)
	const [facilities, setFacilities] = useState(0)
	const [users, setUsers] = useState(0)
	const [devices, setDevices] = useState(0)
	const selected = crumb => {
		setCrumbs(crumb)
	}
	useEffect(() => {
		fetchDashboardDetail()
	}, []);

	const fetchDashboardDetail = async () => {
		dispatch(setLoading(true))
		let url = URL.dashboardDetail ;
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				setAccounts(response.result.result.accountCount || 0)
				setFacilities(response.result.result.facilityCount || 0)
				setUsers(response.result.result.userCount || 0)
				setDevices(response.result.result.deviceCount || 0)
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
	];
	const dashboard=[
		{
			"id":1,
			"Item":"Total Accounts",
			"Count": accounts,
			"icon":<CgOrganisation className="accounts-icon" data-testid="accounts-icon"/>
		},
		{
			"id":2,
			"Item":"Total Facilities",
			"Count": facilities,
			"icon":<FaSitemap  className="device-icon" data-testid="device-icon"/>
		},
		{
			"id":3,
			"Item":"Total Users",
			"Count": users,
			"icon":<FaUsers  className="facility-icon" data-testid="facility-icon"/>
		},
		{
			"id":4,
			"Item":"Total Devices",
			"Count":devices,
			"icon":<FaMobileAlt  className="users-icon" data-testid="users-icon"/>
		}
	]
	return (
		<div className='dashboard' data-testid="admin-dashboard">
			<div className="margin-left-12">
				<BreadCrumb crumbs={BreadCrumbArr} selected={selected} dataTestid={"bread-crums-list"}></BreadCrumb>
			</div>
			<div className="row">
				{dashboard.map((data, index)=>
					<div key={index} className="dashboard-card" data-testid="dashboard-cards">
						<div className="card-title-ctr" data-testid="card-title-ctr">
							<div data-testid="item">{data.Item}</div>
							 {data.icon}
						</div>
						<div className="status-count" data-testid="count">{data.Count}</div>
					</div>
				)}		
			</div>
		</div>
	);
}

export default DashboardAdmin;
