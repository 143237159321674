import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import Table from '../../../common/component/Table';
import './EventHistory.css';
import Calender from '../../../common/component/Calender';
import moment from 'moment-timezone';
import {setSnackData, setLoading, setUserPaginationData, setAlertEventHistory } from '../../../redux/action/userAction';
import { setAlarms } from '../../../redux/action/alarmAction';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import SingleEvent from "./SingleEvent";

function EventHistory() {
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "/home/reports/eventHistory", display: "Reports", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt='arrow-icon' />, type: 'img' },
		{ link: "/home/reports/eventHistory", display: "Mustering Event History", type: "react" },
	];
	const dispatch = useDispatch();
	const userPaginationData = useSelector(state=>state.userReducer.userPaginationData)
	const alertEventHistoryData = useSelector(state => state.userReducer.alertEventHistoryData)
	const [showCalendar, setShowCalendar] = useState(false);
	const [calendarDate, setCalendarDate] = useState(localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date());
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [previouseDate, setPreviouseDate] = useState(true);
	const [search, setSearch] = useState('');
	const [alarm, setAlarm] = useState('');
	const [nextDate, setNextDate] = useState(false)
	const [alertList, setAlertList] = useState([])
	const [view, setView] = useState(0)
	const [viewProps, setViewProps] = useState(null)
	const [calendarDateChange, setCalendarDateChange] = useState()

	useEffect(() => {
		dispatch(setUserPaginationData(0))
		dispatch(setLoading(true))
		if(!localStorage.getItem("timeZone")){
			return fetchFacilitytimeZone()
		}else{
			setCalendarDate(moment(selectedDate).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss"))
			let tempDate = moment(selectedDate).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD")
			setSelectedDate(moment(selectedDate).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD"))
			fetchAlarms(tempDate)
		}
	}, []);
	useEffect(()=>{
	}, [alertEventHistoryData,selectedDate])
	const fetchFacilitytimeZone =async()=>{
		let response = await API.getAPI(URL.getFacilityById + "?id=" + localStorage.getItem("facilityId"))
		if(response.result.status === "success"){ 
			localStorage.setItem("timeZone",response.result.result.timezone )
			let tempDate = moment(selectedDate).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD")
			setCalendarDate(moment(selectedDate).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss"))
			setSelectedDate(moment(selectedDate).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD"))
			fetchAlarms(tempDate)
		}else{
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
			dispatch(setLoading(false))
		} 
	}
	const fetchAlarms = async (tempDate) => {
		let url = URL.alarmsByFacilityId + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.alarmsByFacilityId
		}
		let response = await API.getAPI(url)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let alarmList = response.result.result;
				dispatch(setAlarms(alarmList))
				fetchHistory(tempDate)
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
				dispatch(setLoading(false))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
			dispatch(setLoading(false))
		}
	}

	const fetchHistory = async (selectDate, isSearch=false) => {
		let date;
		let url;
		if(!isSearch){
			date=  moment(selectDate).format("YYYY-MM-DD")
			url = URL.musteringHistory + "?facilityId=" + localStorage.getItem("facilityId") + "&date="+date;
		}else{
			url = URL.musteringHistory + "?facilityId=" + localStorage.getItem("facilityId") + "&searchText=" + search; 
		}
	
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let alertList = response.result.result
				alertList.map((alert, index)=>{
					alertList[index].format = "DD MMM, YYYY hh:mm A"
					if (alert.alertCreatedDate){
						let dateObj = moment(alert.alertCreatedDate+".0000000Z", "YYYY-MM-DD HH:mm:ss.0000000Z");
						alertList[index].alertDate = moment.utc(dateObj).format("DD MMM, YYYY")
						alertList[index].alertTime = moment.utc(dateObj).format("hh:mm A")
						alertList[index].alertDateTime = moment.utc(dateObj).format("DD MMM, YYYY hh:mm A")
					}else{
						alertList[index].alertDateTime = "-"
					}
					alertList[index].view = "..."
				})
				dispatch(setAlertEventHistory(response.result.result))
				setAlertList(response.result.result)
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const monthChange = (date) => {
		let tempDate = new Date(date);
		setCalendarDateChange(tempDate)
	}

	function dateChange(date) {
		if ((moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss")) < (moment(date).format("YYYY-MM-DD HH:mm:ss"))){
			date = moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss");
		}
		setSearch("")
		setCalendarDate(date);
		setSelectedDate(date);
		setShowCalendar(false);
		dispatch(setLoading(true))
		fetchHistory(date)
	}
	const showSingleEvent =(data, page)=>{
		dispatch(setUserPaginationData(page))
		setViewProps(data)
		setView(1)
	}
	const showEventList = () => {
		setViewProps(null)
		setView(0)
	}
	const reserFilters = ()=>{
		setAlarm("")
		setSearch("")
		setCalendarDate(moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss"));
		let currentDate = moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD")
		setSelectedDate(currentDate)
		dispatch(setLoading(true))
		fetchHistory(currentDate)
	}
	
	const calendarRef = useRef();

	useEffect(()=>{
		const handleClickOutSide = (e) =>{
			if(showCalendar && calendarRef.current && !calendarRef.current.contains(e.target)){
				setShowCalendar(false)
			}
		};
		document.addEventListener("click",handleClickOutSide);
		return () => {
			document.removeEventListener("click",handleClickOutSide);
		}
	},[showCalendar])

	const handleSearch = (e) => {
		setSearch(e.target.value)
	}
	const searchSubmit=(e)=>{
		e.preventDefault()
		if(search.trim().length > 0){
			setSelectedDate("");
			setCalendarDate(moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss"));
			dispatch(setLoading(true))
			fetchHistory(null, true)
		}else{
			let currentDate = moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD")
			setCalendarDate(moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss"));
			setSelectedDate(currentDate)
			dispatch(setLoading(true));
			fetchHistory(currentDate)
		}

	}
	return (
		<>
			{view === 0 ?
				<div className="mustering-event-history-container" id="wrapper" >
					<BreadCrumb crumbs={BreadCrumbArr} dataTestid="mustering-event-history-breadcrum" ></BreadCrumb>
					<div className="mustering-event-history-inner-container" data-testid="mustering-event-history-inner-conatiner">
						<div className="mustering-event-history-title" data-testid="mustering-event-history-title">
							Events - History
						</div>
						<div className="mustering-event-history-selection-container">
							<div className="mustering-event-history-selection-option" data-testid="mustering-event-history-selection-option">
								Event Selection Option
							</div>
						</div>
						<div className="mustering-event-history-date-and-event-selection displayFlex">
							<div className='event-history-date-container'>
								<div className="mustering-event-history-date-label">
									Date
								</div>
								<input className="request-service-date "
									placeholder="Choose Date"
									onClick={() => setShowCalendar(!showCalendar)}
									value={selectedDate ? moment(calendarDate).format("MM/DD/YYYY") : ""} 
									readOnly
									data-testid="calender-input"
									id="calender-input"

								/>
							
								{showCalendar ?
									<div className="request-service-date-picker-container" data-testid="date-picker-container" ref={calendarRef} onClick={(e)=> e.stopPropagation()}>
										<Calender
											active={calendarDate || new Date()}
											change = {calendarDateChange}
											highlightDates={[]}
											monthChange={(data) => monthChange(data)}
											onChange={(data) => dateChange(data)}
											previouseDate={previouseDate}
											nextDate={nextDate}
											currentDate={localStorage.getItem("timeZone") ? moment(new Date()).tz(localStorage.getItem("timeZone")).format("YYYY-MM-DD HH:mm:ss") : new Date()}
											/>
									</div>
								:""}
							</div>
							
							<div className='event-history-search-container search'>
								<input type="text" value={search} data-testid="users-search-box"  onKeyDown={(e) => {
								if (e.key === "Enter") {
									searchSubmit(e)
								}}} 
								className="usersSearch alert-user-search" placeholder="Search" onChange={handleSearch} />
								<button onClick={searchSubmit} className="alert-history-search-btn" data-testid="alert-history-search-btn">Search</button>
							</div>
		
							<button className="event-history-btns" data-testid="event-history-reset-btn" onClick={()=>reserFilters()}>Reset</button>
						</div>
						<Table
							headers={[
								{ id: 'alertDateTime', dataTestid:"date-field",label: 'Date', width: 140, responsive: true, sortable: true },
								{ id: "eventName", dataTestid:"eventName-field",label: "Event Name", type: 'linkclick', path: '#', clickFunc: showSingleEvent, width: 220, sortable: true, responsive: true },
								{ id: 'checkedInCount', dataTestid:"checkedInCount-field",label: 'Check Ins', width: 90 },
								{ id: 'absentCount', dataTestid: "absentCount-field", label: "Absent", width: 100 },
								{ id: 'medicalEmergencyNeededCount', dataTestid:"medicalEmergencyNeededCount-field",label: 'Emergency', width: 90 },
								{ id: 'view',dataTestid:"view-field", label: 'View', type: 'linkclick', path: '#', clickFunc: showSingleEvent,  fontSize:18, responsive: true },
							]}
							data={alertEventHistoryData}
							defaultOrderBy={"alertDateTime"}
							defaultSortOrder={"asc"}
							eventHistory={true}
							paginationData={userPaginationData}
						/>
					</div>
				</div>
			:
				<SingleEvent eventData={viewProps} showEventList={showEventList} />
			}
		</>		
	);
}
export default EventHistory;
