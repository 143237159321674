import React from "react";
import "../style/style.css"
function Confirmation(props) {
    const {
        style,
        deleteMessage,
        successFunc,
        cancelFunc,
        buttonValue,
        className,
        dataTestid
    } = props;
    return (
        <div className = {className ? "modal "+className : "modal" }>
            <div className="modal-content-container confirmation-modal" data-testid={dataTestid ? dataTestid: "delete-confirmation-modal"}>
                <div className="confirmation-message" data-testid={dataTestid ? "delete-reConfirmation-message" : "delete-confirmation-message"}>
                    { deleteMessage ? deleteMessage : "Are you sure want to continue ?" }
                </div>
                <div className="confirmation-button-container">
                    <button className="cancel-button" onClick={() => cancelFunc()}  data-testid={dataTestid ? "delete-reConfirmation-cancel-btn" :"delete-confirmation-cancel-btn"}>Cancel</button>
                    <div className="vertical_line"></div>
                    <button className={!style ? "save-button" : "save-button for-reset-password"}  onClick={() => successFunc()} data-testid={dataTestid ? "delete-reConfirmation-success-btn" :"delete-confirmation-success-btn"}>{buttonValue ? buttonValue : "Delete"}</button>
                </div>     
            </div>
        </div>
    );
}

export default Confirmation;
