import actiontypes from "../constant/action-types";

export const setGroups = (groups) => {
	return {
		type: actiontypes.SET_GROUPS,
		payload:groups,
	};
};
export const setAllGroups = (groups) => {
	return {
		type: actiontypes.SET_ALL_GROUPS,
		payload:groups,
	};
};