import actiontypes from "../constant/action-types";

const initialState = {
	accounts: [],
	facilities:[],
	facilityDetail:[],
	facilitySelectionFlag : false
};

export const accountReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actiontypes.SET_ACCOUNT_DATA:
			return { ...state, accounts: payload };
		case actiontypes.SET_FACILITY_DATA:
			return { ...state, facilities: payload };
		case actiontypes.SET_SPECIFIC_FACILITY_DETAIL:
			return {...state, facilityDetail: payload}
		case actiontypes.SET_FACILITY_SELECTION_FLAG:
			return { ...state, facilitySelectionFlag: payload }
		default:
			return state;
	}
}