import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';

import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL";
import * as functions from "../../common/functions/validateFunctions";
import { setSnackData } from "../../redux/action/userAction";

function AddAccount(props) {
	var dispatch = useDispatch()
	const propsData= props.data;

	const [accountName, setAccountName] = useState("");
	const [accountNameError, setAccountNameError] = useState(false);

	const [accountAddress, setAccountAddress] = useState("");
	const [accountAddressError, setAccountAddressError] = useState(false);

	const [accountCity, setAccountCity] = useState("");
	const [accountCityError, setAccountCityError] = useState("");

	const [accountZipCode, setAccountZipCode] = useState("");
	const [accountZipCodeError, setAccountZipCodeError] = useState("");

	const [accountCountry, setAccountCountry] = useState("");
	const [accountCountryError, setAccountCountryError] = useState("");

	const [accountContactNumber, setAccountContactNumber] = useState("");
	const [accountContactNumberError, setAccountContactNumberError] = useState("");

	const [accountUserFirstName, setAccountUserFirstName] = useState("");
	const [accountUserFirstNameError, setAccountUserFirstNameError] = useState("");

	const [accountUserLastName, setAccountUserLastName] = useState("");
	const [accountUserLastNameError, setAccountUserLastNameError] = useState("");

	const [accountUserEmail, setAccountUserEmail] = useState("");
	const [accountUserEmailError, setAccountUserEmailError] = useState("");

	const [accountUserContactNumber, setAccountUserContactNumber] = useState("");
	const [accountUserContactNumberError, setAccountUserContactNumberError] = useState("");

	useEffect(() => {
		if (propsData.id) {
			setAccountName(propsData.accountName || "")
			setAccountAddress(propsData.address || "")
			setAccountCity(propsData.city || "")
			setAccountCountry(propsData.countryCode || "")
			setAccountZipCode(propsData.zipCode || "")
			setAccountContactNumber(propsData.contact || "")
			setAccountUserFirstName(propsData.primaryFirstName || "")
			setAccountUserLastName(propsData.primaryLastName || "")
			setAccountUserEmail(propsData.primaryEmail || "")
			setAccountUserContactNumber(propsData.primaryContact || "")
		}
	}, []); 

	const createAccount = async () =>{
		let accountNameValidation = functions.validateInput(accountName, setAccountNameError, "alphanumeric"),
			accountAddressValidation = functions.validateInput(accountAddress, setAccountAddressError, "all"),
			accountCityValidation = functions.validateInput(accountCity, setAccountCityError, "alphabetic", false),
			accountZipCodeValidation = functions.validateInput(accountZipCode, setAccountZipCodeError, "alphanumeric", false),
			accountCountryValidation = functions.validateInput(accountCountry, setAccountCountryError, "alphabetic", false),
			accountContactNumberValidation = functions.validateInput(accountContactNumber, setAccountContactNumberError, "numeric"),
			accountUserFirstNameValidation = functions.validateInput(accountUserFirstName, setAccountUserFirstNameError, "alphabetic"),
			accountUserLastNameValidation = functions.validateInput(accountUserLastName, setAccountUserLastNameError, "alphabetic"),
			accountUserEmailValidation = functions.validateInput(accountUserEmail, setAccountUserEmailError, "email"),
			accountUserContactValidation = functions.validateInput(accountUserContactNumber, setAccountUserContactNumberError, "numeric");

		if (accountNameValidation &&
			accountAddressValidation &&
			accountCityValidation &&
			accountZipCodeValidation &&
			accountCountryValidation &&
			accountContactNumberValidation &&
			accountUserFirstNameValidation &&
			accountUserLastNameValidation &&
			accountUserEmailValidation &&
			accountUserContactValidation
		) {
			let requestData  = {
				"accountNumber": "test1",
				"accountName": accountName,
				"address": accountAddress,
				"city" : accountCity,
				"zipCode" :accountZipCode,
				"countryCode" : accountCountry,
				"contact": accountContactNumber,
				"primaryEmail": accountUserEmail,
				"primaryFirstName": accountUserFirstName,
				"primaryLastName": accountUserLastName,
				"primaryContact": accountUserContactNumber
			}

			let response = null;

			if (propsData.id) {
				requestData["id"] = propsData.id
				requestData["primaryUserId"] = propsData.primaryUserId
				response = await API.putAPI(URL.accounts, requestData);
			}else{
				response = await API.postAPI(URL.accounts, requestData);
			}
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
					props.onCancel()
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message || "Something went wrong. Try again",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
	}
	return (
		<div className="modal">
			<div data-testid="add-account-modal" className="modal-content-container add-account-modal">
				<div className="modal-content ">
					<div className="modal-title">
						{propsData.id ?
							"Update Account"
							:
							"Add Account"
						}
					</div>
					<div className='modal-inner-container'>
						<div className='response-container'></div>
						<div className='account-form-container'>
							<div className='account-label-input-container'>
								<div className='account-label-container'>
									<label>Account Name *</label>
								</div>
								<div  className='account-input-container'>
									<input 
										data-testid="account-name-input"
										autoFocus
										value={accountName}
										className={("account-input ") + (accountNameError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setAccountName, setAccountNameError)}
									/>
									<div data-testid="account-name-error" className='input-error-message' >{accountNameError}</div>
								</div>
							</div>
							<div className='account-label-input-container'>
								<div className='account-label-container'>
									<label>Address *</label>
								</div>
								<div className='account-input-container'>
									<input 
										data-testid="account-address-input"
										value={accountAddress}
										className={("account-input ") + (accountAddressError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setAccountAddress, setAccountAddressError)}
									/>
									<div data-testid="account-address-error" className='input-error-message' >{accountAddressError}</div>
								</div>
							</div>
							<div className='account-label-input-container'>
								<div className='account-label-container'>
									<label>City</label>
								</div>
								<div className='account-input-container'>
									<input
										data-testid="account-city-input"
										value={accountCity}
										className={("account-input ") + (accountCityError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setAccountCity, setAccountCityError)}
									/>
									<div data-testid="account-city-error" className='input-error-message' >{accountCityError}</div>
								</div>
							</div>
							<div className='account-label-input-container'>
								<div className='account-label-container'>
									<label>ZIP Code</label>
								</div>
								<div className='account-input-container'>
									<input 
										data-testid="account-zip-input"
										value={accountZipCode}
										className={("account-input ") + (accountZipCodeError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setAccountZipCode, setAccountZipCodeError)}
									/>
									<div data-testid="account-zip-error" className='input-error-message' >{accountZipCodeError}</div>
								</div>
							</div>
							<div className='account-label-input-container'>
								<div className='account-label-container'>
									<label>Country</label>
								</div>
								<div className='account-input-container'>
									<input 
										data-testid="account-country-input"
										value={accountCountry}
										className={("account-input ") + (accountCountryError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setAccountCountry, setAccountCountryError)}
									/>
									<div data-testid="account-country-error" className='input-error-message' >{accountCountryError}</div>
								</div>
							</div>
							<div className='account-label-input-container'>
								<div className='account-label-container'>
									<label>Contact Number *</label>
								</div>
								<div className='account-input-container'>
									<input 
										data-testid="account-contact-input"
										value={accountContactNumber}
										className={("account-input ") + (accountContactNumberError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setAccountContactNumber, setAccountContactNumberError)}
									/>
									<div data-testid="account-contact-error" className='input-error-message' >{accountContactNumberError}</div>
								</div>
							</div>
							<div className='account-inner-form-container'>
								<div className='account-inner-form-title'>Primary User Details</div>
								<div className='account-label-input-container'>
									<div className='account-label-container'>
										<label>First Name *</label>
									</div>
									<div className='account-input-container'>
										<input 
											data-testid="account-user-first-name-input"
											value={accountUserFirstName}
											className={("account-input ") + (accountUserFirstNameError ? " invalid " : "")}
											onChange={(event) => functions.validateInputChange(event, setAccountUserFirstName, setAccountUserFirstNameError)}
										/>
										<div data-testid="account-user-first-name-error" className='input-error-message' >{accountUserFirstNameError}</div>
									</div>
								</div>
								<div className='account-label-input-container'>
									<div className='account-label-container'>
										<label>Last Name *</label>
									</div>
									<div className='account-input-container'>
										<input 
											data-testid="account-user-last-name-input"
											value={accountUserLastName}
											className={("account-input ") + (accountUserLastNameError ? " invalid " : "")}
											onChange={(event) => functions.validateInputChange(event, setAccountUserLastName, setAccountUserLastNameError)}
										/>
										<div data-testid="account-user-last-name-error" className='input-error-message' >{accountUserLastNameError}</div>
									</div>
								</div>
								<div className='account-label-input-container'>
									<div className='account-label-container'>
										<label>Email *</label>
									</div>
									<div className='account-input-container'>
										<input 
											data-testid="account-user-email-input"
											value={accountUserEmail}
											className={("account-input ") + (accountUserEmailError ? " invalid " : "")}
											onChange={(event) => functions.validateInputChange(event, setAccountUserEmail, setAccountUserEmailError)}
										/>
										<div data-testid="account-user-email-error" className='input-error-message' >{accountUserEmailError}</div>
									</div>
								</div>
								<div className='account-label-input-container'>
									<div className='account-label-container'>
										<label>Contact Number *</label>
									</div>
									<div className='account-input-container'>
										<input 
											data-testid="account-user-contact-input"
											value={accountUserContactNumber}
											className={("account-input ") + (accountUserContactNumberError ? " invalid " : "")}
											onChange={(event) => functions.validateInputChange(event, setAccountUserContactNumber, setAccountUserContactNumberError)}
										/>
										<div data-testid="account-user-contact-error" className='input-error-message' >{accountUserContactNumberError}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="popup-button-container">
							<button data-testid="add-account-modal-close-button" className="cancel-button " onClick={(e) => props.onCancel()}>Cancel</button>
							<div class="vertical_line" ></div>
							<button data-testid="add-account-modal-submit-button" className="save-button" onClick={() => createAccount()} >
								{propsData.id ?
									"Update Account"
									:
									"Add Account"
								}
							</button>
						</div >
					</div>
				</div>
			</div>
		</div>
	)
}
export default AddAccount;
