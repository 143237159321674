import  URL from "./constantURL";
let excludedURLS = [URL.login, URL.changePassword, URL.forgotPassword]

export function postAPI(url, data){
	let resultObject = {};
	let headers = {
		"Content-Type" : "application/json"
	}
	if (!excludedURLS.includes(url)) {
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	return fetch(url,{
		method: "POST",
		headers: headers,
		body: JSON.stringify(data),
	})
	.then(response => response.json())
	.then((result) => {
		if (result.statusCode === 401) {
			return refreshToken(url, postAPI, data);        
		}
		resultObject = {
			fetchStatus : "success",
			result : result
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus : "failure",
			data : error
		}
		return resultObject;
	})
}
export function postWithFileAPI(url, data){
	let resultObject = {};
	let headers = {}
	if (!excludedURLS.includes(url)) {	
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	return fetch(url,{
		method: "POST",
		headers: headers,
		body: data,
	})
	.then(response => response.json())
	.then((result) => {
		if (result.statusCode === 401) {
			return refreshToken(url, postWithFileAPI, data);        
		}
		resultObject = {
			fetchStatus : "success",
			result : result
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus : "failure",
			data : error
		}
		return resultObject;
	})
}

export function getAPI(url){
	let resultObject = {};
	let headers = {
		"Content-Type" : "application/json"
	}
	if (!excludedURLS.includes(url)) {
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	return fetch(url,{
		method: "GET",
		headers: headers,
	})
	.then(response => response.json())
	.then((result) => {
		if (result.statusCode === 401) {
			return refreshToken(url, getAPI);
		}
		resultObject = {
			fetchStatus : "success",
			result : result
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus : "failure",
			data : error
		}
		return resultObject;
	})
}

export function deleteAPI(url, data){
	let resultObject = {};
	let headers = {
		"Content-Type" : "application/json"
	}
	if (!excludedURLS.includes(url)) {
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	return fetch(url,{
	method: "DELETE",
		headers: headers,
		body: JSON.stringify(data),
	})
	.then(response => response.json())
	.then((result) => {
		if (result.statusCode === 401) {
			return refreshToken(url, deleteAPI, data);
		}
		resultObject = {
			fetchStatus : "success",
			result : result
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus : "failure",
			data : error
		}
		return resultObject;
	})
}
export function putAPI(url, data){
	let resultObject = {};
	let headers = {
		"Content-Type" : "application/json"
	}
	if (!excludedURLS.includes(url)) {
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	return fetch(url,{
		method: "PUT",
		headers: headers,
		body: JSON.stringify(data),
	})
	.then(response => response.json())
	.then((result) => {
		if (result.statusCode == 401) {
			return refreshToken(url, putAPI, data);
		}
		resultObject = {
			fetchStatus : "success",
			result : result
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus : "failure",
			data : error
		}
		return resultObject;
	})
}
export function putwithFileAPI(url, data) {
	let resultObject = {};
	let headers = {}
	if (!excludedURLS.includes(url)) {
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	return fetch(url, {
		method: "PUT",
		headers: headers,
		body: data,
	})
	.then(response => response.json())
	.then((result) => {
		if (result.statusCode == 401) {
			return refreshToken(url, putwithFileAPI, data);
		}
		resultObject = {
			fetchStatus: "success",
			result: result
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus: "failure",
			data: error
		}
		return resultObject;
	})
}

function refreshToken(url, callBack, data={}){
	let resultObject = {};
	let headers = {
		"Content-Type" : "application/json"
	}
	if (!localStorage.accessToken) {
		window.location.reload()
	}
	return fetch(URL.refreshToken,{
		method: "POST",
		headers: headers,
		body:JSON.stringify({"token":localStorage.refreshToken})
	})
	.then(response => response.json())
	.then((responseParsed) => {
		if (responseParsed.statusCode === 401 || responseParsed.status === "failure"){
			localStorage.clear()
			return window.location.href = "/user/login";
		}else{
			localStorage.setItem("accessToken", responseParsed.result.accessToken);
			localStorage.setItem("refreshToken", responseParsed.result.refreshToken);
			return callBack(url, data);
		}
	},
	(error) => {
		resultObject = {
			fetchStatus : "failure",
			data : error,
			message : "Session Timeout, Login Again"
		}
		return resultObject;
	})
}

export function downloadFile(url){
	let resultObject = {};
	let headers = {
		"Content-Type" : "application/octet-stream"
	}
	if (!excludedURLS.includes(url)) {
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	let unauth = false
	let filename
	return fetch(url,
	{
		method : "GET",
		headers: headers
	})
	.then((response)=>{
		if (response.status == 401) {
			unauth = true
		}
		filename = response.headers.get('content-disposition').split('filename=')[1]
		return response})
	.then(response =>response.blob())
	.then((result) => {
		if (unauth) {
			return refreshToken(url, downloadFile);
		}
		const blobUrl = window.URL.createObjectURL(result);
		const link = document.createElement('a');
		link.href = blobUrl;
		link.setAttribute('download',  filename);
		document.body.appendChild(link);
		link.click();
		resultObject = {
			fetchStatus : "success"
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus: "failure",
			data: error
		}
		return resultObject;
	})
}

export function readFiles(url){
	let resultObject = {};
	let headers = {
		"Content-Type" : "application/octet-stream"
	}
	if (!excludedURLS.includes(url)) {
		headers["Authorization"] = localStorage.getItem("accessToken")
	}
	let unauth = false;
	let filename
	return fetch(url,
		{
			method : "GET",
			headers: headers
		})
		.then((response)=>{
			if (response.status == 401) {
				unauth = true
			}
			filename = response.url.split('filename=')
		return response;})
	.then(response =>response)
	.then(async (result) => {
		if (unauth) {
			return refreshToken(url, readFiles);
		}
		let qrLink = await result.blob()
		const blobUrl = window.URL.createObjectURL(qrLink);
		resultObject = {
			qrImage:blobUrl,
			fetchStatus : "success"
		}
		return resultObject;
	},
	(error) => {
		resultObject = {
			fetchStatus: "failure",
			data: error
		}
		return resultObject;
	})
}

export function deepLinkPostAPI(url, body) {
    let resultObject = {};
    let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json"
    };
    
    return fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then((result) => {
        resultObject = {
            fetchStatus: "success",
            result: result
        };
        return resultObject;
    })
    .catch((error) => {
        resultObject = {
            fetchStatus: "failure",
            data: error
        };
        return resultObject;
    });
}

