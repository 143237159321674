import React from 'react';

import {BsArrowRight} from 'react-icons/bs';
import {BsArrowLeft} from 'react-icons/bs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import Card from '../../common/component/Card';
import "../Style/ManageRole.css";

function ManageRole(props) {
    const roleList = useSelector(state => state.userReducer.userTypes)
    const [unAssignedRoleList, setUnAssignedRoleList] = useState([])
    const [assignedRoleList, setAssignedRoleList] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [roleAssignedSelectAll,setRoleAssignedSelectAll] = useState(false)
    const [roleUnAssignedSelectAll,setRoleUnAssignedSelectAll] = useState(false)
    useEffect(()=>{
        let propRoleList = props.userList.Role;
        let tempUnAssignedRole = []
		let assignedRole = []
		roleList.map((role)=>{
			if (propRoleList && propRoleList.includes(role.id)) {
				assignedRole.push({id:role.id, roleName : role.roleName, roleAllocations: role.roleAllocations})
			}else{
				tempUnAssignedRole.push({id:role.id, roleName : role.roleName, roleAllocations: role.roleAllocations})
			}
		})
        setUnAssignedRoleList(tempUnAssignedRole)
        setAssignedRoleList(assignedRole)
    },[])
    const selectRole = (roleIds) => {
		setSelectedRoles([...roleIds])
	}
    const selectRoleMobile=(data)=>{
		let tempSelectedRole = selectedRoles;
		let index = tempSelectedRole.indexOf(data)
		if (index > -1) {
			tempSelectedRole.splice(index, 1);
		}else{
			tempSelectedRole.push(data)
		}
		setSelectedRoles([...tempSelectedRole])
	}
    const selectAllRoles =(checkStatus,data)=>{
		if(checkStatus === true){
			let tempIds = (data.map((data)=>{
				return (data.id)
			}))
			setSelectedRoles([...tempIds])
		}else{
			setSelectedRoles([])
		}
	}
    const moveSelectedRole = () => {
		setRoleUnAssignedSelectAll(false)
		let tempAssignedValues = assignedRoleList
		let tempUnAssignedValues = unAssignedRoleList
		let tempSelectedRoles = selectedRoles;
		roleList.map((role, index) => { 
			if (selectedRoles.includes(role.id) && tempUnAssignedValues.map((data)=>{return data.id}).includes(role.id)) { 
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === role.id), 1);
				tempAssignedValues.push({id:role.id, roleName: role.roleName , roleAllocations : role.roleAllocations})
				tempSelectedRoles.splice(tempSelectedRoles.indexOf(role.id), 1);
			}
		})
		setSelectedRoles ([...tempSelectedRoles])
		setAssignedRoleList([...tempAssignedValues])
		setUnAssignedRoleList([...tempUnAssignedValues])
	}
    const moveUnSelectedRole = () => {
		setRoleAssignedSelectAll(false)
		let tempAssignedValues = assignedRoleList
		let tempUnAssignedValues = unAssignedRoleList
		let tempSelectedRoles = selectedRoles;
		roleList.map((role) => {  
			if (selectedRoles.includes(role.id) && tempAssignedValues.map((data)=>{return data.id}).includes(role.id)) {  
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === role.id), 1);
				tempUnAssignedValues.push({id:role.id, roleName: role.roleName, roleAllocations : role.roleAllocations})
				tempSelectedRoles.splice(tempSelectedRoles.indexOf(role.id), 1);
			}
		})
		setSelectedRoles ([...tempSelectedRoles])
		setAssignedRoleList([...tempAssignedValues])
		setUnAssignedRoleList([...tempUnAssignedValues])
	}
    const manageRoleHandler = () =>{
        props.onSave(assignedRoleList)
        props.onCancel()
    }
    return (
        <div className="modal">
            <div className="modal-content-container manage-role-modal">
                <div className="modal-content add-group-modal-content ">
                    <div className='manage-role-title' data-testid="manage-role-title">Manage Roles Assigned to this User</div>
                    <div className='manage-role-description' data-testid="manage-role-description">You can assign roles by choosing them below and moving them to the “Selected Roles” box.</div>
                    <div className='role-card-container'>
                        <div className='role-list-unselected-container-list' data-testid="unAssigned-role-card-ctr" id="unAssigned-role-card-ctr"> 
                            <Card 
                                header="select"
                                type="users-role"
                                title="Roles"
                                item={unAssignedRoleList}
                                selectedData = {(data)=>selectRole(data)}
                                selectedDataMobile = {(data)=>{selectRoleMobile(data)}}
                                selectAll = {(checkStatus,data)=>{selectAllRoles(checkStatus,data)}}
                                setSelectAllStatus={(checkStatus)=>{setRoleUnAssignedSelectAll(checkStatus);setRoleAssignedSelectAll(false)}}
                                checkedStatus={roleUnAssignedSelectAll}
                                selectionData={selectedRoles}
                                dataTestId={"unAssignedRoleData"}
                                selectAllDataTestId={"UnAssignedRole-select-all"}
                                searchDataTestId={"unAssigned-search-filter-input-box"}
                            />
                        </div>
                        <div className="icon">
                            <BsArrowRight onClick={()=>{moveSelectedRole()}} data-testid="add-unAssigned-role"/>
                            <BsArrowLeft onClick={()=>{moveUnSelectedRole()}} data-testid="remove-Assigned-role"/>
                            
                        </div >
                        <div className='role-list-selected-container-list' id="Assigned-role-card-ctr" data-testid="Assigned-role-card-ctr">
                                <Card 
                                    headers="deselect"
                                    type="users-role"
                                    title="Selected roles"
                                    item={assignedRoleList}
                                    selectedData = {(data)=>selectRole(data)}
                                    selectedDataMobile = {(data)=>{selectRoleMobile(data)}}
                                    selectionData={selectedRoles}
                                    selectAll = {(checkStatus,data)=>{selectAllRoles(checkStatus,data);}}
                                    setSelectAllStatus={(checkStatus)=>{setRoleAssignedSelectAll(checkStatus);setRoleUnAssignedSelectAll(false)}}
                                    checkedStatus={roleAssignedSelectAll}
                                    dataTestId={"AssignedRoleData"}
                                    selectAllDataTestId={"AssignedRole-select-all"}

                            /> 
                        </div>	 
                    </div>
                    <div className="popup-button-container">
                        <button className="cancel-button" onClick={(e) =>props.onCancel()}>Cancel</button>
                        <div className='vertical_line'></div>
                        <button className="save-button" data-testid="add-role-btn" onClick={()=>{manageRoleHandler()}} >
                            Save
                        </button>
                    </div >
                </div>
              
            </div>
        </div>
    )
}

export default ManageRole
