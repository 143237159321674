import actiontypes from "../constant/action-types";

const initialState={
    serviceAllocation: [],
};

export const serviceAllocationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actiontypes.SET_SERVICE_ALLOCATION:
            return { ...state, serviceAllocation: payload };
        default:
            return state;
    }
}