import actiontypes from "../constant/action-types";

export const setUser = (users) => {
		return {
			type: actiontypes.SET_USER,
			payload:users,
		};
	};
export const login = (adminDetail) => { 
	return {
		type: actiontypes.LOGIN,
		payload:  adminDetail, 
	}
};

export const setUserType = (user_type) =>{
	return {
		type: actiontypes.USER_TYPE,
		payload:user_type,
	}
}

export const setAlertColor = (color)=>{
	return {
		type :actiontypes.SET_ALERT_COLOR,
		payload:color
	}
}

export const setAlertIcon = (icon) =>{
	return {
		type :actiontypes.SET_ALERT_ICON,
		payload:icon
	}
}

export const setSnackData=(snackResponse)=>{
	snackResponse = {
		showSnack : snackResponse.showSnack,
		snackMessage: snackResponse.snackMessage,
		snackVariant:snackResponse.snackVariant
	
	}
	return {
		type: actiontypes.SET_SNACK_DATA,
		payload:snackResponse
	};
}

export const setCurrentMusteringEventUserList=(userList)=>{
	return{
		type:actiontypes.SET_CURRENT_MUSTERING_EVENT_USER_LIST,
		payload:userList
	}
}
export const setMusteringLocation=(locationList)=>{
	return {
		type:actiontypes.SET_MUSTERING_LOCATION,
		payload:locationList
	}
}

export const setLoading=(loading)=>{
	return{
		type:actiontypes.SET_LOADING,
		payload:loading
	}
}

export const setRolesDetails=(rolesDetails)=>{
	return{
		type:actiontypes.SET_ROLES_DETAILS,
		payload:rolesDetails
	}
}

export const setUserPermissions = (userPermissions) => {
	return {
		type: actiontypes.SET_USER_PERMISSION,
		payload: userPermissions
	}
}

export const setAdvisorDetails=(advisorDetails)=>{
	return{
		type:actiontypes.SET_ADVISOR_DETAILS,
		payload:advisorDetails
	}
}
export const setUserProfileDetail=(profileDetails)=>{
	return{
		type:actiontypes.SET_USER_PROFILE_DETAIL,
		payload:profileDetails
	}
}

export const setUserSearch=(search)=>{
	return {
		type:actiontypes.SET_USERS_SEARCH,
		payload:search
	}

}

export const setUserFilterGroup=(group)=>{
	return {
		 type:actiontypes.SET_USERS_FILTER_GROUP,
		 payload:group
	}
}

 export const setUserFilterRole=(role)=>{
	return {
		type:actiontypes.SET_USERS_FILTER_ROLE,
		payload:role
	}
 }

export const setUserPaginationData=(paginationData)=>{
	return {
		type:actiontypes.SET_USER_PAGINATION_DATA,
		payload:paginationData
	}
}

export const setUserPerPageData=(userperPageData)=>{
	return {
		type:actiontypes.SET_USER_PER_PAGE_DATA,
		payload:userperPageData
	}
}

export const setAlertEventHistory=(alertEventHistory)=>{
	return {
		 type:actiontypes.SET_ALERT_EVENT_HISTORY,
		 payload:alertEventHistory
	}
}

export const setActiveMusteringList = (musteringList) =>{
	return {
		type: actiontypes.SET_ACTIVE_MUSTERING_LIST,
		payload: musteringList
	}
}

export const setAlertTones = (icon) =>{
	return {
		
		type :actiontypes.SET_ALERT_TONES,
		payload:icon
	}
}
